import { Checkbox, Col, Menu, MenuDropdown, MenuDropdownItem, MenuItem, Row } from "framework7-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ratingSortingOptions } from "./RatingData";

let activeAreas = {};

const localStorageName = "SH-NEXT-RATING-MENU-v2";

export default function RatingMenu({ setActiveAreas, menuAreaData }) {

    const [sortingOrder, setSortingOrder] = useState(menuAreaData.sortingOrder);
    const [sortDirectionText, setSortDirectionText] = useState(menuAreaData.sortingDirection == 1 ? "Faldende" : "Stigende");

    let areas = menuAreaData.areas;
    let rdata = Object.entries(areas).filter(([, val]) => val.status !== "inactive")

    useEffect(() => {
        //localStorage.setItem("SH-NEXT-LOGIN", JSON.stringify({ name: Connector.username, pw: "???" }));
        let menuData = localStorage.getItem(localStorageName);
        try {
            menuData && _.merge(menuAreaData, JSON.parse(menuData));
            //menuData && Object.assign(menuAreaData, { ...menuAreaData, ...JSON.parse(menuData) });
        } catch (error) {
            console.log("Invalid menuData:", error);
        }
        updateActiveAreas();
        setSortingOrder("" + menuAreaData.sortingOrder);
        //setSortDirectionText(menuAreaData.sortingDirection);
        setSortDirectionText(menuAreaData.sortingDirection == 1 ? "Faldende" : "Stigende");

    }, []);

    function updateActiveAreas() {
        activeAreas = {};

        rdata.forEach(([key, val]) => {
            activeAreas[key] = val.show === true;
        });

        localStorage.setItem(localStorageName, JSON.stringify(menuAreaData, (key, val) => {
            if (typeof val === 'object' || key == "show" || key == "sortingOrder" || key == "sortingDirection") return val;
            return undefined;
        }));

        setActiveAreas(activeAreas);
    }


    function ratingAreaChange(e) {
        let val = e.target.value;
        let checked = e.target.checked;
        console.log("you clicked: " + val, "set:", val);
        areas[val].show = checked;
        updateActiveAreas();
    }

    /* const sortingSelect = (id) => {
        setSortingOrder(id);
        menuAreaData.sortingOrder = id;
        console.log("sorting:", id);
        updateActiveAreas();
    };
 */
    function sortingSelect(e) {
        console.log("you clicked: ", e.currentTarget.id);
        let id = e.currentTarget.id;
        setTimeout((id) => {
            console.log("changing to: ", id);
            setSortingOrder(id);
            menuAreaData.sortingOrder = id;
            console.log("sorting:", id);
            updateActiveAreas();

        }, 1, id);


    }

    /*     function sortingSelect(id) {
            setSortingOrder( id);
            menuAreaData.sortingOrder = id;
            console.log("sorting:", id);
            updateActiveAreas();
            //forceUpdate(Math.random);
            
            
        }
     */
    function sortingDirection(e) {
        let checked = e.target.checked;
        menuAreaData.sortingDirection = checked ? 1 : 0;
        setSortDirectionText(menuAreaData.sortingDirection == 1 ? "Faldende" : "Stigende");
        updateActiveAreas();
    }

    return (<div>
        <Row>
            <Col >
                <Menu>
                    {/* <MenuItem href="#" text="Sortering: Alfabetisk" /> */}
                    <MenuItem text={"Sortering: " + ratingSortingOptions[sortingOrder]} dropdown>
                        <MenuDropdown left>
                            {
                                Object.entries(ratingSortingOptions).map(([key, val]) => {
                                    return (
                                        <MenuDropdownItem href="#" text={val} key={"key" + key} id={key} onClick={sortingSelect} />
                                    )
                                })

                            }

                        </MenuDropdown>
                    </MenuItem>

                </Menu>


            </Col>
            <Col><p><Checkbox name="sortDirection" onChange={sortingDirection} checked={menuAreaData.sortingDirection == 1} />&nbsp; {sortDirectionText}</p></Col>
            <Col>

                <Row>
                    {rdata.map(([key, val]) => {
                        return (
                            <Col key={"key" + key}>
                                <p><Checkbox name="ratingArea" value={key} onChange={ratingAreaChange} checked={val.show == true} />&nbsp; {val.friendlyName}</p>
                            </Col>)
                    })}

                </Row>
            </Col>


        </Row>
    </div>
    )
}