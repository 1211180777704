import React, { useState } from "react";
import OfferHandler from "../OfferHandler";

const StingHistory = (props) => {
    const [timeline, setTimeline] = useState((<div></div>));
    const [timelineData, setTimelineData] = useState();
    const timelineReadyRef = React.useRef(false);


    if (!timelineData && !timelineReadyRef.current) {
        timelineReadyRef.current = true;
        OfferHandler.instance.retrieveTimeline(props.uid).then(value => {
            //console.dir(value);
            setTimelineData(value);
            setTimeline(buildTimeline(value));
        }).catch(reason => {
            console.error(reason);
        });
    }

    return (
        <div>
            {timeline}
        </div>);
}

function buildTimeline(timelineData) {
    let activeDate = undefined;
    timelineData = Array.isArray(timelineData) ? timelineData : [];
    let output = [];
    timelineData.forEach((item, index) => {
        if (activeDate !== item.date) {
            activeDate = item.date;
            output.push(<div key={"date" + index}>{activeDate}</div>);
        }

        let extraStyle = (item.external ? { backgroundColor: "rgb(31 60 40)" } : {});

        output.push(
            <div className="timeline-item" key={"timelineItem" + index}>
                <div className="timeline-item-date" key={"timelineDate" + index}>
                    {item.time}
                </div>
                <div className="timeline-item-divider" ></div>
                <div className="timeline-item-content" key={"timelineContent" + index}>
                    <div className="timeline-item-inner" key={"timelineItemInner" + index} style={extraStyle} ><a style={{ color: "white" }} href={item.url} rel="noreferrer" className="link external" target="_blank">{item.url + (item.title ? ' (' + item.title + ')' : "")}</a></div>
                </div>
            </div>
        )

    });

    return (
        <div className="timeline">

            {output}

        </div>

    );
}

export default StingHistory;

/* <div className="timeline">
                <div className="timeline-item">
                    <div className="timeline-item-date">
                        21 <small>DEC</small>
                    </div>
                    <div className="timeline-item-divider"></div>
                    <div className="timeline-item-content">
                        <div className="timeline-item-inner">Some text goes here</div>
                    </div>
                </div>
            </div> */