
import AboutPage from '../pages/about';
import FormPage from '../pages/form';
import OffersPage from '../pages/OffersPage';


import NotFoundPage from '../pages/404';
import CustomerPage from '../pages/CustomerPage';
import CustomersPage from '../pages/CustomersPage';
import DynamicRoutePage from '../pages/dynamic-route';
import NewTaskPage from '../pages/NewTaskPage';
import { OfferTabs } from '../pages/offerTabs';
import PostCodePage from '../pages/PostcodePage';
import RatingPage from '../pages/RatingPage';
import RequestAndLoad from '../pages/request-and-load';
import SettingsPage from '../pages/SettingsPage';
import StartScreenPage from '../pages/StartScreenPage';
import TeamPrefPage from '../pages/TeamPrefPage';
import TeamsPage from '../pages/TeamsPage';
import TeamView from '../pages/TeamView';
import PanelMenu from '../views/PanelMenu';

var routes = [
    {
        path: '/start-screen/',
        component: StartScreenPage,
    },
    {
        path: '/customer-page/:id',
        component: CustomerPage,
    },
    {
        path: '/settings/',
        component: SettingsPage,
    },

    {
        path: '/teamView/',
        component: TeamView,
    },

    {
        path: '/newTask/:id',
        component: NewTaskPage,
    },
    {
        path: '/teams/',
        component: TeamsPage,
    },
    {
        path: '/TeamPref/',
        component: TeamPrefPage,
    },
    {
        path: '/rating/',
        component: RatingPage,
    },
    {
        path: '/customers/',
        component: CustomersPage,
    },
    {
        path: '/postnumre/',
        component: PostCodePage,
    },
    {
        path: '/offers-page/',
        component: OffersPage,
    },
    {
        path: '/offer/:uid/fromlist/:fromlist',
        component: OfferTabs,
    },
    {
        path: '/offer/:uid',
        component: OfferTabs,
    },
    {
        path: '/about/',
        component: AboutPage,
    },
    {
        path: '/form/',
        component: FormPage,
    },
    // Left Panel
    {
        path: '/panel-menu/',
        component: PanelMenu,
    },

    {
        path: '/dynamic-route/blog/:blogId/post/:postId/',
        component: DynamicRoutePage,
    },
    {
        path: '/request-and-load/user/:userId/',
        async: function ({ router, _to, resolve }) {
            // App instance
            var app = router.app;

            // Show Preloader
            app.preloader.show();



            // Simulate Ajax Request
            setTimeout(function () {
                // We got user data from request
                var user = {
                    firstName: 'Vladimir',
                    lastName: 'Kharlampidi',
                    about: 'Hello, i am creator of Framework7! Hope you like it!',
                    links: [
                        {
                            title: 'Framework7 Website',
                            url: 'http://framework7.io',
                        },
                        {
                            title: 'Framework7 Forum',
                            url: 'http://forum.framework7.io',
                        },
                    ]
                };
                // Hide Preloader
                app.preloader.hide();

                // Resolve route to load page
                resolve(
                    {
                        component: RequestAndLoad,
                    },
                    {
                        props: {
                            user: user,
                        }
                    }
                );
            }, 1000);
        },
    },
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;
