import copy from 'copy-to-clipboard';
import { Block, Button, Card, Col, f7, Icon, Link, List, ListItem, Navbar, Page, Row } from 'framework7-react';
import React, { useRef, useState } from 'react';
import InfoHelper from '../components/InfoHelper';
import Connector from '../Connector';
import { beConnectorInstance } from '../js/BeConnector';

import OfferHandler from '../OfferHandler';
import { getFirstMondayOfWeek } from '../utils/DateHelperWeb';
import StingHistory from '../views/StingHistory';



const OfferPage = (props) => {
    const _popup = useRef(null);
    let list = OfferHandler.list;
    let app = props.f7router.app;
    const [offerLoadState, setOfferLoadState] = useState("UNKNOWN");

    let [refreshServerInfo, setServerInfo] = useState(undefined);

    let currentOfferServerInfo = refreshServerInfo;
    let customerCategory = "Emne";
    let addOrModifyCustomer = "Opret kundeemne i CRM";
    let contactInfoText = [<p key="contactLeft" style={{ float: "left" }}>Kontakt info:</p>];





    const fromList = !!props.fromlist;

    if (!list || !list.uidList) {

        setTimeout(() => { app.preloader.show(); })
        OfferHandler.instance.getListwithOffer(props.uid).then(() => {
            console.log("found offer...");
            OfferHandler.ready = false;
            setOfferLoadState("FOUND");

        }).catch(() => {

            setOfferLoadState("NOT_FOUND");
            console.log("HELLO popup");
        })



        return (<Page onPageBeforeOut={() => { app.preloader.hide(); }}><Navbar title="Henter tilbud..." backLink="Back" /></Page>)
    }
    app.preloader.hide();
    if (offerLoadState === "NOT_FOUND") {
        return (<Page><Navbar title="Ukendt tilbud" />
            <Block> {"Tilbud med uid = " + props.uid + " kunne ikke findes... Prøv at søge på email."}</Block>
            <Block> <Link iconF7="house" text="Klik her for at se alle tilbud" color="green" href="/" external></Link></Block>
        </Page>)
    }


    let offer = list.uidList[props.uid];

    if (!offer) {
        OfferHandler.list = undefined;
        OfferHandler.ready = false;
        setOfferLoadState("UNKNOWN");
        return null;
    }

    //currentOffer = offer; 
    let contactView = generateCustomerDataView(offer, 'contact');
    let questionView = generateCustomerDataView(offer, 'other');
    let priceInfo = generatePriceInfo(offer);

    //    offer.postcode = "2000";

    //offer.dictionary.telefon.value = offer.dictionary.telefon.value.trim();

    offer.dictionary.telefon.value = offer.dictionary.telefon.value.replace(/[^0-9]/g, "");

    offer.state = (offer.state === '0') ? '1' : offer.state;
    OfferHandler.hasChanged = true;
    let initBaseUrl = Connector.initialBaseUrl;

    function updateOffer() {
        f7.dialog.preloader('Opdaterer tilbud...');

        OfferHandler.instance.updateOffer(props.uid).then((_result) => {
            f7.dialog.close();
            //f7.views.main.router.navigate("/",)

            console.log("in", initBaseUrl, "fromlist:", props.fromlist, fromList);
            if (fromList) {
                props.f7router.back();
            }
            else {
                window.location.href = "/";

            }
        })
    }
    let kundeMailBody = "%0D%0A%0D%0ASe din oprindelige bestilling på følgende link:%0D%0A https://dinside.servicehuset.dk/tilbud/" + props.uid + "-tilbud.html%0D%0A"
    let kundeMailToData = `mailto:${"" + offer.name + " <" + offer.email + ">"}?subject=Rengøringstilbud fra ServiceHuset&body=` + kundeMailBody;


    Connector.initialBaseUrl = undefined;

    function createCustomerClick() {
        console.log("createCustomerClick");

        props.setActiveTab("ulSelector");

        //props.f7router.tab
        /* 

        createCustomerPopup.current = f7.popup.create({
            el: "#create-in-care",
            // Events
            on: {
                open: function (popup) {
                    console.log('1 Popup open:', popup);
                },
                opened: function (popup) {
                    console.log('2 Popup opened:', popup);
                },
                close: function () {
                    console.log('3 Popup closed:');
                    //extraRequest = {};
                }
            }
        });

        createCustomerPopup.current.once("close", (popup) => {
            console.log('popup close-handler:', popup);

        })

        createCustomerPopup.current.open(true); */
    }




    switch (offer?.dictionary?.frekvens?.value) {
        case "Hver uge":
        case "Every week":
        case "Hver 2. uge":
        case "Every second week":
        case "Hver 4. uge":
        case "Every fourth week":
            customerCategory = "Omr. {} Rengøring";
            break;
        case "ved ikke lige nu":
        case "I don't know yet":
            customerCategory = "Emne";
            break;
        case "Bare en enkelt gang":
        case "Only once":
        case "Flytterengøring":
        case "Movine out cleaning":
            customerCategory = "Engangs reng.";
            break;
    }

    // Omr. 5 Rengøring
    const arealist = {
        area99: "5",
        area5: "5",
        area4: "4",
        area3: "3",
        area1: "1",

    }

    const initialList = {
        area99: "rl",
        area5: "np",
        area4: "CN",
        area3: "YT",
        area1: "af",

    }

    let userInitials = initialList[offer.area];

    customerCategory = customerCategory.split("{}").join(arealist[offer.area] || "5");

    if (!currentOfferServerInfo) {
        setServerInfo("LOADING"); // 
        retrieveOfferInfo();

    }

    async function retrieveOfferInfo() {
        let newInfo = {
            customerId: offer.dictionary?.telefon?.value,
            name: offer.dictionary?.navn?.value,
            streetName: offer.dictionary?.gade?.value,
            postcode: offer.dictionary?.postnummer?.value || '0000',
            email: offer.dictionary?.email?.value,
            phone: offer.dictionary?.telefon?.value,
            customerCategory: customerCategory,
            logUser: userInitials,
        }

        console.time("retrieveOfferInfo");

        let result = await beConnectorInstance.getOfferServerInfo(newInfo)

        if (result?.status == "success") {
            let ulWeekLookup = {};
            let schedules = result.ulData.allSchedules;
            let minweek = 9999;
            let maxweek = -1;

            for (const ulid in schedules) {
                let ul = schedules[ulid];
                for (const week of ul.weeks) {
                    ulWeekLookup[ulid] ||= {};
                    ulWeekLookup[ulid][week.week] = week;

                    minweek > week.week && (minweek = week.week);
                    maxweek < week.week && (maxweek = week.week);
                }
            }
            result.ulData.scheduleMaxWeek = maxweek;
            result.ulData.scheduleMinWeek = minweek;
            //let filledSchedules = {};

            for (const ulid in schedules) {
                let ul = schedules[ulid];

                ul.weeks = []
                for (let weekNum = minweek; weekNum <= maxweek; weekNum++) {
                    let week = ulWeekLookup[ulid][weekNum];
                    !week && (week = {
                        jobCount: 0,
                        jobsPerDay: [9999, 9999, 9999, 9999, 9999, 9999, 9999],
                        //jobsPerDay: [-1, -1, -1, -1, -1, -1, -1],
                        minDist: [9999, 9999, 9999, 9999, 9999, 9999, 9999],
                        week: weekNum,
                        weekStart: getFirstMondayOfWeek(weekNum)
                    });
                    ul.weeks.push(week);
                }

            }
        }
        /*
        
        jobCount:
        3
        jobsPerDay:
        (7) [3, 0, 0, 0, 0, 0, 0]
        minDist:
        (7) [18.970038651657344, 9999, 9999, 9999, 9999, 9999, 9999]
        week:
        6
        weekStart:
        '2023-02-05T23:00:00.000Z'
        */

        //await new Promise(resolve => setTimeout(resolve, 1111));

        currentOfferServerInfo = result;


        console.timeEnd("retrieveOfferInfo");
        setServerInfo(result);
        updateCreateOrModify();

        if (result.status == "FAIL") {
            InfoHelper.showError("Der opstod en fejl - prøv at genindlæse (Konkakt IT - hvis fejlen vedbliver.)");

            return;
        }
        let crmProps = {
            offerServerInfo: result,
            title: addOrModifyCustomer,
            offer: offer,
            customerCategory: customerCategory,
            userInitials: userInitials
        }

        props.setAddToCrmParams(crmProps); // refactor this}


    }

    updateCreateOrModify();
    let allReady = !!(currentOfferServerInfo && currentOfferServerInfo !== "LOADING" && currentOfferServerInfo?.status != "FAIL");

    return (
        <Page>
            <Navbar title={"Tilbud til: " + offer.name} >



                {fromList ?
                    <Link slot="left" back><Icon f7={"house"}></Icon></Link> :
                    <Link slot="left" href="/" external ><Icon f7={"house"}></Icon></Link>
                }

            </Navbar>
            <Block></Block>
            <Block>
                <Row>
                    <Col width="33">
                        <Button external target="_blank" fill raised href={kundeMailToData}>Start mail til kunden</Button>
                    </Col>
                    <Col width="33">
                        <Button disabled={!allReady} fill raised style={{ background: "#334433" }} onClick={createCustomerClick} tabLink="#ulSelector"> {"Underleverandørvælger"}</Button>
                    </Col>

                    <Col width="33">
                        <Button fill raised onClick={(_e) => {
                            if (offer.state === '2') {
                                offer.state = '0';
                                offer.offer.offerStatus = 'SENT';
                            } else {
                                offer.state = '2';
                                offer.offer.offerStatus = 'BINDING_OFFER_SENT';
                            }

                            offer.offer.offerStatusChangeTime = Date.now();
                            updateOffer();
                        }} > {offer.state === '2' ? 'Marker ubehandlet' : 'Marker behandlet'}</Button>

                    </Col>

                </Row>

            </Block>

            <Row>
                <Col width="100" medium="50">
                    <Card>
                        <span slot="header">Ønsker:</span>
                        <span slot="content">

                            <List>
                                {questionView}
                            </List>
                        </span>

                    </Card>

                </Col>
                <Col width="100" medium="50">
                    <Card>
                        <span slot="header" style={{ width: "100%" }}>{contactInfoText}</span>
                        <span slot="content">

                            <List>
                                {contactView}
                            </List>
                        </span>

                    </Card>

                    <Card className="data-table">
                        <span slot="header">Online formular prisudregning:</span>
                        <table>

                            <tbody>
                                {priceInfo}

                            </tbody>
                        </table>
                    </Card>
                    <Card>
                        <span slot="header">Tracking:</span>
                        <StingHistory uid={props.uid} />
                    </Card>

                </Col>
            </Row>
        </Page>
    )



    function updateCreateOrModify() {
        if (currentOfferServerInfo && currentOfferServerInfo !== "LOADING" && currentOfferServerInfo?.status != "FAIL") {
            if (currentOfferServerInfo?.existingCustomer) {
                addOrModifyCustomer = "Opdater kundeemne i CRM";
                contactInfoText.push(
                    <p key="contactRight" style={{ float: "right", color: "rgb(255 160 42)" }}>{"Kunden eksisterer i Care (" + currentOfferServerInfo.existingCustomer.KUNDEKAT + ")"}</p>
                );
            } else {
                contactInfoText.push(
                    <p key="contactRight" style={{ float: "right", color: "rgb(33 160 33)" }}>{"(Ny Kunde)"}</p>
                );
            }
        }
        else {
            if (currentOfferServerInfo?.status == "FAIL") {
                contactInfoText.push(
                    <p key="contactRight" style={{ float: "right", color: "rgb(255 70 70)" }}>{"(INTERNET FORBINDELSES PROBLEM)"}</p>
                );
            } else {
                contactInfoText.push(
                    <p key="contactRight" style={{ float: "right", color: "rgb(111 111 224)" }}>{"(Øjeblik - Analyserer ruter)"}</p>
                );

            }
        }
    }
}


/*
<Menu>
                            <MenuItem text="Ændre status" dropdown>
                                <MenuDropdown left  >
                                    <MenuDropdownItem href="#" text="Tilbud sendt" onClick={(e) => { statusChange(e) }} />
                                    <MenuDropdownItem href="#" text="Ubehandlet" />
                                    <MenuDropdownItem href="#" text="Slet/Ignorer" />
 
                                </MenuDropdown>
                            </MenuItem>
                        </Menu>
 
 
<Card>
                        <span slot="header">Online formular prisudregning:</span>
                        <span slot="content">
 
                            <List>
                                {priceInfo}
                            </List>
                        </span>
 
                    </Card>
*/
// function statusChange(event) {
//     console.log(event);
// }

function clickElement(elementText) {
    //let value = currentOffer[Number(id)].value;

    //    currentconsole.log("clicked element.", id, currentOffer[Number(id)], value);

    copy(elementText);
    showToastTop(elementText);
}

const showToastTop = (text) => {
    // Create toast
    let toastTop;
    if (!toastTop) {
        toastTop = f7.toast.create({
            text: 'Kopieret: ' + text,
            position: 'top',
            horizontalPosition: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
        });
    }
    // Open it

    toastTop.open();
}

function generatePriceInfo(offer) {
    let parsedOffer = OfferHandler.instance.getParsedOffer(offer);


    let fitems = parsedOffer.offeredPrice;

    if (!parsedOffer.offeredPrice) return [];


    //currentOffer = fitems;
    let list = fitems.items.map((items, index) => {
        return getDataListItem(items[0], items[1], index)
    })

    list.push(getDataListItem(fitems.total[0], fitems.total[1], list.length + 1, "#84a971"));
    list.push(getDataListItem(fitems.fradragPris[0], fitems.fradragPris[1], list.length + 1, "#84a971"));

    let otherItems = parsedOffer.parsedFormItems.other;
    let ftItem = otherItems.find((val) => {
        if (val.id === "firstTime") return true;
        return false;
    });

    if (ftItem) {
        let label = ftItem.name.length > 50 ? "Opstartstillæg (Første rengøring):" : ftItem.name;
        list.push(getDataListItem(label, ftItem.value, list.length + 1, "#ccc9ff"));

    }

    return list;
}


function getDataListItem(name, value, index, color = "") {
    return (
        <tr onClick={(_e) => clickElement('' + value)} key={"priceKey" + index}>
            <td className="label-cell">{name}</td>
            <td className="label-cell" style={{ textAlign: "right", color: color, fontWeight: "bold" }}>{value}</td>
        </tr>
    )
}
/*
function getListItem(name, value, index) {
    return (
        <ListItem link="#"
            onClick={(e) => clickElement('' + value)}
            key={"item" + index}
            id={"id" + index}
        >
            <div slot="header" style={{ color: "#71c5b2" }}>{name}</div>
            <div slot="title" style={{ whiteSpace: 'pre-line' }}>{value}</div>
        </ListItem>
    )
}
*/

function generateCustomerDataView(offer, subList) {
    let parsedOffer = OfferHandler.instance.getParsedOffer(offer);


    let fitems = parsedOffer.parsedFormItems[subList];
    //currentOffer = fitems;
    let orderList = fitems.map((items, index) => {
        return (
            <ListItem link="#"
                onClick={(_e) => clickElement('' + items.value)}
                key={"item" + index}
                id={"id" + index}
            >
                <div slot="title" style={{ whiteSpace: 'pre-line', fontWeight: "bold" }}>{items.value}</div>
                <div slot="header" style={{ color: "#a6c7ad" }}>{items.name}</div>
            </ListItem>)
    })

    return orderList;
}

/*<ListItem title={items.value}
                header={items.name}
                text="TEXT"
            >*/

export default OfferPage;
