import { globalRatingStats } from "../../components/rating/RatingData";
import { beConnectorInstance } from "../BeConnector";

const EXCLUDED_ULIDS = { 71: true, 213: true, 428: true, 451: true, 168: true };

let globalUlRatingData = {};

//let globalCustomerData = {};

export async function loadRatingData() {

    let result = await beConnectorInstance.getRatingList();
    globalRatingStats.current = result.preAnalyzedData.analyzedData.globalUlRatingData;
    return result.preAnalyzedData.analyzedData;
    //globalRatingStats.current
}

export async function compileRatingData() {

    globalUlRatingData = globalRatingStats.current = {};

    //    globalCustomerData = {};

    let finalData = {
        ulStats: [],
        globalUlRatingData: globalUlRatingData,
    };
    let result = await beConnectorInstance.getRatingList();
    finalData.ratingDate = result.compiledData.ratingDate;
    console.log("Retrieved rating data");
    if (result.status == "FAIL") {
        return undefined;
    }
    let data = result.compiledData;
    let ratingList = data.ratingList;
    console.log("Analyzing", ratingList.length, "ULs");
    for (const ul of ratingList) {
        if (EXCLUDED_ULIDS[ul.mainInfo.MEDARBNR]) {
            continue
        }



        let ulData = {};
        finalData.ulStats.push(ulData);
        ulData.mainInfo = ul.mainInfo;
        ulData.customerScoreData = calcCustomerScoreData(ul);
        ulData.globalData = calcGlobalScoreData(ul);
        ulData.behaviourData = calcBehaviourScoreData(ul);
    }

    console.log("Global calc");
    analyzeBehaviourData(globalUlRatingData);

    analyzeCustomerScoreData(globalUlRatingData.customerScoreData);


    dividePoints(finalData);
    return finalData;
}

function analyzeCustomerScoreData(data) {
    data.score = data.accumRatingValue / data.ratings || 0;
    data.responseRate = (data.ratings + data.dismissed) / (data.noReaction + data.ratings + data.dismissed) || 0;


}

function dividePoints(finalData) {

    let glBhData = finalData.globalUlRatingData;
    let globalCustomerScoreData = finalData.globalUlRatingData.customerScoreData;
    let ulStats = finalData.ulStats;
    for (const item of ulStats) {
        let points = {
            userRating: 0,
            quitRating: 0,
            smsFail: 0,
            appMistake: 0,
            FreeCleaning: 0,
            loyalty: 0,
            timeMoney: 0,
            cancel: 0,
        };

        item.points = points;
        // ------------------ Customer rating ----------------
        //console.log("item: " + item);

        switch (true) {
            case item.customerScoreData.score == 5:
                points.userRating += 5;
                break;
            case item.customerScoreData.score > 4.5:
                points.userRating += 3;
                break;
            case item.customerScoreData.score > 4.3:
                points.userRating += 2;
                break;
            case item.customerScoreData.score > 4.0:
                points.userRating += 2;
                break;
            case item.customerScoreData.score >= 3.7:
                points.userRating += 1;
                break;

        }

        let ratingModifier = item.customerScoreData.ratings / 10;
        points.userRating = points.userRating * ratingModifier;
        item.customerScoreData.score > globalCustomerScoreData.score && points.userRating++;
        // ------------------ Behaviour points ----------------

        let bhData = item.behaviourData;
        bhData.smsFailRatio < glBhData.smsFailRatio && points.smsFail++;
        bhData.recentAppMistakeRatio < glBhData.recentAppMistakeRatio && points.appMistake++;
        bhData.recentTimeMoneyRatioNormalized < glBhData.recentTimeMoneyRatioNormalized && points.timeMoney++;
        let cancelDiff = glBhData.recentCancelRatio - bhData.recentCancelRatio;

        switch (true) {
            case cancelDiff > 10:
                points.cancel += 3;
                break;
            case cancelDiff > 5:
                points.cancel += 2;
                break;
            case cancelDiff > 0:
                points.cancel += 1;
                break;
            case cancelDiff < -5:
                points.cancel -= 1;
                break;
            case cancelDiff < -10:
                points.cancel -= 2;
                break;
        }

        bhData.recentFreeCleaningRatio < glBhData.recentFreeCleaningRatio && points.FreeCleaning++;

        bhData.recentQuitRatio < glBhData.recentQuitRatio ?
            points.quitRating += (glBhData.recentQuitRatio / bhData.recentQuitRatio) || 0
            :
            points.quitRating -= (bhData.recentQuitRatio / glBhData.recentQuitRatio) || 0;

        bhData.recentQuitRatio <= 0 && (points.quitRating = 0);

        switch (true) {
            case bhData.recentCleaningsCount < 100:
                points.quitRating = Math.min(points.quitRating, 1);
                break;
            case bhData.recentCleaningsCount < 200:
                points.quitRating = Math.min(points.quitRating, 2);
                break;
            case bhData.recentCleaningsCount < 1000:
                points.quitRating = Math.min(points.quitRating, 3);
                break;
            default:
                points.quitRating = Math.min(points.quitRating, 3);
                break;
        }


        let startDate = new Date(item.mainInfo.taskStartDate || item.mainInfo.ANS_DATO);
        let years = (new Date().getFullYear() - startDate.getFullYear());
        //        points.loyalty = years * 0.5;
        //        points.loyalty = Math.min(points.loyalty, 3);

        switch (true) {
            case years >= 6:
                points.loyalty = 3;
                break;

            case years >= 4:
                points.loyalty = 2;
                break;

            case years >= 1:
                points.loyalty = 1;
                break;


        }


        //console.log("points userRating:", JSON.stringify([points, pointsToScore(points)], undefined, "\t"));
        item.pointTotal = pointsToScore(points);

        Object.keys(points).forEach(key => points[key] = Math.round(points[key] * 100) / 100);

        if (bhData.recentCleaningsCount < 50) {
            item.pointTotal = -1000;
        } else if (bhData.recentDataPointRatio < 25) {
            //console.log("LOW DATAPOINT RATIO: ", bhData.recentDataPointRatio, "UL:", item.mainInfo.NAVN, item.mainInfo.MEDARBNR);
            item.pointTotal = -2000;
        }

        console.log("points");
    }

}



function calcBehaviourScoreData(ul) {
    let cust = ul.customers;
    let custEntries = Object.values(cust);

    //    let ulid = ul.mainInfo.MEDARBNR;

    let customerScoreData = {
        customerCount: 0,
        recentCustomerCount: 0,
        recentTimeMoneyCount: 0,
        recentTimeMoneyRatioAcum: 0,
        recentMoneyEarned: 0,
        recentSmsLogs: 0,
        recentTimeSpendtMinutes: 0,
        recentFreeCleanings: 0,
        recentCleaningsCount: 0,
        recentCancelCount: 0,
        recentAppMistakes: 0,
        recentquitCount: 0,
        recentMoneyEarnedLow: 0,
        recentMoneyEarnedHigh: 0,
        recentMoneyEarnedMedium: 0,
        recentTimeSpendtMinutesLow: 0,
        recentTimeSpendtMinutesHigh: 0,
        recentTimeSpendtMinutesMedium: 0,

    };


    let dateMinusYear = new Date();
    dateMinusYear.setFullYear(dateMinusYear.getFullYear() - 1);
    let dateMin = dateMinusYear.getTime();
    let dateNow = new Date().getTime();


    for (const customer of custEntries) {
        customerScoreData.customerCount++;

        //        globalCustomerData[customer.customerId] ||= { uls: {}, endTime: customer.endTime };
        //        globalCustomerData[customer.customerId]["uls"][ulid] ||= [customer.cleaningsCount, customer.adHoc]
        //globalCustomerData[customer.customerId]["uls"][ulid]++;



        /*1519340400000
        1636984955046  // FIX THIS dateQuit > dateMin seems to not work ???
        -- ----------------------------------------------------- */

        let dateQuit = new Date(customer.endTime).getTime();

        if (customer.recentCleaningsCount > 0) {
            if (dateQuit > dateMin && dateQuit < dateNow && customer.cleaningsCount > customer.adHoc) {
                customerScoreData.recentquitCount++;
            }
            customerScoreData.recentCustomerCount++;
            customerScoreData.recentTimeMoneyCount += customer.recentTimeMoneyCount;
            customerScoreData.recentTimeMoneyRatioAcum += customer.recentTimeMoneyRatioAcum;
            customerScoreData.recentMoneyEarned += customer.recentMoneyEarned;
            customerScoreData.recentMoneyEarnedLow += customer.recentMoneyEarnedLow;
            customerScoreData.recentMoneyEarnedHigh += customer.recentMoneyEarnedHigh;
            customerScoreData.recentMoneyEarnedMedium += customer.recentMoneyEarnedMedium;
            customerScoreData.recentSmsLogs += customer.recentSmsLogs;
            customerScoreData.recentTimeSpendtMinutes += customer.recentTimeSpendtMinutes;
            customerScoreData.recentTimeSpendtMinutesLow += customer.recentTimeSpendtMinutesLow;
            customerScoreData.recentTimeSpendtMinutesHigh += customer.recentTimeSpendtMinutesHigh;
            customerScoreData.recentTimeSpendtMinutesMedium += customer.recentTimeSpendtMinutesMedium;
            customerScoreData.recentFreeCleanings += customer.recentFreeCleanings;
            customerScoreData.recentCleaningsCount += customer.recentCleaningsCount;
            customerScoreData.recentCancelCount += customer.recentCancelled;
            customerScoreData.recentAppMistakes += customer.recentAppMistakes;



        }
    }



    addToGlobal(customerScoreData);
    analyzeBehaviourData(customerScoreData);

    return customerScoreData;
}

function analyzeBehaviourData(behaviourData) {
    behaviourData.recentCleaningsPerCustomer = Math.floor(behaviourData.recentCleaningsCount / behaviourData.recentCustomerCount * 100) / 100 || 0;
    behaviourData.recentTimeMoneyRatioOld = Math.floor((behaviourData.recentTimeMoneyRatioAcum / behaviourData.recentTimeMoneyCount) * 100) / 100 || 0;
    behaviourData.recentTimeMoneyRatio = Math.floor((behaviourData.recentMoneyEarned / behaviourData.recentTimeSpendtMinutes) * 100) / 100 || 0;

    behaviourData.recentTimeMoneyRatioLow = Math.floor((behaviourData.recentMoneyEarnedLow / behaviourData.recentTimeSpendtMinutesLow) * 100) / 100 || 0;
    behaviourData.recentTimeMoneyRatioHigh = Math.floor((behaviourData.recentMoneyEarnedHigh / behaviourData.recentTimeSpendtMinutesHigh) * 100) / 100 || 0;
    behaviourData.recentTimeMoneyRatioMedium = Math.floor((behaviourData.recentMoneyEarnedMedium / behaviourData.recentTimeSpendtMinutesMedium) * 100) / 100 || 0;

    let totalTimeMoneyTime = behaviourData.recentTimeSpendtMinutesLow + behaviourData.recentTimeSpendtMinutesMedium + behaviourData.recentTimeSpendtMinutesHigh;

    behaviourData.recentTimeMoneytimeRatioHigh = behaviourData.recentTimeSpendtMinutesHigh / totalTimeMoneyTime * 100;
    behaviourData.recentTimeMoneytimeRatioMedium = behaviourData.recentTimeSpendtMinutesMedium / totalTimeMoneyTime * 100;
    behaviourData.recentTimeMoneytimeRatioLow = behaviourData.recentTimeSpendtMinutesLow / totalTimeMoneyTime * 100;


    let recentTotalTime = behaviourData.recentTimeSpendtMinutesHigh + behaviourData.recentTimeSpendtMinutesLow;
    let highToLow = behaviourData.recentTimeMoneyRatioHigh / 2;
    let lowHighRatio = behaviourData.recentTimeSpendtMinutesHigh / recentTotalTime;
    behaviourData.recentTimeMoneyRatioNormalized = Math.floor(((highToLow * lowHighRatio) + ((1 - lowHighRatio) * behaviourData.recentTimeMoneyRatioLow)) * 100) / 100 || 0;

    behaviourData.recentCancelRatio = (Math.floor((behaviourData.recentCancelCount / behaviourData.recentCleaningsCount * 10000)) / 100) || 0;
    behaviourData.smsFailRatio = (Math.floor(10000 - (behaviourData.recentSmsLogs / behaviourData.recentCleaningsCount * 10000)) / 100) || 0;
    behaviourData.recentAppMistakeRatio = Math.floor(behaviourData.recentAppMistakes / behaviourData.recentCleaningsCount * 10000) / 100 || 0;
    behaviourData.recentQuitRatio = Math.floor(behaviourData.recentquitCount / behaviourData.recentCleaningsCount * 10000) / 100 || 0;
    behaviourData.recentFreeCleaningRatio = Math.floor(behaviourData.recentFreeCleanings / behaviourData.recentCleaningsCount * 10000) / 100 || 0;


    behaviourData.recentDataPointRatio = Math.floor(behaviourData.recentTimeMoneyCount / behaviourData.recentCleaningsCount * 10000) / 100 || 0;




    //console.log("behaviourData.recentTimeMoneyRatio:", behaviourData.recentTimeMoneyRatio)
}

function addToGlobal(customerScoreData) {
    Object.entries(customerScoreData).forEach(([key, val]) => {
        globalUlRatingData[key] ? globalUlRatingData[key] += val : globalUlRatingData[key] = val;
    });
}

function addToObject(target, source) {
    Object.entries(source).forEach(([key, val]) => {
        target[key] ? target[key] += val : target[key] = val;
    });
}


function calcGlobalScoreData(ul) {
    ul.stats.totalEarnings = Math.floor(ul.stats.totalEarnings);

    return ul.stats;

}

function pointsToScore(points) {
    let result = Object.values(points).reduce((prev, current) => prev + current, 0);

    return result;
}

function calcCustomerScoreData(ul) {
    let customerRatings = ul.customerRatings || [];
    let accumRatingValue = 0;
    let dismissed = 0;
    let ratings = 0;
    let noReaction = 0;
    let score = 0;
    let responseRate = 0;
    customerRatings.forEach((item) => {
        if (item.status == 'none') {
            noReaction++;
        } else {
            if (item.value > 0) {
                ratings++;
                accumRatingValue += item.value;
            }
            else {
                dismissed++;
            }
        }
    });
    score = accumRatingValue / ratings || 0;
    responseRate = (ratings + dismissed) / (noReaction + ratings + dismissed) || 0;

    let customerScoreData = {
        score: score,
        ratings: ratings,
        accumRatingValue: accumRatingValue,
        noReaction: noReaction,
        responseRate: responseRate,
        dismissed: dismissed,

    }

    globalUlRatingData.customerScoreData ||= {}
    addToObject(globalUlRatingData.customerScoreData, customerScoreData);
    globalUlRatingData.customerScoreData.numULs ||= 0;
    globalUlRatingData.customerScoreData.numULs++;

    return customerScoreData;
}