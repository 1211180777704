const stateTemplate = { id: "", callback: undefined, queue: [], delay: -1 };

export default class StateManager {

    static stateDispList = {};

    static registerStateDispatcher(id, callback, delayed = false) {

        StateManager.stateDispList[id] ||= { ...stateTemplate };
        StateManager.stateDispList[id].id = id;
        StateManager.stateDispList[id].callback = callback;
        StateManager.stateDispList[id].delay = delayed ? 0 : -1;

        StateManager.callQueue(StateManager.stateDispList[id]);

    }

    static callQueue(stateDef, onlyCallLast = true) {
        while (stateDef?.queue?.length > 0) {
            var callbackPack;
            if (onlyCallLast) {
                callbackPack = stateDef?.queue.pop();
                stateDef.queue = [];
            }
            else {
                callbackPack = stateDef?.queue.shift();

            }
            setTimeout(() => {
                stateDef.callback(callbackPack[1]);
                StateManager.callQueue(stateDef);
            });
        }
    }

    static setState(id, value) {
        if (StateManager.stateDispList[id]) {
            let callback = StateManager.stateDispList[id]?.callback;

            if (StateManager.stateDispList[id].delay >= 0) {
                setTimeout(() => {
                    callback(value);
                }, StateManager.stateDispList[id].delay);
            } else {
                callback(value);

            }

            return;
        }
        StateManager.stateDispList[id] = { ...stateTemplate };
        StateManager.stateDispList[id].queue.push([id, value]);
        StateManager.stateDispList[id].id = id;



    }


}