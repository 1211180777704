import {
    Block,
    BlockTitle, Link, Navbar,
    NavLeft,
    NavRight, Page, Tab, Tabs, Toolbar
} from 'framework7-react';
import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Connector from '../Connector';
import StateManager from '../js/StateManager';
import Offers from '../views/Offers';

const OffersPage = () => {
    const [username, setUsername] = useState('');
    const [area, _setArea] = useState();

    useEffect(() => {
        StateManager.setState('setActivePage', 'tilbud');

    }, []);


    let globalTheme = 'dark';
    const [_theme, _setTheme] = useState(globalTheme);
    const [activePage, setActivePage] = useState('tab-tilbud');

    /*  const setLayoutTheme = (newTheme) => {
         Connector.dispatchState("DARK_MODE", newTheme === "dark");
     };
 
     function logOut() {
         localStorage.removeItem("SH-NEXT-LOGIN");
     } */

    if (Connector.loginName && Connector.loginName != username) {
        setUsername(Connector.loginName);
    }

    //let loggedIn = (username && username.length > 0);
    let loggedIn = true;

    //console.log("The area is ", area);
    return (
        <Page name="home" pageContent={false}>
            <Navbar >
                <NavLeft>
                    <Link iconF7="bars" panelOpen className="panel-open"></Link>
                </NavLeft>
                <NavRight >

                </NavRight>
            </Navbar>
            <Toolbar tabbar bottom>
                {/* <Link tabLink="#tab-postnumre" onClick={(e) => { setActivePage("tab-postnumre") }}>Postnumre</Link> */}
                <Link tabLink="#tab-tilbud" tabLinkActive onClick={() => { setActivePage("tab-tilbud") }}>Nye tilbud</Link>
                <Link tabLink="#tab-old-offers" onClick={() => { setActivePage("tab-old-offers") }}>Behandlede tilbud</Link>
                {/* <Link tabLink="#tab-version">Version {appVer}</Link> */}
            </Toolbar>

            <Tabs animated>
                <Tab id="tab-tilbud" className="page-content" tabActive>
                    <BlockTitle>Online tilbud:</BlockTitle>
                    <Block>
                        <Offers show={activePage === 'tab-tilbud'} status="SENT" area={area} loggedId={loggedIn} />
                    </Block>
                </Tab>
                <Tab id="tab-old-offers" className="page-content">
                    <BlockTitle>Behandlede tilbud:</BlockTitle><Block>
                        <Offers show={activePage === 'tab-old-offers'} status='BINDING_OFFER_SENT' area={area} loggedId={loggedIn} />
                    </Block>

                </Tab>
                <Tab id="hiddenLocalData" className="page-content">

                    <Block>
                        <Iframe
                            url="https://dinside.servicehuset.dk/regLocal-X2V345AGTGS5.html"
                            width="100"
                            height="100"

                            border="1"

                        />
                    </Block>

                </Tab>
                {/*
                <Tab id="tab-postnumre" className="page-content" >
                    <PostCodes show={activePage === 'tab-postnumre'} />

                </Tab>
               
                 <Tab id="tab-version" className="page-content">
                    <BlockTitle>ServiceHuset Next v0.5</BlockTitle>
                    <Block>
                        <span>DarkTheme </span> <br />
                        <Toggle onToggleChange={(state) => {
                            console.log("Toggle", state);
                            setLayoutTheme(state ? "dark" : "light");


                        }} defaultChecked></Toggle>

                    </Block>
                    <Block>{theme}</Block>
                </Tab> */}

            </Tabs>
        </Page>
    );

}
export default OffersPage;