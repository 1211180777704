import { Icon, List, ListItem } from "framework7-react";
import React, { useState } from "react"
import Connector from "../Connector";
import OfferHandler from "../OfferHandler"


const PRIORITIES = ['P1', 'P2', 'P3', 'P4'];
const COLOR_LOOK_UP = ['#00FF00', '#FFFF00', '#FF0000', '#FF0000'];
const PRIORITY_BOUNDS = [1, 2, 12, 24, 48];
const STATE_FRIENDLY_NAME = ['Ubehandlet', 'Ubehandlet (Læst)', 'Afsendt'];
const STATE_FRIENDLY_ICON = ['envelope', 'envelope_open', 'paperplane'];


const OfferList = (props) => {
    const [theList, setTheList] = useState(<div>Henter data...</div>);
    const [area, setArea] = useState('X');
    const [status, setStatus] = useState('X');
    // `https://prlaang.dk/sh5/getOffers`
    //headers: { accept: "application/json" }

    if (props.status !== status) {
        setStatus(props.status);
        OfferHandler.ready = false;
    }
    //console.log("OfferList props.area:", props.area);

    retriveList();

    if (area !== "" + props.area || OfferHandler.getHasChanged()) {
        setArea(props.area);
        if (OfferHandler.ready) {
            updateTheList();
        }
    }

    return (theList)

    function retriveList() {


        if (OfferHandler.ready || OfferHandler.loading) {
            //updateTheList();
            return;

        }

        OfferHandler.instance.getData(props.status).then(() => {
            updateTheList();
        });
    }

    function isDisabled(item) {
        var supportedArea = false;
        if (Connector.userArea.find(value => "area" + value === item.area) !== undefined)
            supportedArea = true;

        return (item.state == '2' && props.status == 'SENT') || supportedArea == false;
        //return (item.state == '2' && props.status == 'SENT') || 'area' + Connector.userArea !== item.area;

    }

    function updateTheList() {
        let list = OfferHandler.list;
        let offersOutput = [];
        let currentArea = 'area' + props.area;
        //console.log("area:", props.area)

        PRIORITIES.forEach(pri => {
            if (pri in list) {
                let items = list[pri];
                items.forEach(item => {
                    let hotness = Math.floor(item.age / (PRIORITY_BOUNDS[parseInt(item.priority)]));
                    let iconColor = COLOR_LOOK_UP[hotness < 3 ? hotness : 2];

                    if (item.area === currentArea) {
                        offersOutput.push(
                            (
                                <ListItem header={"Prioritet: " + item.priority + " - Alder: " + item.age + " timer"}
                                    title={"" + item.name}
                                    footer={`email: ${item.email} - postnummer: ${item.postcode} - telefon: ${item?.dictionary?.telefon?.value} - Værdi: ${item.moneyValue}`}
                                    after={STATE_FRIENDLY_NAME[item.state]}
                                    key={item.uid}
                                    link={"/offer/" + item.uid + "/fromlist/true"}
                                    disabled={isDisabled(item)}
                                >
                                    <Icon f7={STATE_FRIENDLY_ICON[item.state]} slot="media" style={{ color: iconColor }}></Icon>
                                </ListItem>
                            )
                        )

                    }

                })

            }


        })


        setTheList((
            <div>Viser område: {props.area}
                <List>
                    {offersOutput}
                </List>
            </div>
        ));
    }


}


export default OfferList;