import { sharedData } from "../../data/SharedData";
import { beConnectorInstance } from "../../js/BeConnector";

export const teamsTESTData = [
    { name: "Dingo", ulid: "123", area: 1, customerCount: 10, rating: 4.5 },
    { name: "Bob", ulid: "234", area: 3, customerCount: 20, rating: 3.2 },
    { name: "Charlie", ulid: "345", area: 4, customerCount: 15, rating: 4.8 },
    { name: "David", ulid: "456", area: 5, customerCount: 5, rating: 2.7 },
    { name: "Eve", ulid: "567", area: 1, customerCount: 12, rating: 4.1 },
    { name: "Frank", ulid: "678", area: 3, customerCount: 8, rating: 3.9 },
    { name: "Grace", ulid: "789", area: 5, customerCount: 18, rating: 4.6 }
];

const DEFAULT_FIELDS = ["name", "ulid", "area", "postcode", "email", "mainPhone"];
const TEAMS_LIST_TRANSLATIONS = { name: "Navn", customerCount: "Antal kunder", area: "Område", ulid: "ULID", postcode: "Postnummer", email: "Email", mainPhone: "Telefon" };

export class TeamsData {
    calendarData = undefined;
    calendarDataLookup = {};
    rawData = undefined;
    rawList = undefined;
    filterText = "";
    filterAreas = undefined

    constructor() {
        console.log("TeamsData constructor");
        //this.rawList = teamsTESTData;
    }

    async saveTeamData(list) {
        //console.log("TeamsData saveTeamData", list);

        list = list.map((item) => {
            return { ...item, issues: undefined };

        });

        let result = await beConnectorInstance.saveTeamsCalendarData(list, sharedData.getCurrentUserName());
        return result;
    }

    async getCustomerIssues() {
        const result = await beConnectorInstance.getCustomerIssues();
        return result;
    }


    async loadFromServer() {
        console.log("TeamsData loadFromServer");
        this.rawData = await beConnectorInstance.getTeamsCalendarData();
        this.rawList = this.rawData.baseData;
        this.calendarData = []
        for (const item of this.rawList) {
            //console.log("item", item);
            let cleanedName = removeNumbers(item.name);
            //console.log("cleanedName", cleanedName, "-", item.name);
            item.name = cleanedName;
            item.mainPhone = item.phone1 || item.phone2;
            let calDataEntry = {
                name: item.name,
                area: item.area,
                ulid: item.ulid,
                start: item.startDate,
                end: item.endDate,
                availableEvenWeeks: item.availableEvenWeeks,
                availableOddWeeks: item.availableOddWeeks,
                previousTimestamp: item?.calendarTimestamp || "NOT_SET",
                intervals: item?.intervals || [],
                calendarLastEditBy: item?.calendarLastEditBy || "",
                calendarTimestamp: item?.calendarTimestamp,

            };

            /*            for (const obj of calDataEntry.intervals) {
                            let interval = obj.interval;
                            obj.interval = [DateTime.fromISO(interval[0], { zone: 'Europe/Copenhagen' }), DateTime.fromISO(interval[1], { zone: 'Europe/Copenhagen' })];
                        } */


            this.calendarDataLookup[item.ulid] = calDataEntry;
            item.calDataEntry = calDataEntry;

            this.calendarData.push(calDataEntry);

        }



    }


    getIsReady() {
        if (this.rawList) return true;
        return false;
    }


    setFilter(text, areas) {
        this.filterText = text;
        this.filterAreas = areas;
    }

    filterByText(data) {
        return data.filter(team =>


            team.name.toLowerCase().includes(this.filterText.toLowerCase()) ||
            ("" + team.ulid).toLowerCase().includes(this.filterText.toLowerCase())
        );
    }

    filterByAreas(data) {
        if (!this.filterAreas) return data;
        return data.filter(team => this.filterAreas[team.area]);
    }

    getCurrentList() {
        if (!this.rawList) return [];
        let out = this.filterByAreas(this.rawList);
        out = this.filterByText(out);
        return out;
    }

    getTeamsCalendarList() {
        let list = this.getCurrentList();
        return list.map(team => team.calDataEntry);
    }

    getActiveListFields() {
        return DEFAULT_FIELDS;
    }

    getListTranslations() {
        return TEAMS_LIST_TRANSLATIONS;
    }


}


function removeNumbers(str) {
    const regex = /(?:tel|tlf|tele)?[\s.\-:]*(\d{2,})/gi;
    const matches = str.match(regex);
    if (matches) {
        matches.forEach(match => {
            str = str.replace(match, '');
        });
    }
    return str.trim();
}


export const teamsData = new TeamsData();