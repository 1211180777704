import { Card, CardContent, CardHeader, Checkbox, f7, Link } from 'framework7-react';
import React, { useEffect, useState } from 'react';

let _statref;
const headers = ["Navn", "Adresse", "Postnummer", "UL Rating", "Mail", "Telefon"];

const CustomerList = ({ list }) => {
    const [activeHeader, setActiveHeader] = useState("Navn");
    //const customerListRef = useRef(undefined);
    const [custList, setCustList] = useState(undefined);
    //const sortOrder = useRef(undefined);
    //let list = props.list;


    useEffect(() => {
        console.log("Generate list", 105);
        list && (setCustList(generateList(activeHeader)));

    }, [list, activeHeader]);

    if (list == undefined) { return null }
    //customerListRef.current||=generateList(activeHeader);
    //let custList = customerListRef.current;

    function sortType(e) {
        setActiveHeader(e.currentTarget.id);
        console.log("sortType", activeHeader, e.currentTarget.id);
    }

    let headerList = headers.map((name) => {
        return (<th className={"clist-header-" + name + " sortable-cell sortable-asc" + (name == activeHeader ? "sortable-cell-active" : "")} key={name} id={name} onClick={sortType}>{name + ":"}</th>)
    });

    return (
        <div>
            <Card className="data-table data-table-init">
                <CardHeader>
                    <div ref={ref => { _statref = ref }} className="data-table-title">{"Kunder"}</div>
                    <div className="data-table-actions">
                        <Link
                            iconIos="f7:line_horizontal_3_decrease"
                            iconAurora="f7:line_horizontal_3_decrease"
                            iconMd="material:sort"
                        />
                        <Link
                            iconIos="f7:ellipsis_vertical_circle"
                            iconAurora="f7:ellipsis_vertical_circle"
                            iconMd="material:more_vert"
                        />
                    </div>
                </CardHeader>
                <CardContent padding={false}>
                    <table>
                        <thead>
                            <tr>
                                <th className="checkbox-cell">
                                    <Checkbox />
                                </th>
                                {headerList}
                                {/* <th className="sortable-cell sortable-cell-active" onClick={sortType}>
                                    Navn:
                                </th>
                                <th className="sortable-cell">Adresse:</th>
                                <th className="sortable-cell">Postnummer:</th>
                                <th className="sortable-cell">email:</th>
                                <th className="sortable-cell">Telefon:</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {custList}
                        </tbody>
                    </table>
                </CardContent>


            </Card>
        </div>
    )

    function itemClicked(e) {
        console.log("itemClick", e.currentTarget.id);
        f7.views.main.router.navigate("/customer-page/" + e.currentTarget.id);
    }

    function generateList(sortBy) {
        console.log("---- generateList CALLED WITH SORT:" + sortBy);
        list.sort((elem1, elem2) => {
            let tlf1, tlf2;
            let res = 0;

            switch (sortBy) {
                case "UL Rating":
                    switch (true) {
                        case (elem1.ratingAverage == elem2.ratingAverage):
                            return 0;
                        case elem1.ratingAverage == '?':
                            return 1
                        case elem2.ratingAverage == '?':
                            return -1
                        default:
                            return elem2.ratingAverage - elem1.ratingAverage;
                    }

                case "Navn":
                    res = elem1.FIRMA > elem2.FIRMA;
                    break;
                case "Adresse":
                    res = (elem1.ST_ADDR1 || elem1.ADDR1) > (elem2.ST_ADDR1 || elem2.ADDR1);
                    break;
                case "Postnummer":
                    res = (elem1.ST_POSTNR || elem1.POSTNR) > (elem2.ST_POSTNR || elem2.POSTNR);
                    break;
                case "Mail":
                    res = (elem1.ST_EMAIL || elem1.EMAIL) > (elem2.ST_EMAIL || elem2.EMAIL);
                    break;
                case "Telefon":
                    tlf1 = (elem1.ST_TLF || elem1.TLF || "").replace(/\s+/g, '');
                    tlf2 = (elem2.ST_TLF || elem2.TLF || "").replace(/\s+/g, '');
                    res = tlf1 > tlf2;
                    break;

            }

            return res ? 1 : -1;
        })

        let result = list.map((value, _index) => {
            let tlf = (value.ST_TLF || value.TLF || "").replace(/\s+/g, '');
            return (
                <tr key={value.KUNDEID + "-" + value.ADRESSENR} id={value.KUNDEID} name={"pc-" + value.KUNDEID} onClick={itemClicked} style={{ cursor: "pointer" }}>
                    <td className="checkbox-cell">
                        <Checkbox />
                    </td>
                    <td className="label-cell">{value.FIRMA}</td>
                    <td className="label-cell">{value.ST_ADDR1 || value.ADDR1}</td>
                    <td className="label-cell">{value.ST_POSTNR || value.POSTNR}</td>
                    <td className="label-cell table-td-size1">{value.ratingAverage}</td>
                    <td className="label-cell table-td-size3">{value.ST_EMAIL || value.EMAIL}</td>
                    <td className="label-cell">{tlf}</td>

                </tr>)



        })
        return result;
    }


}
export default CustomerList;
