
export default class Config {
    static version = "?.?.?";
    static startPage = "/offers-page/";

    //static startPage = "/customers/";
    //static IS_PRODUCTION = true
    static IS_PRODUCTION_REAL = Config.setIsProduction();
    static IS_PRODUCTION = Config.setIsProduction();

    static setIsProduction() {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "")  // Not perfect but it does the trick
        {
            return false;
            //alert("It's a local server!");
        }
        return true
    }



}