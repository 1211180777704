import React, { Fragment, useState } from "react";
import "./TeamsList.css";

export function TeamsList({ list, onItemClick, translation, mainFields }) {
    const [expandedRows, setExpandedRows] = useState({});
    if (!list || list.length === 0) return (<div className="teams-list-empty">Ingen teams fundet</div>)
    const rows = mainFields;

    function _expandRow(index, rowData) {
        expandedRows[index] = !expandedRows[index];
        setExpandedRows({ ...expandedRows })
        console.log("index", index, "rowData", rowData);
    }

    return (
        <table className="team-list-table">
            <thead>
                <tr>
                    {rows.map((row) => (
                        <th key={row}>{translation && translation[row] ? translation[row] : row}:</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {list.map((item, index) => {
                    let style = {};
                    if (item.color) {
                        style = { background: item.color }
                    }

                    return (
                        <Fragment key={index}>
                            <tr style={style} key={index} className={index % 2 === 0 ? "even" : "odd"} onClick={() => {
                                onItemClick(item);
                                //expandRow(index, item) 
                            }}>
                                <TeamRow rowData={item} rows={rows} />
                            </tr>
                            <tr >
                                <td colSpan={rows.length}><div className={"team-content-block " + (expandedRows[index] ? "expanded" : "closed")}>UL INFO</div></td>
                            </tr>
                        </Fragment>
                    )
                }
                )}
            </tbody>
        </table>
    );
}

function TeamRow({ rowData, rows }) {
    return <>
        {rows.map((row) => (
            <td key={row} style={{ padding: "0.5rem", borderTop: "none", borderBottom: "none" }}>{rowData[row]}</td>
        ))}
    </>
}