import { Block, Button, Checkbox, Input, Link, NavLeft, Navbar, Page, f7ready } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
//import { TeamsList } from '../components/teams/TeamList';
import { INTERVAL_STATES, INTERVAL_STATE_COLORS, INTERVAL_STATE_LABELS, MENU_IDS, TeamsCalendar } from '../components/teams/TeamsCalendar';
import { teamsData } from '../components/teams/TeamsData';
import { sharedData } from '../data/SharedData';
import Toaster from '../js/utils/Toaster';
import { useDebounce } from '../js/utils/universalUtils';
import { contextMenuController } from '../utils/ContextMenu/TinyContextMenu';
import { storageMaster } from '../utils/StorageMaster';

const SELECTED_AREAS_KEY = "teams_page_areas_selected";
const NUM_DAYS_KEY = "teams_page_NUM_DAYS";
const TEAMS_AVAILABLE_AREAS = [1, 5, 3, 4];

const AVAILABLE_INTERVAL_TYPES = [
    INTERVAL_STATES.VACATION,
    INTERVAL_STATES.SICKNESS,
    INTERVAL_STATES.UNAUTHORIZED,
];


// eslint-disable-next-line no-unused-vars
/* const chromeContextMenu = new ContextMenu(document.body, [{ text: 'Back', hotkey: 'Alt+Left arrow', disabled: true },
{ text: 'Forward', hotkey: 'Alt+Right arrow', disabled: true },
{ text: 'Reload', hotkey: 'Ctrl+R' }]);
chromeContextMenu.install();
 */
export default function TeamsPage() {
    //storageMaster.delete(SELECTED_AREAS_KEY);
    const [activeSearchText, setActiveSearchText] = useState("");
    const [selectedAreas, setSelectedAreas] = useState({});
    const isLoading = useRef(false);
    const selectedAreasRef = useRef(selectedAreas);
    selectedAreasRef.current = selectedAreas;
    useEffect(() => {
        async function getData() {
            isLoading.current = true;
            Toaster.showPreloader("Henter data");
            let result = await teamsData.loadFromServer();
            console.log("TeamsPage useEffect getData", result);
            Toaster.closePreloader();
            isLoading.current = false;
            setSelectedAreas({ ...selectedAreasRef.current });
        }
        !isLoading.current && getData();

        f7ready(() => {
            const selected = storageMaster.retrieve(SELECTED_AREAS_KEY) || { [[sharedData.getArea()]]: true };
            teamsData.setFilter(activeSearchText, selected);
            setSelectedAreas(selected);
        });
        console.log("TeamsPage useEffect");
    }, []);


    function textChange(e) {
        console.log("textChange", e.currentTarget.value);
        teamsData.setFilter(e.currentTarget.value, selectedAreas);
        setActiveSearchText(e.currentTarget.value);
    }

    function handleAreaChange(area, checked) {
        let selected = { ...selectedAreas };
        selected[area] = checked;
        storageMaster.save(SELECTED_AREAS_KEY, selected);
        teamsData.setFilter(activeSearchText, selected);
        setSelectedAreas(selected);

    }

    //console.log("selectedAreas", selectedAreas, "array:", Object.keys(selectedAreas).filter((key) => selectedAreas[key]).map(Number));
    return (<>

        <Page>
            <Navbar title={"Teams"} backLink >
                <NavLeft>
                    <Link iconF7="bars" panelOpen className="panel-open"></Link>
                </NavLeft>
            </Navbar>
            <Block strong>
                <div style={{ display: "flex", justifyContent: "flex-end", textAlign: "right" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="trans-50" style={{ marginRight: "0.5rem" }}>Søgetekst:</span>
                        <Input
                            inputStyle={{ fontSize: "1.1rem" }}
                            style={{ maxWidth: "12rem", border: "solid 1px #FFFFFF22" }}
                            type="text"
                            inputId="freeTextSearch"
                            onChange={textChange}
                            defaultValue={activeSearchText}
                        />
                        <div style={{ minWidth: "2rem" }} />
                        {TEAMS_AVAILABLE_AREAS.map((area) => (
                            <div key={area} className="teams-main-header-checkbox">
                                <Checkbox
                                    style={{ marginBottom: "0.1rem" }}
                                    checked={!!selectedAreas[area]}
                                    onChange={(e) => handleAreaChange(area, e.target.checked)}
                                />
                                &nbsp; Område {area}
                            </div>
                        ))}
                    </div>
                </div>
            </Block>
            <Block strong>

                <CalendarComp />
            </Block>
            {/* <Block>
                <TeamsList
                    list={teamsData.getCurrentList()}
                    onItemClick={(item) => console.log("onItemClick", item)}
                    translation={teamsData.getListTranslations()}
                    mainFields={teamsData.getActiveListFields()}
                />
            </Block> */}
        </Page>
    </>
    );
}

function CalendarComp() {
    const [startDate, setStartDate] = useState(new Date());
    const [numDays, setNumDays] = useState(storageMaster.retrieve(NUM_DAYS_KEY) || 60);
    const [intervalType, setIntervalType] = useState(INTERVAL_STATES.VACATION);
    const [showCalendar, _setShowCalendar] = useState(true);
    const startDateDebounced = useDebounce(startDate, 500);
    const numDaysDebounced = useDebounce(numDays, 500);

    function handleStartDateChange(event) {
        !!event.target.value && setStartDate(new Date(event.target.value));
        //console.log("handleStartDateChange", event.target.value);
    }

    async function onListUpdated(list) {
        //console.log("onListUpdated", list);
        await teamsData.saveTeamData(list);
        console.log("onListUpdated", list);

        return undefined;
        //        const issues = await teamsData.getCustomerIssues();
        //        return issues;
    }

    function handleNumDaysChange(event) {
        setNumDays(parseInt(event.target.value));
        storageMaster.save(NUM_DAYS_KEY, event.target.value);
        //console.log("handleNumDaysChange", event.target.value);
    }
    //console.log("CalendarComp", startDateDebounced, numDaysDebounced);
    return (
        <>
            <div style={{ display: "flex", borderBottom: "none", justifyContent: "space-between" }} className='calendar-comp-border'>
                <div className='calendar-comp-settings'>
                    {/*                     <Button className='calendar-button' onClick={() => { setShowCalendar(!showCalendar) }}>hide</Button>
                    <Button className='calendar-button'>Button 1</Button>
                    <Button className='calendar-button' onClick={() => { contextMenuController.removeListener() }}>removeContextMenu</Button> */}
                    {AVAILABLE_INTERVAL_TYPES.map((type) => {
                        return (<Button
                            className='calendar-button'
                            key={type}
                            onClick={() => { setIntervalType(type) }}
                            style={{ background: INTERVAL_STATE_COLORS[type + "_SAVED"], border: (type != intervalType) ? "0px solid #FFFFFFAA" : "2px solid #f9ffc5" }}
                        >{INTERVAL_STATE_LABELS[type][1]}</Button>)
                    })}
                </div>
                <div><Button className='calendar-button' onClick={() => {
                    contextMenuController.itemClicked(MENU_IDS.SAVE_ALL);
                }}>Godkend/Gem alt</Button></div>
                <div className='calendar-comp-settings'>
                    <label className="item-label" style={{ margin: "0 0.5rem" }}>Start dato:</label>
                    <Input style={{ padding: "0 0.5rem", background: "#333344" }} type="date" value={startDate.toISOString().substr(0, 10)} onInput={handleStartDateChange} />
                    <label className="item-label" style={{ margin: "0 0.5rem" }}>Vis antal dage frem:</label>
                    <Input style={{ padding: "0 0.5rem", background: "#333344", maxWidth: "3rem" }} type="number" value={numDays} onInput={handleNumDaysChange} />

                </div>
            </div>
            <div style={{ display: "flex" }} className='calendar-comp-border'>

                {showCalendar && <TeamsCalendar
                    startDate={startDateDebounced}
                    numDays={numDaysDebounced}
                    list={teamsData.getTeamsCalendarList()}
                    intervalType={intervalType}
                    onListUpdated={onListUpdated}
                />}

            </div>

        </>

    )


}