export const teamsDefPref = {
    "userName": "",
    "mailName": "",
    "mailEmail": "",
    "mailTitle": "",
    "mailPic": "",
    "area": "",
    "preferences": {
        "Alex og Gabriella (117)": {
            "Basis instillinger": {
                "Data": {
                    "Procent andel": 70,
                    "Forsikret": true,
                    "Opholdstilladelse": true,
                    "Kontrakt": true,
                    "GDPR": true,
                },
                "App": {
                    "Vis opgave pris (fuld)": false,
                    "Vis opgave pris (andel)": true,
                    "Vis oversigt over periode": true,
                    "Rapporter forkert brug": true,
                    "Accepterede fejlprocent ": 5
                },
                "Kunder": {
                    "Typisk antal assistenter pr. kunde": 2,
                    "Max assistenter pr. kunde": 2,
                    "Max kunder pr. dag": 6,
                    "Max kunder pr uge:": 30,
                    "Max timer pr. dag": 8,
                    "Max rejsetid pr. dag (min.)": 70,
                },
                "Status": {
                    "On boarding": false,
                    "Off boarding": false,
                    "Tilføj ikke nye kunder": true,
                    "Særlig opmærksom": false,
                    "Intern vurdering": 4
                },
                "Varsler og info": {
                    "Send daglig rute": true,
                    "Send advarsel om forkert brug af App": false,
                    "Max aflysninger pr. måned": 5,
                    "Max datoændringer pr. måned": 10
                }
            }
        }
    }
};
