import { beConnectorInstance } from "./BeConnector";
import Config from "./Config";

const _MAX_LIST_AGE = 30;

const areaLookup = {
    '0': "",
    '1': "alice@servicehuset.dk",
    '4': "camilla@servicehuset.dk",
    '3': "yvonne@servicehuset.dk",
    '5': "steen@servicehuset.dk"
}

let SERVERURL = "https://next-v2-be.servicehuset.dk/nnxt/custSearch?";
//const SERVERURL = "https://next1.servicehuset.dk/be_v_100/custSearch?";

export default class CustomerHandler {
    static lastResult = [];

    static instance;

    static getInstance() {
        if (!CustomerHandler.instance) {

            CustomerHandler.instance = customerHandler;

            //CustomerHandler.instance = new CustomerHandler();
        }
        return CustomerHandler.instance;
    }

    constructor() {
        if (!Config.IS_PRODUCTION)
            SERVERURL = "http://localhost:8090/nnxt/custSearch?";

    }

    freshResults = undefined;
    categories = [];
    categoryTitles = [];
    paymentIds = [];
    rawTasks = [];

    resultRequested = false;

    searchCache = {};

    isLoading = false;
    listUpdated = 0;
    updateResultCallback;
    updateCountCallback;
    activeFilters = {};
    searchVals = [];

    searchParam1 = "";
    searchParam2 = "";

    sParams = {};


    getCustomerById(id) {
        if (this.freshResults[id] && this.freshResults[id].status == "OK") {
            return this.freshResults[id].userData;
        }

        if (!CustomerHandler.lastResult)
            return undefined;

        let result = CustomerHandler.lastResult.find(elem => elem.KUNDEID == id);
        return result;
    }


    load(updateResultCallback, updateCountCallback) {
        this.updateCountCallback = updateCountCallback;
        this.updateResultCallback = updateResultCallback;

        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        setTimeout(() => {
            this.listUpdated = new Date().getTime() / 1000;
            this.isLoading = false;
            //this.updateResultCallback.apply(this,[""])
        }, 2000)



        console.log("load now");
    }

    sParamsStr() {
        let res = "";
        Object.entries(this.sParams).forEach(([name, val], index) => {
            index > 0 && (res += "&");
            res += name + "=" + val;
        })

        return res;

    }

    async retrieveResult(max = 700) {
        if (this.resultRequested) { return }
        this.resultRequested = true;
        setTimeout(() => { this.resultRequested = false }, 4000);

        let seachStr = "str=" + this.searchParam1 + "&str2=" + this.searchParam2 + "&max=" + max + "&" + this.sParamsStr();
        let cachedData = this.searchCache[seachStr];
        if (cachedData) {
            this.handleResult(cachedData);
            this.resultRequested = false;
            return;
        }

        try {
            let res = await fetch(SERVERURL + seachStr);
            var data = await res.json();
            //console.log("Received:", data);


        } catch (error) {
            console.log("FAILED TO FETCH Customer data", error);
        }

        this.resultRequested = false;
        this.searchCache[seachStr] = data;
        this.handleResult(data);


    }

    async handleResult(data) {


        if (data && data.count) {
            this.updateCountCallback(data.count);
        } else {
            this.updateCountCallback(0);
        }

        if (data && data.list) {
            this.processResult(data.list);


            this.updateResultCallback(data.list);
            CustomerHandler.lastResult = data.list;
        }
        else {
            this.updateResultCallback(undefined);
        }

    }

    async processResult(list) {
        for (const item of list) {
            if (!item.ratings || item.ratingAverage) {
                item.ratingAverage ||= "?";
                continue;
            }
            let totalRating = 0;
            let totalRatingCount = 0;

            Object.values(item.ratings).forEach((val) => {
                console.log("ratings process");
                val.average = 0;
                for (const rating of val.ratings) {
                    val.average += rating.rating;
                    totalRating += rating.rating;
                    totalRatingCount++;
                }
                val.average = Math.floor(100 * val.average / val.ratings.length) / 100;

            });
            item.ratingAverage = Math.floor(100 * totalRating / totalRatingCount) / 100;

        }
    }

    setMultiSearchFields(fields) {


        console.log("setMultiSearchFields:", fields);
    }

    multiSearch(text) {
        this.searchParam2 = text;
        console.log("multiSearch:", text);

        setTimeout(() => {
            this.callServer();

        }, 500);

    }

    setFilter(name, value) {

        this.activeFilters[name] = value;

        console.log("setFilter", name, value);

        switch (name) {
            case "area":
                var newArea = areaLookup[value];
                if (newArea != this.searchParam1) {
                    this.searchParam1 = newArea;

                }
                break;

            default:
                this.sParams[name] = value;
                break;
        }
        this.callServer();
    }

    callServer() {
        console.log("Calling server");
        setTimeout(() => {
            this.retrieveResult();
        }, 50);
    }

    async loadCustomerdata(id) {
        const result = await beConnectorInstance.loadCustomerdata(id);
        if (result && result.status == "OK") {
            this.freshResults ||= {};
            this.freshResults[id] = result;
            this.categories = result.categories;
            this.categoryTitles = result.categories.map(elem => elem.CUSTCAT);
            this.paymentIds = result.paymentIds;
            this.rawTasks = result.tasks;

            this.divideTasks(this.rawTasks);


        }


        return result;
    }

    tasks = {};

    async divideTasks(tasks) {
        this.tasks = {};
        tasks.forEach((task) => {
            this.tasks[task.OPGAVENR] ||= { tasks: [], TaskId: task.OPGAVENR, type: task.OPGAVETYPE, exeTime: task.UDFOERES_KLOKKEN };
            this.tasks[task.OPGAVENR].tasks.push(task);
        });


    }



}

export const customerHandler = new CustomerHandler();

