// https://direct.servicehuset.dk/data/cfg/next-fe-cfg.json

export class FEConfig {
    externalConfig;

    constructor() {
        this.loadConfig().then((config) => {
            console.log('Config loaded:', config);
        });
    }

    async init() {
        //console.log('FEConfig init');
    }

    async getOrderData() {
        if (!this.externalConfig) await this.loadConfig();
        return this.externalConfig.settings.customerForm.displayOrder;

    }

    async loadConfig() {
        const cacheBreaker = new Date().getTime(); // Cache breaker using current timestamp
        const url = `https://direct.servicehuset.dk/data/cfg/next-fe-cfg.json?_=${cacheBreaker}`;

        try {
            const response = await fetch(url);
            const config = await response.json();
            this.externalConfig = config;
            // Process the loaded config as needed
            console.log(config); // Example: Log the loaded config

            // Return the loaded config or perform additional operations
            return config;
        } catch (error) {
            console.error('Error loading config:', error);
            // Handle the error appropriately
            throw error;
        }
    }
}


export const feConfig = new FEConfig();