import { Block, Col, Row } from 'framework7-react';
import React from 'react';
import ULCard from './ULCard';

const areaLookup = {
    "Vinduespudser": "VP",
    "UL område 1": "1",
    "UL område 2": "1",
    "UL område 3": "3",
    "UL område 4": "4",
    "UL område 5": "5",

}

export default function RatingLanes({ areaData, ratingData }) {
    const areaInfo = areaData.areas;
    const sortingOrder = areaData.sortingOrder;
    console.log("sorting", sortingOrder);
    //let lanes = generateLanes(activeAreas)

    sortData(ratingData.ulStats, sortingOrder, areaData.sortingDirection);

    return (
        <Row>
            {generateLanes(areaInfo, ratingData)}
        </Row>

    )

    function generateLanes(activeAreas, ratingData) {

        let lanes = Object.values(activeAreas).filter(current => current.show && current.status != 'inactive');


        let out = lanes.map((current) => {
            return (<Col className='rating-lane' key={current.id}>
                <Block strong>
                    <h3>{current.friendlyName}</h3>
                </Block>
                <Row>
                    {cards(current.id, ratingData, lanes.length)}
                </Row>


            </Col >);

        })
        return out;
    }

}

function sortData(ratingData, sortingOrder, direction) {
    console.log("Sorting data...");

    ratingData.sort((in1, in2) => {

        let elem1 = direction ? in1 : in2;
        let elem2 = direction ? in2 : in1;



        switch (sortingOrder) {
            case "ALPHA":
                return elem2.mainInfo.NAVN.localeCompare(elem1.mainInfo.NAVN);

            case "TOTAL_RATING":
                return elem2.pointTotal - elem1.pointTotal;
            case "NUM_TASKS":
                return elem2.globalData.cleaningsCount - elem1.globalData.cleaningsCount;
            case "QUITRATE":
                return elem2.behaviourData.recentQuitRatio - elem1.behaviourData.recentQuitRatio;
            case "LTV":
                return elem2.globalData.totalEarnings - elem1.globalData.totalEarnings;
            case "SMS":
                return elem2.behaviourData.smsFailRatio - elem1.behaviourData.smsFailRatio;
            case "CANCEL":
                return elem2.behaviourData.recentCancelRatio - elem1.behaviourData.recentCancelRatio;
            case "STARS":
                return elem2.customerScoreData.score - elem1.customerScoreData.score;

            default:
                return 0;

        }

    })

}




const LANE_SETUP = { 1: "33", 2: "50", 3: "100" };

function cards(area, ratingData, numLanes) {
    //let list = [{ id: 111, name: "bob" }, { id: 123, name: "dingo" }, { id: 222, name: "dongo" }]

    let cardList = [];

    ratingData.ulStats.forEach((item, index) => {
        let target = areaLookup[item.mainInfo.ANS_KAT];
        if (target) {
            (target == area || area == 0) && cardList.push(generateCard(item, area + "-" + index));
        } else {
            console.log("missing index:", item.mainInfo.ANS_KAT);
        }

    });

    function generateCard(ratingItem, key) {
        return <Col width={"100"} large={LANE_SETUP[numLanes] || "100"} key={"col-key-" + key}><ULCard data={ratingItem} key={"key-" + key + ratingItem.mainInfo.MEDARBNR} /></Col>
    }

    /*     let cardlist = list.map((item) => {
    
            //  return <div key={item.id}>TEST</div>
            return <ULCard data={item} key={item.id} />
        })
     */
    //return [<div key="1">TEST</div>, <div key="2">TEST</div>]

    return cardList;

}
