import React, { useEffect } from 'react';
import { Page, Navbar, Block, BlockTitle, Preloader, f7 } from 'framework7-react';
import Connector from '../Connector';
import Config from '../js/Config';

const StartScreenPage = (_props) => {

    useEffect(() => {
        if (!Connector.initialBaseUrl) {
            goToNextPage();
        } //return null;

    }, [])

    function goToNextPage() {

        if (Connector.ready == true) {
            f7.views.main.router.navigate(Config.startPage);
        }

        setTimeout(() => {
            if (Connector.ready == false) {
                goToNextPage();
                return;
            }
            f7.views.main.router.navigate(Config.startPage);

        }, 1000)
    }

    return (<Page>
        <Navbar title="" />

        <Block >
            <div style={{ overflow: 'hidden', height: '260px' }}>
                <p className="text-align-center"><img src="/icons/192x192.png" style={{ position: 'relative', top: "25px" }} /></p>
                <p className="text-align-center" style={{ position: 'relative', top: "-210px" }}><Preloader color="multi" size={242} /></p>
            </div>
            <p className="text-align-center"><img src="/logo/NEXT.png" width="300px" /></p>
        </Block>
        <Block strong>
            <p className="text-align-center">
                Øjeblik - henter data
            </p>
        </Block>
    </Page>)

}


export default StartScreenPage;
