import { Block, Button, Col, Row } from 'framework7-react';
import React, { useState } from 'react';
import Connector from '../Connector';
import OfferList from '../views/OfferList';


const Offers = (props) => {

    // eslint-disable-next-line no-undef
    if (Connector.initialBaseUrl) return null;


    const [_areai, setArea] = useState('' + props.area);
    Connector.registerStateDispatch("SET_AREA", setArea);
    //Connector.registerStateDispatch("changeArea", setArea);
    let area = '' + Connector.currentArea;
    //console.log("--Creating Offers now: Area = ", area, "prop=", props.area, "Connector.currentArea", Connector.currentArea);

    function areaClicked(theArea) {
        console.log("Connector.currentArea", Connector.currentArea);
        Connector.currentArea = theArea;
        Connector.getInstance().area = theArea;

        console.log("clicked", theArea);
        setArea(theArea);
    }


    if (props.show == false || props.loggedId == false) {
        return (<div></div>);
    }

    //console.log(" ? Creating Offers now: Area = ", area, "prop=", props.area, "Connector.currentArea", Connector.currentArea, "areai", areai);
    return (
        <div>
            <Block strong>
                <Row>
                    <Col width="50" medium="20" style={{ marginTop: "0.3rem" }}>
                        <Button fill raised id={'1'} color={area === '1' ? "deeppurple" : ""} onClick={(event) => areaClicked(event.target.id)}>Område #1</Button>
                    </Col>
                    <Col width="50" medium="20" style={{ marginTop: "0.3rem" }}>
                        <Button fill raised id={'3'} color={area === '3' ? "deeppurple" : ""} onClick={(event) => areaClicked(event.target.id)}>Område #3</Button>
                    </Col>
                    <Col width="50" medium="20" style={{ marginTop: "0.3rem" }}>
                        <Button fill raised id={'4'} color={area === '4' ? "deeppurple" : ""} onClick={(event) => areaClicked(event.target.id)}>Område #4</Button>
                    </Col>
                    <Col width="50" medium="20" style={{ marginTop: "0.3rem" }}>
                        <Button fill raised id={'5'} color={area === '5' ? "deeppurple" : ""} onClick={(event) => areaClicked(event.target.id)}>Område #5</Button>
                    </Col>
                    <Col width="50" medium="20" style={{ marginTop: "0.3rem" }}>
                        <Button fill raised id={'99'} color={area === '99' ? "deeppurple" : ""} onClick={(event) => areaClicked(event.target.id)}>Område #99</Button>
                    </Col>
                </Row>
            </Block>

            <OfferList area={area} status={props.status} />








        </div>


    )

}

export default Offers