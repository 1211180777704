import { Block, BlockTitle, Link, Navbar, NavLeft, NavRight, Page } from 'framework7-react';
import React, { useEffect } from 'react';
import StateManager from '../js/StateManager';
import PostCodes from '../views/PostCodes';

const PostCodePage = () => {

    useEffect(() => {
        StateManager.setState('setActivePage', 'postnumre');

    }, []);



    return (
        <Page>
            <Navbar backLink="Back">
                <NavLeft>
                    <Link iconF7="bars" panelOpen className="panel-open"></Link>
                </NavLeft>
                <NavRight >
                    <Link backLink></Link>
                </NavRight>

            </Navbar>
            <Block >
                <BlockTitle>Filter:</BlockTitle>
                <PostCodes show={true} /></Block>


        </Page>
    )
}

export default PostCodePage;