import datetime from 'node-datetime';


export function formatDateLight(date, format = "") {
    var dt = datetime.create(date);
    //    var formattedDate = dt.format('d-m-Y' + (noTime ? '' : ' H:M:S'));
    var formattedDate = dt.format(format);
    return formattedDate;
}

/* Return date for Monday of supplied ISO week number
 * @param {number|string} weekNo - integer from 1 to 53
 * @returns {Date} Monday of chosen week or
 *                 undefined if input is invalid
 */
export function getFirstMondayOfWeek(weekNo) {
    let year = new Date().getFullYear();

    // Test weekNo is an integer in range 1 to 53
    if (Number.isInteger(+weekNo) && weekNo > 0 && weekNo < 54) {

        // Get to Monday of first ISO week of year
        var firstMonday = new Date(year, 0, 4);
        firstMonday.setDate(firstMonday.getDate() + (1 - firstMonday.getDay()));

        // Add required weeks
        firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));

        // Check still in correct year (e.g. weekNo 53 in year of 52 weeks)
        if (firstMonday.getFullYear() <= year) {
            return firstMonday;
        }
    }
    // If not an integer or out of range, return undefined
    return;
}