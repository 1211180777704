import { Block, Navbar, Page } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import InfoHelper from '../components/InfoHelper';
import { areaData } from '../components/rating/RatingData';
import RatingLanes from '../components/rating/RatingLanes';
import RatingMenu from '../components/rating/RatingMenu';
import StateManager from '../js/StateManager';
import { loadRatingData } from '../js/utils/ratingHelper';
import { CenteredPreloader } from '../js/utils/visHelper';

let ratingDataCache = undefined;

export default function (_props) {
    StateManager.setState('setActivePage', 'ulRating');
    const [_activeAreas, setActiveAreas] = useState({ "1": true, "5": true });
    const loadingRef = useRef(false);
    const [ratingData, setRatingData] = useState(undefined);

    useEffect(() => {
        if (!loadingRef.current) {
            loadingRef.current = true;
            fetchData();
        }
        async function fetchData() {
            if (ratingDataCache) {
                setRatingData(ratingDataCache);
                return;
            }

            //let result = await compileRatingData();
            let result = await loadRatingData();
            if (result) {
                ratingDataCache = result;
                setRatingData(result);
                console.log("rating data");
            } else {
                InfoHelper.showError("Ukendt problem... Prøv at genindlæse siden.");
            }
        }
    }, []);


    return (
        <Page>
            <Navbar title={"UL Ratings"} backLink="Back" />
            <Block strong style={{ zIndex: "unset" }}>
                <RatingMenu setActiveAreas={setActiveAreas} menuAreaData={areaData} />
            </Block>
            <Block>
                {ratingData ? <RatingLanes areaData={areaData} ratingData={ratingData} /> : <CenteredPreloader />}

            </Block>
            <Block strong style={{ textAlign: "right" }}> {ratingData ? "Opdateret: " + new Date(ratingData.ratingDate).toLocaleDateString("da-DK") : ""}</Block>
        </Page>
    )

}

//<Block strong className="text-align-center">