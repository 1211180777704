// Import React and ReactDOM
import React from 'react';
import { createRoot } from 'react-dom/client';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-React Plugin
import Framework7React from 'framework7-react';

// Import Framework7 Styles
import 'framework7/css/bundle';

// Import Icons and App Custom Styles
import '../css/app.scss';
import '../css/icons.css';
import '../css/sh-next.scss';

import versionJson from "../../public/version.json";
import InfoHelper from '../components/InfoHelper';
import Config from './Config';
import StateManager from './StateManager';

// Import App Component
import App from '../components/app';
import { feConfig } from '../controllers/FEConfig';

// Check version here
const checkVersion = async () => {

    // delete cache if outdated.
    /*    console.log("sw:", navigator.serviceWorker.controller);
        console.log("caches", caches);
    
        caches.keys().then(cacheNames => {
            console.log("cacheNames", cacheNames);
            cacheNames.map(value => caches.delete(value))
    
        }); */

    let serverVersionResponse = await fetch("/version.json?" + Math.random(), { cache: 'no-cache' });
    let serverVersionJson = serverVersionResponse.ok ? await serverVersionResponse.json() : undefined;
    //let regVersion = localStorage.getItem("NEXT_FE_VERSION");
    let regVersion = versionJson.version;
    let serverVersion = serverVersionJson.version;

    console.log("VERSION CHECK  : stored:", regVersion, "Server:", serverVersion);

    if (serverVersion && regVersion && serverVersion != regVersion) {
        console.log("New version - clearing caches", caches);
        caches.keys().then(cacheNames => {
            cacheNames.map(value => caches.delete(value));
        });
        Config.version = regVersion + "(RELOAD)";

        InfoHelper.showError("En ny version er tilgængelig <BR> Genindlæs venligst siden <BR><BR>");

    }
    if (regVersion) {
        localStorage.setItem("NEXT_FE_VERSION", regVersion);
        Config.version = regVersion;
    }

    StateManager.setState("setAppVer", Config.version);
    //Connector.setAppVer(Config.version);
}

checkVersion();
setInterval(checkVersion, 1000 * 60 * 10);
feConfig.init();

// Init F7 React Plugin
Framework7.use(Framework7React)

// Mount React App
const root = createRoot(document.getElementById('app'));
root.render(React.createElement(App));