import { Block, Button, Col, Link, List, ListInput, Navbar, NavLeft, NavRight, Page, Row } from 'framework7-react';
import React, { useRef, useState } from 'react';
import { beConnectorInstance } from '../../js/BeConnector';
import Toaster from '../../js/utils/Toaster';
import { CenteredPreloader } from '../../js/utils/visHelper';

const idLookup = {
    firstTime: "ekstra rengøring",
    parkering2: "Gratis parkering",
    parkering: "Gratis parkering",
    gulve: "Ubehandlede gulve",
    dage: "Foretrukne dage"
}

let buttonDisabled = true;



export function AddToCRM(props) {
    let offerServerInfo = props.offerServerInfo;
    let addOrModifyCustomer = props.title;
    let offer = props.offer;
    let customerCategory = props.customerCategory;
    let userInitials = props.userInitials;
    let customerToSubmit = useRef({});
    let selectedULSList = useRef({});
    let [refresh, setRefresh] = useState({});
    let selectedULs = props.selectedULs;

    console.log("refresh:", refresh);

    if (selectedULs) {
        selectedULSList.current = selectedULs;
        //        selectedULSList.current[selectedULs] = true;
    }

    buttonDisabled = (addOrModifyCustomer != "Opret kundeemne i CRM")

    let comment = generateComment();

    /* useEffect(() => {
        customerToSubmit.current = {};

    }, []); */


    return (<div>
        {offerServerInfo == "LOADING" && (CenteredPreloader())}
        {
            offerServerInfo && offerServerInfo != "LOADING" && (

                <Page>
                    <Navbar title={addOrModifyCustomer}>

                        <NavLeft></NavLeft>
                        <NavRight>
                            <Link back>Luk ❌</Link>
                        </NavRight>
                    </Navbar>
                    <Block strong>
                        <div><Row>
                            <Col width={100}>
                                <Row style={{ height: "61px" }}>
                                    {getInput("Kunde id", offer.dictionary.telefon?.value || "", "customerId")}
                                    {getInput("Navn", offer.dictionary.navn?.value || "", "name")}
                                </Row>
                                <Row style={{ height: "61px" }}>
                                    {getInput("Gade", offer.dictionary.gade?.value || "", "streetName")}
                                    {getInput("Postnummer", offer.dictionary?.postnummer?.value || "", "postcode")}
                                </Row>
                                <Row style={{ height: "61px" }}>
                                    {getInput("Email", offer.dictionary.email?.value || "", "email")}
                                    {getInput("Telefon", offer.dictionary.telefon?.value || "", "phone")}
                                </Row>
                                {getInput("Kunde kategori", customerCategory, "customerCategory", "selectCategory")}

                                <Row style={{ height: "61px" }}>
                                    {getInput("Potentielle ULer", "", "potentialULs")}
                                    <Col></Col>
                                </Row>
                                {getInput("Bemærkning", comment, "remarks", "textarea")}
                                {getInput("Log Bruger", userInitials, "logUser", "hidden")}
                            </Col>
                        </Row>
                            <Button outline raised onClick={submitData} disabled={buttonDisabled}>{addOrModifyCustomer}</Button>
                        </div>
                    </Block>
                </Page>

            )
        }

    </div>
    )

    async function submitData() {

        if (buttonDisabled) return;

        /***** add potential ULS to remarks */
        let remark = customerToSubmit.current.remarks;
        let uls = Object.entries(selectedULSList.current).filter(
            ([, val]) => { return val; }
        ).map(([key,]) => { return key; });
        uls.length > 0 && (remark = `${uls.length > 1 ? "Potentielle UL'ere:" : "Potentiel UL:"}\n${uls.join("\n")}\n\n${remark}\n`);
        customerToSubmit.current.remarks = remark;
        delete customerToSubmit.current.potentialULs;

        /***** */

        Toaster.showPreloader("Øjeblik");
        let result = await beConnectorInstance.submitNewCustomer(customerToSubmit.current);
        Toaster.closePreloader();
        if (result.status != "success") {
            Toaster.ShowToastCenter("Noget gik galt... Rapporter venligst denne fejl til IT afdelingen.");
            //createCustomerPopup.current.close();

        } else {
            //Toaster.ShowToastCenter("Kunde tilføjet i CRM");
            Toaster.ShowToast("Kunde tilføjet i CRM");

            //createCustomerPopup.current.close();
        }
    }

    function generateComment() {
        //let dic = offer.dictionary;
        let wishes = offer.parsedOffer.parsedFormItems.other;
        let priceCalc = offer.parsedOffer.offeredPrice;
        let comment = ""
        let priceText = "\n";

        if (priceCalc) {
            priceText = "\n - Prisudregning - \n"
            priceCalc.items.forEach(([key, val]) => { priceText += key + " : " + val + "\n" });
            priceText += "" + priceCalc.total[0] + " : " + priceCalc.total[1] + "\n";
            priceText += "" + priceCalc.fradragPris[0] + " : " + priceCalc.fradragPris[1] + "\n";
        }

        wishes.forEach((item) => {
            let desc = idLookup[item.id] || item.id;
            desc = (desc[0]).toLocaleUpperCase() + (desc.substring(1));

            comment += desc + " : " + item.value + "\n";
        })

        //console.log("Generate comment", comment);
        //console.log("Generate priceText", priceText);
        return comment + priceText;
    }

    function getInput(label, value, idv, inputType = "text") {

        function valueUpdated(idv, value) {
            //console.log("valueUpdated", id, value);
            customerToSubmit.current[idv] = value;
            setRefresh({});
        }

        customerToSubmit.current[idv] ?
            value = customerToSubmit.current[idv] :
            customerToSubmit.current[idv] = value;

        if (idv == 'potentialULs') {
            let uls = Object.entries(selectedULSList.current).filter(
                ([, val]) => { return val; }
            ).map(([key,]) => {
                return key;
            });
            value = uls.join(", ");
            customerToSubmit.current[idv] = value;
            //uls.length > 0 && (value = `${uls.length > 1 ? "Potentielle UL'er:" : "Potentiel UL:"}\n${uls.join("\n")}\n\n${value}\n`);
        }


        switch (inputType) {
            case "hidden":
                return (<div></div>)
            case "selectCategory":
                return (
                    <Col><List noHairlinesMd>
                        <ListInput
                            id={idv}
                            key={label + idv}
                            defaultValue={value}
                            outline
                            label={label + ":"}
                            floatingLabel
                            type={"select"}
                            placeholder={label}
                            clearButton={false}
                            onChange={(e) => { valueUpdated(idv, e.currentTarget.value) }}
                        >
                            <option id={idv} value="Omr. 1 Rengøring">Omr. 1 Rengøring</option>
                            <option id={idv} value="Omr. 3 Rengøring">Omr. 3 Rengøring</option>
                            <option id={idv} value="Omr. 4 Rengøring">Omr. 4 Rengøring</option>
                            <option id={idv} value="Omr. 5 Rengøring">Omr. 5 Rengøring</option>
                            <option id={idv} value="Emne">Emne</option>
                            <option id={idv} value="Engangs reng.">Engangs reng.</option>
                            <option id={idv} value="Omr. 1 Ad hoc kunde">Omr. 1 Ad hoc kunde</option>
                            <option id={idv} value="Omr. 3 Ad hoc kunde">Omr. 3 Ad hoc kunde</option>
                            <option id={idv} value="Omr. 4 Ad hoc kunde">Omr. 4 Ad hoc kunde</option>
                            <option id={idv} value="Omr. 5 Ad hoc kunde">Omr. 5 Ad hoc kunde</option>
                            <option id={idv} value="Udenfor vores omr.">Udenfor vores omr.</option>

                        </ListInput></List>
                    </Col>
                )

            default:
                break;
        }

        const extraStyle = (inputType == "textarea") ? { maxHeight: "calc(100vh - (33rem))" } : {};

        return (
            <Col><List noHairlinesMd>
                <ListInput inputStyle={extraStyle}
                    id={idv}
                    key={label + idv}
                    value={value}
                    outline
                    label={label + ":"}
                    floatingLabel
                    type={inputType}
                    resizable
                    placeholder={label}
                    clearButton={false}
                    onChange={(e) => { valueUpdated(idv, e.currentTarget.value) }}
                /></List>
            </Col>
        )
    }
}
