import { f7, Link, Page, Tab, Tabs, Toolbar } from 'framework7-react';
import React from 'react';
import { CenteredPreloader } from '../js/utils/visHelper';
import { AddToCRM } from './newCustomer/AddToCRM';
import { ULSelector } from './newCustomer/ulSelector';
import OfferPage from './offer';


export const OfferTabs = (props) => {

    const [activeTab, setActiveTab] = React.useState("offer");
    const [addToCrmParams, setAddToCrmParams] = React.useState(undefined);
    const [selectedULs, setSelectedULs] = React.useState(undefined);




    const handleTabClick = (tabId) => {
        if (!addToCrmParams) return;
        setActiveTab(tabId);
        f7.tab.show("#" + tabId);

    };

    return (
        <Page name="OfferTabs" pageContent={false} tabs>
            <Toolbar tabbar bottom>
                <Link
                    key="t1"
                    tabLink="#offer"
                    onClick={() => handleTabClick('offer')}
                    tabLinkActive={activeTab === 'offer'}
                >
                    Forespørgsel
                </Link>


                <Link
                    key="t3"
                    /* tabLink="#ulSelector" */
                    onClick={() => handleTabClick('ulSelector')}
                    tabLinkActive={activeTab === 'ulSelector'}
                >
                    Underleverandørvælger
                </Link>
                <Link

                    key="t2"
                    /* tabLink="#addToCRM" */
                    onClick={() => handleTabClick('addToCRM')}
                    tabLinkActive={activeTab === 'addToCRM'}
                >
                    Opret/Opdater i CRM
                </Link>
            </Toolbar>
            <Tabs animated>
                <Tab id="offer" className="page-content" tabActive>
                    <OfferPage {...props} setActiveTab={setActiveTab} setAddToCrmParams={setAddToCrmParams} />;

                </Tab>
                <Tab id="addToCRM" className="page-content" >
                    {addToCrmParams && <AddToCRM {...addToCrmParams} selectedULs={selectedULs} />}
                    {!addToCrmParams && <CenteredPreloader />}

                </Tab>
                <Tab id="ulSelector" className="page-content" >
                    <ULSelector dataParams={addToCrmParams} setSelectedULs={setSelectedULs} currentSelectedULs={selectedULs} />
                </Tab>
            </Tabs>

        </Page>)


}