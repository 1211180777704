import { Card, CardContent, CardFooter, CardHeader, Col, f7, Link, Row } from 'framework7-react';
import React, { useEffect, useRef } from 'react';
import { toLocalFixed } from '../../js/utils/universalUtils';
import { globalRatingStats } from './RatingData';




export default function ULCard({ data }) {
    let globalBehaviour = globalRatingStats.current;
    let globalStars = globalBehaviour.customerScoreData;
    let ulStars = data.customerScoreData;
    let basicStats = data.globalData;
    let behaviourData = data.behaviourData;
    let mainInfo = data.mainInfo;

    let ulId = mainInfo.MEDARBNR;
    let targetEl = 'score-tooltip-' + ulId;

    let pointInfo = formatPointInfo(data.points);


    const scoreToolTip = useRef(null);

    useEffect(() => {
        if (scoreToolTip.current) {
            scoreToolTip.current.destroy();
        }
        scoreToolTip.current = f7.tooltip.create({
            targetEl: "." + targetEl,
            text: pointInfo,
        });


        return () => {
            // if (scoreToolTip.current) {
            //     scoreToolTip.current.destroy();
            // }

        }
    }, [])


    globalBehaviour.customerRating = globalStars.score;
    behaviourData.customerRating = ulStars.score;

    function genULBehaviourData() {
        let list = [];
        list.push(genElement("Stjerner:", bhStat("customerRating", "⭐", 1, false)));
        list.push(genElement("Kundetab:", bhStat("recentQuitRatio")));
        list.push(genElement("Aflysningsprocent:", bhStat("recentCancelRatio")));
        list.push(genElement("Gratis rengøringer:", bhStat("recentFreeCleaningRatio")));
        list.push(genElement("Kroner/tid (Norm):", bhStat("recentTimeMoneyRatioNormalized", "kr/t", 60)));
        behaviourData.recentTimeMoneytimeRatioHigh > 15 ?
            list.push(genElement("Kroner/tid (2P):", bhStat("recentTimeMoneyRatioHigh", "kr/t", 60))) :
            list.push(genElement("Kroner/tid (2P):", [" - ", "#000"]));


        behaviourData.recentTimeMoneytimeRatioLow > 15 ?
            list.push(genElement("Kroner/tid (1P):", bhStat("recentTimeMoneyRatioLow", "kr/t", 60)))
            : list.push(genElement("Kroner/tid (1P):", [" - ", "#000"]));
        //        list.push(genElement("Kr./t ratio (2P):", bhStat("recentTimeMoneytimeRatioHigh")));
        //        list.push(genElement("Kr./t ratio (1P):", bhStat("recentTimeMoneytimeRatioLow")));
        list.push(genElement("App Start fejl:", bhStat("recentAppMistakeRatio")));
        list.push(genElement("App stop fejl:", bhStat("smsFailRatio")));

        //        list.push(genElement("Kroner/tid:", bhStat("recentTimeMoneyRatio", "kr/min")));
        //        list.push(genElement("Kroner/tid (M):", bhStat("recentTimeMoneyRatioMedium", "kr/min")));
        return list;
    }

    function bhStat(name, valueType = "%", multi = 1, lowIsBest = true) {
        let value = (behaviourData[name] * multi || 0);
        let gvalue = (globalBehaviour[name] * multi || "?");
        let color = "#333333";

        if (typeof value === "number" && typeof gvalue === "number") {
            if (lowIsBest)
                color = (value < gvalue) ? "#335533" : "#553333";
            else
                color = (value > gvalue) ? "#335533" : "#553333";
        }

        //behaviourData.recentQuitRatio + "% (44%)

        return [`${toLocalFixed(value) + valueType} (${toLocalFixed(gvalue) + valueType})`, color];

    }


    function genElement(key, [val, color]) {
        return (<Row style={{ width: "100%", background: color }} key={"key-" + key}>
            <Col>{key}</Col>
            <Col style={{ textAlign: "right" }}>{val}</Col>
        </Row>
        )


    }

    function formatPointInfo(pointData) {

        return Object.entries(pointData).map(([key, val]) => {
            return `${key}: ${val} <br>`;
        }).join("\n");

    }

    let ptotal = toLocalFixed(data.pointTotal);

    switch (true) {
        case data.pointTotal == -1000:
            ptotal = "⌛";
            break;
        case data.pointTotal == -2000:
            ptotal = "UL DATA FEJL";
            break;
        case data.pointTotal > 11:
            ptotal += " 🥰";
            break;
        case data.pointTotal > 10:
            ptotal += " 😃";
            break;
        case data.pointTotal > 9:
            ptotal += " 😉";
            break;
        case data.pointTotal > 8:
            ptotal += " 🙂";
            break;
        case data.pointTotal < 0:
            ptotal += " 🤬";
            break;
        case data.pointTotal < 1:
            ptotal += " 😬";
            break;
        case data.pointTotal < 2:
            ptotal += " 😒";
            break;

        default:
            break;
    }


    let nextRating = ptotal;

    return (

        <Card outline className='ul-rating-card'>
            <CardHeader>
                <Row style={{ width: "100%" }}>
                    <Col>
                        {mainInfo.NAVN}
                    </Col>
                    <Col>
                        <div style={{ textAlign: "right" }}>{mainInfo.MEDARBNR}<br />
                            {mainInfo.ANS_KAT}
                        </div>
                    </Col>
                </Row>

            </CardHeader>
            <CardContent>
                <Row style={{ width: "100%" }}>
                    <Col className='ul-rating-card-address' width={"66"}>
                        Next Rating: <b className={'next-rating-value ' + targetEl}>{nextRating}</b><br />
                        Startdato: <b> {new Date(mainInfo.taskStartDate || mainInfo.ANS_DATO).toLocaleDateString("da-DK")}</b><br />
                        Antal opgaver: <b> {"" + basicStats.cleaningsCount}</b><br />
                        LTV: <b>{"" + (basicStats.totalEarnings.toLocaleString('da-DK') + " kr.")}</b><br />



                        {/* {mainInfo.ADR1} {mainInfo.ADR1 && <br />}
                        {mainInfo.ADR2} {mainInfo.ADR2 && <br />}
                        {mainInfo.ADR3} {mainInfo.ADR3 && <br />}
                        {mainInfo.ADR4} {mainInfo.ADR4 && <br />} */}
                    </Col>
                    <Col width={"33"} >
                        {/* <div className="next-rating-box">18,22</div> */}
                        <img src="img/profiles/placeholder_profile_picture.jpg" width={70} style={{ display: "block", marginLeft: "auto" }} />
                    </Col>
                </Row>
                <hr />
                {genULBehaviourData()}

            </CardContent>
            <CardFooter>
                <Link>{mainInfo.EMAIL}  </Link>
            </CardFooter>

        </Card>
    )
    /* <Card key={data.id} >
        <CardContent padding={false}>
            <CardHeader textColor="white" className="display-block">
                Navn: {data.name}

                <small style={{ opacity: 0.7 }}>ID: {data.id}</small>
            </CardHeader>


            <Link
                cardClose
                color="white"
                className="card-opened-fade-in"
                style={{ position: 'absolute', right: '15px', top: '15px' }}
                iconF7="xmark_circle_fill"
            />

            <div className="card-content-padding"><p>
                THIS IS THE BOMB
            </p></div>
        </CardContent>


    </Card>
*/





    //return (<div> --- THIS IS A CARD --- {data.name} </div>)
}
