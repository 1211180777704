import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionToggle,
    List,
    ListInput,
    ListItem,
    Toggle
} from 'framework7-react';
import React, { useState } from 'react';

const MySettings = ({ settings, onUpdate, translation }) => {
    const [state, setState] = useState(settings);
    const transLabel = translation.label;
    const transDescription = translation.description;



    const handleChange = (path, value) => {
        const newState = { ...state };
        let obj = newState;
        path.forEach((key, index) => {
            if (index === path.length - 1) {
                obj[key] = value;
            } else {
                obj = obj[key];
            }
        });
        setState(newState);
        onUpdate(newState);
    };

    const renderGroup = (group, path) => {
        return Object.entries(group).map(([key, value]) => {
            const subpath = [...path, key];
            const indentation = subpath.length * 0.4;
            const background = '#00000011';

            if (typeof value === 'boolean') {
                const label = transLabel[key] || key;
                return (
                    <ListItem key={key} style={{ maxWidth: "22rem" }} className='new-style-setting-container'>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ paddingRight: "0.6rem" }}>{label}</div>
                            {value ?
                                (<div style={{ color: "#4CAF50", fontSize: "0.8rem" }}>(AKTIVERET)</div>) :
                                (<div style={{ color: "#F44336", fontSize: "0.8rem" }}>(DEAKTIVERET)</div>)}
                            :
                        </span>
                        <div className='new-style-settings-value-description'>{transDescription[key] || ""}</div>
                        <Toggle
                            color='green'
                            defaultChecked={value}
                            onChange={(e) => handleChange(subpath, e.target.checked)}
                        />

                    </ListItem>


                );
            } else if (typeof value === 'number') {
                const label = transLabel[key] || key;
                return (
                    <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '22rem', paddingLeft: "16px" }}>
                        <span style={{ marginRight: '1rem', textAlign: 'left' }}>{label}:
                            <div className='new-style-settings-value-description'>{transDescription[key] || ""}</div>
                        </span>
                        <ListInput
                            inputId="setting-number-input"
                            style={{ maxWidth: '10rem' }}
                            key={key}
                            type='number'
                            value={value}
                            inputStyle={{ textAlign: 'right', maxWidth: '5rem', background: "#111122", paddingLeft: "0.5rem" }}
                            onInput={(e) => handleChange(subpath, parseInt(e.target.value))}
                        />

                    </div>
                );
            } else if (typeof value === 'object') {
                const label = transLabel[key] || key;
                return (
                    <Accordion key={key} style={{ marginLeft: indentation + "rem", border: "solid 1px #333355", background: background }}>
                        <AccordionItem opened >
                            <AccordionToggle className='new-style-accordion-toggle-label' style={{ background: "#333344" }}>
                                <span>{label} </span>

                            </AccordionToggle>
                            <div className='new-style-settings-description'>{transDescription[key] || ""}</div>
                            <AccordionContent className='sh-ac-content'>
                                {renderGroup(value, subpath)}
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                );
            }
            return null;
        });
    };

    return <List inlineLabels style={{ listStyle: "none" }}>{renderGroup(state, [])}</List>;
};

export default MySettings;