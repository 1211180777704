import { DateTime } from "luxon";
import { sharedData } from "../../data/SharedData";
import { beConnectorInstance } from "../../js/BeConnector";
import { customerHandler } from "../../js/CustomerHandler";

export const teamsTESTData = [
    { name: "Dingo", ulid: "123", area: 1, customerCount: 10, rating: 4.5 },
    { name: "Bob", ulid: "234", area: 3, customerCount: 20, rating: 3.2 },
    { name: "Charlie", ulid: "345", area: 4, customerCount: 15, rating: 4.8 },
    { name: "David", ulid: "456", area: 5, customerCount: 5, rating: 2.7 },
    { name: "Eve", ulid: "567", area: 1, customerCount: 12, rating: 4.1 },
    { name: "Frank", ulid: "678", area: 3, customerCount: 8, rating: 3.9 },
    { name: "Grace", ulid: "789", area: 5, customerCount: 18, rating: 4.6 }
];

const DEFAULT_FIELDS = ["name", "ulid", "area", "postcode", "email", "mainPhone"];
const TEAMS_LIST_TRANSLATIONS = { name: "Navn", customerCount: "Antal kunder", area: "Område", ulid: "ULID", postcode: "Postnummer", email: "Email", mainPhone: "Telefon" };

export class NTData {
    calendarData = undefined;
    calendarDataLookup = {};
    rawData = undefined;
    rawList = undefined;
    filterText = "";
    filterAreas = undefined
    activeID = undefined;


    distanceData = undefined;
    ulSchedule = undefined;

    constructor() {
        console.log("TeamsData constructor");
        //this.rawList = teamsTESTData;
    }

    async saveTeamData(list) {
        //console.log("TeamsData saveTeamData", list);

        list = list.map((item) => {
            return { ...item, issues: undefined };

        });

        let result = await beConnectorInstance.saveTeamsCalendarData(list, sharedData.getCurrentUserName());
        return result;
    }

    async getCustomerIssues() {
        const result = await beConnectorInstance.getCustomerIssues();
        return result;
    }

    async getDistanceData(id) {

        !customerHandler.freshResults && await customerHandler.loadCustomerdata(id);
        const currentUserData = customerHandler.freshResults[id];
        const userData = currentUserData?.userData;

        let newInfo = {
            customerId: userData.KUNDEID,
            name: userData.ST_FIRMA || userData.FIRMA,
            streetName: userData.ST_ADDR1 || userData.ADDR1,
            postcode: userData.ST_POSTNR || userData.POSTNR || "0000",
            email: userData.ST_EMAIL || userData.EMAIL,
            phone: userData.ST_TLF || userData.TLF,
            customerCategory: userData.ST_KUNDEKAT || userData.KUNDEKAT,
            logUser: userData.LOGUSER,
        }

        const result = await beConnectorInstance.getOfferServerInfo(newInfo);
        return result;
    }

    processDistanceData(data) {
        console.log("processDistanceData", data);
        Object.values(data).forEach((ulData) => {
            const dateLookup = {};
            ulData.weeks.forEach((week) => {
                //console.log("week", week);
                let dayStart = new Date(week.weekStart);
                let dateStr = DateTime.fromJSDate(dayStart).toFormat("dd-MM-yyyy");

                week.jobsPerDay.forEach((val, index) => {
                    dateLookup[dateStr] = { jobsPerDay: val, minDist: week.minDist[index], dayIndex: index };
                    dayStart.setDate(dayStart.getDate() + 1);
                    dateStr = DateTime.fromJSDate(dayStart).toFormat("dd-MM-yyyy");
                });
            });
            ulData.dateLookup = dateLookup;

        });



    }


    async loadFromServer(id) {
        console.log("TeamsData loadFromServer");
        let [rawData, distanceData] = await Promise.all([
            beConnectorInstance.getTeamsCalendarData(),
            this.getDistanceData(id)
        ]);

        this.rawData = rawData;
        this.distanceData = distanceData;
        this.ulSchedule = distanceData.ulData.allSchedules;

        this.processDistanceData(this.ulSchedule);

        this.rawList = this.rawData.baseData;
        this.calendarData = []
        for (const item of this.rawList) {
            //console.log("item", item);
            let cleanedName = removeNumbers(item.name);
            //console.log("cleanedName", cleanedName, "-", item.name);
            item.name = cleanedName;
            item.mainPhone = item.phone1 || item.phone2;
            let calDataEntry = {
                name: item.name,
                area: item.area,
                ulid: item.ulid,
                start: item.startDate,
                end: item.endDate,
                availableEvenWeeks: item.availableEvenWeeks,
                availableOddWeeks: item.availableOddWeeks,
                previousTimestamp: item?.calendarTimestamp || "NOT_SET",
                intervals: item?.intervals || [],
                calendarLastEditBy: item?.calendarLastEditBy || "",
                calendarTimestamp: item?.calendarTimestamp,

            };

            /*            for (const obj of calDataEntry.intervals) {
                            let interval = obj.interval;
                            obj.interval = [DateTime.fromISO(interval[0], { zone: 'Europe/Copenhagen' }), DateTime.fromISO(interval[1], { zone: 'Europe/Copenhagen' })];
                        } */


            this.calendarDataLookup[item.ulid] = calDataEntry;
            item.calDataEntry = calDataEntry;

            this.calendarData.push(calDataEntry);

        }



    }


    getIsReady() {
        if (this.rawList) return true;
        return false;
    }


    setFilter(text, areas) {
        this.filterText = text;
        this.filterAreas = areas;
    }

    filterByText(data) {
        return data.filter(team =>


            team.name.toLowerCase().includes(this.filterText.toLowerCase()) ||
            ("" + team.ulid).toLowerCase().includes(this.filterText.toLowerCase())
        );
    }

    filterByAreas(data) {
        if (!this.filterAreas) return data;
        return data.filter(team => this.filterAreas[team.area]);
    }

    getCurrentList() {
        if (!this.rawList) return [];
        let out = this.filterByAreas(this.rawList);
        out = this.filterByText(out);
        return out;
    }

    getTeamsCalendarList() {
        let list = this.getCurrentList();
        return list.map(team => team.calDataEntry);
    }

    getActiveListFields() {
        return DEFAULT_FIELDS;
    }

    getListTranslations() {
        return TEAMS_LIST_TRANSLATIONS;
    }


}


function removeNumbers(str) {
    const regex = /(?:tel|tlf|tele)?[\s.\-:]*(\d{2,})/gi;
    const matches = str.match(regex);
    if (matches) {
        matches.forEach(match => {
            str = str.replace(match, '');
        });
    }
    return str.trim();
}


export const ntData = new NTData();