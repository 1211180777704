export default class Connector {
    static instance;

    static ready = false;

    static currentArea = 1;
    static username = "";
    static password = "";
    static userArea;
    static loginName = "";
    static setUsernameCall;
    static stateCallbacks = new Object();

    static initialBaseUrl;

    //static setAppVer;

    area = 1;

    static setUsername = (username) => {
        Connector.username = username;
        if (Connector.setUsernameCall) {
            setTimeout(() => { Connector.setUsernameCall(username) });
        }
        Connector.loginName = username;
        //Connector.setUsernameCall ? Connector.setUsernameCall(username) : Connector.username = username;

    };

    static getInstance() {
        if (!Connector.instance) {
            Connector.instance = new Connector();
        }
        return Connector.instance;
    }

    static setUser(username, password) {
        Connector.username = username;
        Connector.password = password;
        console.log("Username", username);
        setTimeout(this.setUsername, 0, username);
    }

    static getUserName() {
        return Connector.username;
    }

    static updateUsernameCall(setUsernameCall) {
        if (Connector.username && !Connector.setUsernameCall) {
            setUsernameCall(Connector.usernames);
        }
        Connector.setUsernameCall = setUsernameCall;
    }

    static registerStateDispatch(name, callback) {
        Connector.stateCallbacks[name] = callback;
    }

    static dispatchState(name, value) {
        if (Connector.stateCallbacks[name]) {
            setTimeout(Connector.stateCallbacks[name], 0, value);

        }
    }

}