export class StorageMaster {

    storeKey;
    data = {};

    constructor() {
        this.setStoreKey("storage_master_default");
    }

    delete(name) {
        delete this.data[name];
        this.saveToLocal(this.storeKey, this.data);
    }

    setStoreKey(key) {
        this.storeKey = key;
        this.data = this.retrievFromLocal(this.storeKey) || {};
    }

    save(name, data) {
        this.data[name] = data;
        this.saveToLocal(this.storeKey, this.data);
    }

    retrieve(name) {
        this.data = this.retrievFromLocal(this.storeKey) || {};
        return this.data[name];
    }


    saveToLocal(name, data) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    retrievFromLocal(name) {
        const data = localStorage.getItem(name);
        return data ? JSON.parse(data) : null;
    }
}

export const storageMaster = new StorageMaster();