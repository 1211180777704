import { useEffect, useState } from "react";

export function toLocalFixed(input, maxDecimalPlaces = 2, minDecimalPlaces = undefined) {
    minDecimalPlaces ||= maxDecimalPlaces;

    return input.toLocaleString("da-DK", {
        minimumFractionDigits: minDecimalPlaces,
        maximumFractionDigits: maxDecimalPlaces
    });
}

// function that takes a callback and a value and a timeout. the function can be called multiple times but the callback is only called once after the timeout has passed with the last value.
/* export function debounce(callback, value, timeout) {
   
   
   
     let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            callback(value);
        }, timeout);
    };
} */

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])

    return debouncedValue
}
