export const globalRatingStats = {
    version: 1,
};
export const ratingSortingOptions = {
    ALPHA: "Alfabetisk",
    TOTAL_RATING: "Bedømmelse",
    STARS: "Stjerner",
    QUITRATE: "Kundetab",
    NUM_TASKS: "Opgaver",
    LTV: "Life Time Value",
    SMS: "SMS Fejl",
    CANCEL: "Aflysningsprocent",

}

export const areaData =
{
    sortingOrder: "ALPHA",
    areas:
    {
        "0": {
            id: "0",
            contact: "rune@servicehuset.dk",
            friendlyName: "Alle",
            status: "active",
            show: false
        },
        "1": {
            id: "1",
            contact: "alice@servicehuset.dk",
            friendlyName: "Område 1",
            status: "active",
            show: true
        },
        "2": {
            id: "2",
            contact: "it@servicehuset.dk",
            friendlyName: "Område 2",
            status: "inactive",

        },
        "3": {
            id: "3",
            contact: "yvonne@servicehuset.dk",
            friendlyName: "Område 3",
            status: "active",
            show: true
        },
        "4": {
            id: "4",
            contact: "camilla@servicehuset.dk",
            friendlyName: "Område 4",
            status: "active",
            show: true
        },
        "5": {
            id: "5",
            contact: "niklas@servicehuset.dk",
            friendlyName: "Område 5",
            status: "active",
            show: true
        }

    }
}