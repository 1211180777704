export class SharedData {
    currentUserBaseInfo = {};
    currentUserName = "";
    setCurrentUserBaseInfo = (newInfo) => {
        this.currentUserBaseInfo = newInfo;
    }
    getCurrentUserBaseInfo = () => {
        return this.currentUserBaseInfo;
    }
    setCurrentUserName = (newName) => {
        this.currentUserName = newName;
    }
    getCurrentUserName = () => {
        return this.currentUserName;
    }

    getArea = () => {
        return this.currentUserBaseInfo?.area?.[0] || '1';
    }

}

export const sharedData = new SharedData();
