import React, { useState } from 'react';
import './skins/chrome-dark.css';

export function TinyContextMenu({ controller }) {
    const [inner, setInner] = useState();
    controller.registerSetInner(setInner);
    return (<div id={controller.getID()}>
        {inner}
    </div>)
}

export class ContextMenuController {
    isShowing = false;
    contextMenuID = "default-context-menu";
    listenerElement;
    items;
    itemsRendered = [];
    actionHandlers = {};

    regActionHandlers(actionHandlers) {
        actionHandlers.forEach((handlerData) => {
            this.actionHandlers[handlerData[0]] = handlerData[1];
        });

    }

    registerSetInner(setInner) {
        this.setInner = setInner;
    }

    getID() {
        return this.contextMenuID;
    }

    show(x, y) {
        this.activateMenu(x, y);
        this.isShowing = true;
    }

    onContextMenu = (e) => {
        console.log('context menu');
        e.preventDefault();
        this.show(e.clientX, e.clientY);
    }

    addListener(elementID) {
        this.listenerElement = document.getElementById(elementID);

        this.listenerElement.addEventListener('contextmenu', this.onContextMenu);
        document.addEventListener('mouseup', this.closeMenu);
        window.addEventListener('blur', this.closeMenu);
    }

    closeMenu = (event) => {
        console.log('close menu', this.isShowing);
        if (!this.isShowing) return;
        this.isShowing = false;
        setTimeout(() => {
            console.log('close menu');
            !this.isShowing && this.setInner(null);

        }, 100);
        //this.removeListener()
    }

    removeListener() {
        this.listenerElement.removeEventListener('contextmenu', this.onContextMenu);
        document.removeEventListener('mouseup', this.closeMenu);
        window.removeEventListener('blur', this.closeMenu);
    }

    setMenuItems(items) {
        this.items = items;

        this.itemsRendered = items.map((item, index) => {
            if (item === null) {
                return (<div key={index} className='separator'></div>)
            }

            const extraClass = item.disabled ? ' disabled' : '';

            return (<div key={index} className={'item' + extraClass} onClick={() => {
                this.itemClicked(item.id)
            }}>
                <span className='label'>{item.text}</span>
                <span className='hotkey'>{item.hotkey}</span>
            </div>
            )
        });
    }

    itemClicked(id) {
        this.actionHandlers[id](id);
    }

    activateMenu(clientX, clientY) {
        this.setInner(
            <div className='context' style={{ left: clientX, top: clientY }}>
                {this.itemsRendered}
            </div>

        );
    }



}

export const contextMenuController = new ContextMenuController();