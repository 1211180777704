//import { DateTime } from 'luxon';
import { Preloader } from 'framework7-react';
import { max, min } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef } from 'react';
import { contextMenuController } from '../../utils/ContextMenu/TinyContextMenu';
import './NTCalendar.css';
import { ntData } from './NTData';

export const INTERVAL_STATES = {
    EMPTY: "EMPTY",
    VACATION: "VACATION",
    VACATION_SAVED: "VACATION_SAVED",
    SICKNESS: "SICKNESS",
    SICKNESS_SAVED: "SICKNESS_SAVED",
    UNAUTHORIZED: "UNAUTHORIZED",
    UNAUTHORIZED_SAVED: "UNAUTHORIZED_SAVED",
    RECURRING_ABSENCE: "RECURRING_ABSENCE",
};

export const INTERVAL_STATE_COLORS = {
    [INTERVAL_STATES.EMPTY]: "#000000",
    [INTERVAL_STATES.SICKNESS]: "#555533",
    [INTERVAL_STATES.SICKNESS_SAVED]: "#998800",
    [INTERVAL_STATES.VACATION]: "#333933",
    [INTERVAL_STATES.VACATION_SAVED]: "#336633",
    [INTERVAL_STATES.UNAUTHORIZED]: "#887777",
    [INTERVAL_STATES.UNAUTHORIZED_SAVED]: "#884444",
    [INTERVAL_STATES.RECURRING_ABSENCE]: "#556666",
};

export const INTERVAL_STATE_LABELS = {
    [INTERVAL_STATES.EMPTY]: ["0", "Tom"],
    [INTERVAL_STATES.SICKNESS]: ["S", "Sygdom"],
    [INTERVAL_STATES.SICKNESS_SAVED]: ["S", "Sygdom (Gemt)"],
    [INTERVAL_STATES.VACATION]: ["F", "Ferie"],
    [INTERVAL_STATES.VACATION_SAVED]: ["F", "Ferie (Gemt)"],
    [INTERVAL_STATES.UNAUTHORIZED]: ["U", "Uautoriseret fravær"],
    [INTERVAL_STATES.UNAUTHORIZED_SAVED]: ["U", "Uautoriseret fravær (Gemt)"],
    [INTERVAL_STATES.RECURRING_ABSENCE]: ["FF", "Fast fravær"],
};

export const MENU_IDS = {
    SAVE: "SAVE",
    SAVE_ALL: "SAVE_ALL",
    DELETE: "DELETE"
};


const CALENDAR_MENU = [{ id: MENU_IDS.DELETE, text: 'Slet', hotkey: '', disabled: false },
{ id: MENU_IDS.SAVE, text: 'Godkend/Gem', hotkey: '', disabled: false },
    null,
{ id: MENU_IDS.SAVE_ALL, text: 'Godkend/Gem alt', hotkey: '' }];

export function NTCalendar({ numDays, startDate, list, intervalType, onListUpdated }) {

    const [hoverIndex, setHoverIndex] = React.useState(null);
    const [saveCount, setSaveCount] = React.useState(0);
    const contextMenuRef = useRef();
    const listRef = useRef();
    const days = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'];
    const weeks = [];
    const months = [];
    const allDates = [];
    const start = new Date(startDate);
    const end = new Date(start.getTime() + numDays * 24 * 60 * 60 * 1000);
    start.setHours(0, 0, 0, 0);
    const startDateTime = DateTime.fromJSDate(start, { zone: 'Europe/Copenhagen' });
    listRef.current = list;

    useEffect(() => {
        if (!contextMenuRef.current) {
            contextMenuRef.current = contextMenuController;
            contextMenuController.addListener("calendar-base");
            contextMenuController.setMenuItems(CALENDAR_MENU);
            contextMenuController.regActionHandlers([[MENU_IDS.SAVE_ALL, (_menuId) => {
                //console.log("SAVE_ALL", listRef.current);

                listRef.current.forEach((item) => {
                    item.intervals.forEach((interval) => {
                        if (interval.type === INTERVAL_STATES.SICKNESS) {
                            interval.type = INTERVAL_STATES.SICKNESS_SAVED;
                            interval.approved = true;
                        }
                        if (interval.type === INTERVAL_STATES.VACATION) {
                            interval.type = INTERVAL_STATES.VACATION_SAVED;
                            interval.approved = true;
                        }
                        if (interval.type === INTERVAL_STATES.UNAUTHORIZED) {
                            interval.type = INTERVAL_STATES.UNAUTHORIZED_SAVED;
                            interval.approved = true;
                        }
                    });
                })
                onSave();
                setSaveCount(Math.random());
                //listRef.current = [...listRef.current];
            }]]);

            return () => {
                contextMenuController.removeListener("calendar-base");
                contextMenuRef.current = undefined;
            };
        }
    }, []);

    // Generate weeks and months
    let week = [];
    let month = [];
    let curr = new Date(start);
    let prev = new Date(start);
    while (curr < end) {
        allDates.push(curr);
        week.push(curr);
        if (curr.getDay() === 0) {
            weeks.push(week);
            week = [];
        }
        if (curr.getMonth() !== prev.getMonth()) {
            prev = curr;
            months.push(month);
            month = [];
        }
        month.push(curr);
        curr = new Date(curr.getTime() + 24 * 60 * 60 * 1000);
    }
    if (month.length > 0) {
        months.push(month);
    }
    if (week.length > 0) {
        weeks.push(week);
    }

    // Generate header rows
    const weekNums = weeks.map((week) => {
        const firstDay = week[0];
        const lastDay = week[week.length - 1];
        /*  const weekNum = Math.ceil(
             ((firstDay - new Date(firstDay.getFullYear(), 0, 1)) / 86400000 +
                 new Date(firstDay.getFullYear(), 0, 1).getDay() +
                 1) /
             7
         ); */
        const dt = DateTime.fromJSDate(firstDay, { zone: 'Europe/Copenhagen' });
        const weekNum = dt.weekNumber;


        return (
            <th key={firstDay} colSpan={week.length} style={{ whiteSpace: 'nowrap' }}>
                {(week.length > 1 ? "Uge " : "") + weekNum}
            </th>
        );
    });

    var dateIndex = 0;
    const dayNames = [];
    const dates = weeks.map((week) => {
        const style = {};

        return (week.map((day) => {
            const style = {};
            if (hoverIndex !== null && hoverIndex == dateIndex) {
                style.background = '#556655';
            }
            dateIndex++;
            let dt = DateTime.fromJSDate(day, { zone: 'Europe/Copenhagen' });
            dayNames.push(<th key={"dayname" + day} style={style}>{days[dt.weekday - 1]}</th>);

            return (<th key={day} style={style}>{day.getDate()}</th>)
        }))
    }
    );



    const HeaderMonths = months.map((month) => {
        const firstDay = month[0];
        const lastDay = month[month.length - 1];
        return (
            <th key={firstDay} colSpan={month.length}>
                {firstDay.toLocaleString('da-DK', { month: 'long' })}
            </th>
        );
    });

    function columnChangeHandler({ selected, hover }) {
        //console.log("selected, hover", selected, hover);
        setHoverIndex(hover);
    }

    async function onSave(list = undefined) {
        list ||= listRef.current;
        /*  if (list) {
             for (const ulData of list) {
                 ulData.isLoading = true;
                 //let inList = list.find((item) => item.ulid === ulData.ulid);
             }
             setSaveCount(Math.random());
         } */
        const issues = await onListUpdated(list);
        await updateIssues(issues);



    }

    async function updateIssues(issues) {
        //console.log("updateIssues", issues);
        if (!issues) return;
        for (const ulData of listRef.current) {
            ulData.issues = issues.affectedCustomers.affected[ulData.ulid];
            ulData.isLoading = false;
        }
        setSaveCount(Math.random());
    }


    const rows = useMemo(() => {

        return list.map((team) => {
            return (
                <TeamRow
                    key={team.ulid}
                    team={team}
                    dates={allDates}
                    onColumnChange={columnChangeHandler}
                    startDateTime={startDateTime}
                    intervalType={intervalType}
                    onSave={onSave}
                />
            );
        });
    }, [listRef.current, start.getTime(), intervalType, saveCount]);


    weekNums.unshift(<th key='empty'></th>);
    HeaderMonths.unshift(<th key='empty'></th>);
    dates.unshift(<th key='empty'></th>);
    dayNames.unshift(<th key='empty'></th>);



    return (
        <div style={{ overflowX: 'auto' }} >
            <table className='nt-calendar-table' id="calendar-base">
                <thead>
                    <tr className='nt-calendar-table-week'>{weekNums}</tr>
                    <tr className='nt-calendar-table-month'>{HeaderMonths}</tr>
                    <tr className='nt-calendar-table-days'>{dayNames}</tr>
                    <tr className='nt-calendar-table-dates'>{dates}</tr>
                </thead>
                <tbody className='nt-calendar-body'>{rows}</tbody>
            </table>
            <br />
        </div>
    );
}




function TeamRow({ team, dates, onColumnChange, startDateTime, intervalType, onSave }) {
    const selectedRef = useRef(null);
    const hover = useRef(null);
    const [selected, setSelected] = React.useState();
    const [intervals, setIntervals] = React.useState([]);

    function updateIntervals(intervals, doSave = true) {
        //console.log("updateIntervals", team.name, doSave);
        setIntervals(intervals);
        team.intervals = indexToDateTime(intervals);
        if (doSave) {
            team.hasChanged = true;
            onSave([team]);
        }
    }

    useEffect(() => {
        updateIntervals(dateTimeToIndex(), false);
    }, [startDateTime]);

    const globalMouseUp = useRef(() => {
        console.log("globalMouseUP", team.name);
        selectedRef.current = null;
        setSelected(null);
    });

    function indexToDateTime(intervals) {
        return intervals.map((entry) => {
            const start = startDateTime.plus({ days: entry.interval[0] });
            const end = startDateTime.plus({ days: entry.interval[1] });

            return { ...entry, interval: [start.toISODate({ zone: 'Europe/Copenhagen' }), end.toISODate({ zone: 'Europe/Copenhagen' })] };
        });
    }

    function dateTimeToIndex() {
        return team.intervals.map((entry) => {
            const start = DateTime.fromISO(entry.interval[0], { zone: 'Europe/Copenhagen' });
            const end = DateTime.fromISO(entry.interval[1], { zone: 'Europe/Copenhagen' });
            const startIndex = Math.floor((start - startDateTime) / (1000 * 60 * 60 * 24));
            const endIndex = Math.floor((end - startDateTime) / (1000 * 60 * 60 * 24));

            return { ...entry, interval: [startIndex, endIndex] };
        });
    }

    function handleMouseDown(event) {

        if (event.button !== 0) return;


        const value = getCellValue(event);
        if (value == undefined) return;
        console.log(" value", value);

        if (event.shiftKey && selectedRef.current) { // TODO: fix this
            selectedRef.current[1] = value;
        }
        else {
            selectedRef.current = [value, value];
        }
        console.log("selected value", selectedRef.current);
        setSelected([...selectedRef.current]);
        //report = true;

        document.addEventListener('mouseup', globalMouseUp.current, { once: true });

    }

    function handleMouseUp(event) {
        if (event.button === 2) {
            const value = getCellValue(event);
            regContextAction(value);
        }

        if (event.button !== 0) return;
        const value = getCellValue(event);
        if (value == undefined) return;
        document.removeEventListener('mouseup', globalMouseUp.current, { once: true });
        if (selectedRef.current) {
            selectedRef.current[1] = value;
            console.log("selected value", selectedRef.current);
            insertIntoIntervals(selectedRef.current);

            selectedRef.current = null;
            setSelected(null);
        }
    }

    function insertIntoIntervals(selected) {
        const [start, end] = [min(selected), max(selected)];
        const newInterval = { type: intervalType, interval: [start, end], approved: false };
        const overlappingIndex = intervals.findIndex(item => { // TODO: does not handle overlapping intervals if multiple intervals are inside new interval
            return item.interval[0] <= end && item.interval[1] >= start;
        });

        if (overlappingIndex >= 0) {
            const overlapping = intervals[overlappingIndex];
            console.log("overlapping", overlapping);
            if (overlapping.type === newInterval.type) {
                overlapping.interval[0] = Math.min(start, overlapping.interval[0]);
                overlapping.interval[1] = Math.max(end, overlapping.interval[1]);

                //if overlapping is inside new interval - delete overlapping
                /* if (overlapping.interval[0] >= start && overlapping.interval[1] <= end) {
                    intervals.splice(overlappingIndex, 1);
                } */

                updateIntervals([...intervals]);
                return;
            }
            //if new interval is inside overlapping
            if (overlapping.interval[0] < start && overlapping.interval[1] > end) {
                const newInterval2 = { ...overlapping, interval: [...overlapping.interval] };
                overlapping.interval[1] = start - 1;
                newInterval2.interval[0] = end + 1;
                intervals.splice(overlappingIndex, 0, newInterval2);

            } else if (overlapping.interval[0] < end) {
                overlapping.interval[1] = end - 1;
            } else if (overlapping.interval[1] > start) {
                overlapping.interval[0] = start + 1;
            }
            if (overlapping.interval[0] > overlapping.interval[1]) {
                overlapping.interval = null;
                intervals.splice(overlappingIndex, 1);
            }
        }
        intervals.push(newInterval);

        updateIntervals([...intervals]);
    }

    function handleMouseMove(event) {
        const value = getCellValue(event);
        if (value === undefined) return;
        if (value !== hover.current) {
            hover.current = value;

            if (selectedRef.current && event.buttons === 1) {
                selectedRef.current[1] = value;

                setSelected([...selectedRef.current]);
                console.log("selected value", selectedRef.current);
            }
            onColumnChange && onColumnChange({ selected: selectedRef.current, hover: hover.current, team: team });

            regContextAction(value);
        }

    }

    function regContextAction(value) {
        if (value === undefined) return;
        contextMenuController.regActionHandlers(
            [[MENU_IDS.DELETE, () => { deleteInterval(value) }],
            [MENU_IDS.SAVE, () => { saveInterval(value) }]])
    }

    function saveInterval(index) {
        console.log("deleteIntervalAtIndex", index);
        saveIntervalAtIndex(intervals, index);
        updateIntervals([...intervals]);
        //        intervals.splice(index, 1);
        //      setIntervals([...intervals]);
    }

    function deleteInterval(index) {
        console.log("deleteIntervalAtIndex", index);
        deleteIntervalAtIndex(intervals, index);
        updateIntervals([...intervals]);
        //        intervals.splice(index, 1);
        //      setIntervals([...intervals]);
    }
    // style={{ height: "10px", width: "10px" }} 

    //console.table(intervals);
    //console.log("team", team.name, "interval", intervals);
    const teamNameClass = team.issues ? "nt-calendar-team-name-issue" : "";

    const tooltip = team.issues ? "Berørte kunder:\n\n" + team.issues.map(issue => issue.KUNDEID + " " + issue.FIRMA).join("\n") : "";


    let rowCount = 0;
    return (
        <tr key={team.ulid} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} >
            <td title={tooltip} style={{ whiteSpace: 'nowrap', padding: "0 0.5rem" }} className={teamNameClass}>{`${team.name} (${!team.isLoading ? team.ulid : ""})`} {team.isLoading && <Preloader size={18} color='green' rippleColor='blue' />}</td>
            {dates.map((date) => {

                let dt = DateTime.fromJSDate(date);
                let dayIndex = dt.weekday - 1;
                let isEven = dt.weekNumber % 2 === 0;
                let repEntry = isEven ? team.availableEvenWeeks[dayIndex] : team.availableOddWeeks[dayIndex];
                let text = ""
                let title = "";
                let style = {};
                const interval = intervals.find(item => isInInterval(rowCount, item.interval));
                if (interval) {
                    style = { background: INTERVAL_STATE_COLORS[interval.type] };
                    text = INTERVAL_STATE_LABELS[interval.type][0];
                    title = INTERVAL_STATE_LABELS[interval.type][1] || "";
                }

                if (repEntry[0] == "00:00" && repEntry[1] == "00:00") {
                    style = { background: INTERVAL_STATE_COLORS[INTERVAL_STATES.RECURRING_ABSENCE] };
                    text = INTERVAL_STATE_LABELS[INTERVAL_STATES.RECURRING_ABSENCE][0];
                    title = INTERVAL_STATE_LABELS[INTERVAL_STATES.RECURRING_ABSENCE][1] || "";

                }

                isInInterval(rowCount, selected) && (style = { background: "#FFFFFF77" });


                let ulInfo = ntData.ulSchedule[team.ulid];
                const dateLookup = ulInfo?.dateLookup;
                const dateStr = DateTime.fromJSDate(date).toFormat("dd-MM-yyyy");
                const dayData = dateLookup?.[dateStr];

                text ||= dayData?.jobsPerDay || "?";
                return (
                    <td data-value={rowCount} key={rowCount++} style={style} title={title}>
                        {text}
                    </td>)
            }

            )}
        </tr>
    )
}

function deleteIntervalAtIndex(intervals, index) {
    const targetIndex = intervals.findIndex(item => isInInterval(index, item.interval));
    if (targetIndex >= 0) {
        intervals.splice(targetIndex, 1);
    }
}

function saveIntervalAtIndex(intervals, index) {
    const targetIndex = intervals.findIndex(item => isInInterval(index, item.interval));
    if (targetIndex >= 0 && intervals[targetIndex].approved === false) {
        intervals[targetIndex].approved = true;
        intervals[targetIndex].type = intervals[targetIndex].type + "_SAVED";

    }
}


function isInInterval(value, interval) {
    if (!interval) return false;
    let minterval = [min(interval), max(interval)];
    if (value >= minterval[0] && value <= minterval[1]) return true;
    return false;
}

function getCellValue(event) {
    const cell = event.target.closest('td');
    if (cell) {
        const val = cell.getAttribute('data-value');
        if (val === null || val === undefined) return undefined;
        return +val;
        //console.log(" value",  value);
    }
    return undefined;
}

