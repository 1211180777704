import { Block, Button, Card, CardContent, CardFooter, CardHeader, Navbar, Page } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import MySettings from '../components/settings/MySettings';
import { teamsDefPref } from '../components/settings/teamDefaultPrefs';
import { sharedData } from '../data/SharedData';
import { beConnectorInstance } from '../js/BeConnector';
import Toaster from '../js/utils/Toaster';
import { CenteredPreloader } from '../js/utils/visHelper';

const SETTINGPAGE_STATES = {
    "UNINITIALIZED": "UNINITIALIZED",
    "LOADING": "LOADING",
    "READY": "READY",
}

const SETTINGS_TRANSLATION = {
    label: {
        CHAT: "Chat beskeder",
        MOVE_CLEANING: "Flyt rengøring",
        ADD_EXTRA_TASKS: "Tilføj ekstra opgaver",
        CANCEL_CLEANING: "Aflys rengøring",
        MAX_CANCEL_PERCENT: "Max aflysningsprocent",
        mitSH: "Mit ServiceHuset",
        messagePrefs: "Beskedsindstillinger",
    }, description: {
        messagePrefs: "Instillinger for beskeder, aflysninger, mm. sendt via Mit.ServiceHuset",
        CANCEL_CLEANING: "OBS: Hvis kunde aflyser sidste besøg og vi stadig har deres nøgle bliver der altid sendt besked til os.",
        MAX_CANCEL_PERCENT: "Send kun besked hvis antal aflysninger er over denne procent. 0=altid send besked. 100=aldrig send besked."
    }
}


export default function (_props) {
    const stateRef = useRef(SETTINGPAGE_STATES.UNINITIALIZED);
    const [settingsData, setSettingsData] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const settingToSaveRef = useRef();


    useEffect(() => {
        async function fetchData() {
            if (stateRef.current == SETTINGPAGE_STATES.UNINITIALIZED) {
                stateRef.current = SETTINGPAGE_STATES.LOADING;
                setShowLoader(true);
                let result = await beConnectorInstance.getNextUserSettings(sharedData.getCurrentUserName());
                result = {};
                console.log("SettingsPage: useEffect: Loading: result", result);
                settingToSaveRef.current = { ...teamsDefPref, ...result.settings };
                stateRef.current = SETTINGPAGE_STATES.READY;
                const preferences = settingToSaveRef.current.preferences;
                setSettingsData(preferences);
                setShowLoader(false);
            }

        }
        fetchData();
    }, []);

    async function saveSettings() {
        setShowLoader(true);


        await new Promise(r => setTimeout(r, 4000));
        console.log("SettingsPage: saveSettings: settingToSaveRef.current", settingToSaveRef.current);
        //beConnectorInstance.saveNextUserSettings(sharedData.getCurrentUserName(), settingToSaveRef.current);
        setShowLoader(false);
        Toaster.ShowToast("Indstillinger gemt");
    }


    async function settingsChanged(preferences) {
        //console.log("SettingsPage: settingsChanged: preferences", preferences);
        /*         let result = await beConnectorInstance.setNextUserSettings(sharedData.getCurrentUserName(), settings);
                console.log("SettingsPage: settingsChanged: result", result); */
        settingToSaveRef.current.preferences = { ...settingToSaveRef.current.preferences, ...preferences };
        //console.log("SettingsPage: settingsChanged: settingToSaveRef.current", settingToSaveRef.current);
    }

    const showSettings = (stateRef.current == SETTINGPAGE_STATES.READY);

    return (
        <Page>
            <Navbar title={`Teams opsætning:`} backLink="Back" />
            <Block strong style={{ zIndex: "unset" }}>
                {showLoader && <CenteredPreloader />}
            </Block>
            <Block>
                <select name="area" defaultValue={"Alex og Gabriella (117)"} >
                    <option value="Alex og Gabriella (117)" id="Alex og Gabriella (117)">Alex og Gabriella (117)</option>
                    <option value="Miro" id="Miro">Miro</option>
                    <option value="H/ Safha" id="H/ Safha">H/ Safha</option>
                    <option value="Simone" id="Simone">Simone</option>
                    <option value="Mario" id="Mario">Mario</option>
                    <option value="A/ Hasan" id="A/ Hasan">A/ Hasan</option>
                    <option value="Ljiljana" id="Ljiljana">Ljiljana</option>
                    <option value="Anja og Jad" id="Anja og Jad">Anja og Jad</option>
                    <option value="Julie" id="Julie">Julie</option>
                    <option value="Mara" id="Mara">Mara</option>
                    <option value="Susanne" id="Susanne">Susanne</option>
                    <option value="Anelin" id="Anelin">Anelin</option>
                    <option value="Dennis" id="Dennis">Dennis</option>
                    <option value="Ria" id="Ria">Ria</option>
                    <option value="Aima" id="Aima">Aima</option>
                    <option value="Lora" id="Lora">Lora</option>
                    <option value="Randi" id="Randi">Randi</option>
                    <option value="Sujan" id="Sujan">Sujan</option>
                    <option value="Danio" id="Danio">Danio</option>
                    <option value="Yasin" id="Yasin">Yasin</option>
                    <option value="Josip" id="Josip">Josip</option>
                    <option value="Khloe" id="Khloe">Khloe</option>
                    <option value="Jannet" id="Jannet">Jannet</option>
                    <option value="Andrei" id="Andrei">Andrei</option>
                    <option value="Daphine" id="Daphine">Daphine</option>
                    <option value="Patrina" id="Patrina">Patrina</option>
                    <option value="Tatjana" id="Tatjana">Tatjana</option>
                    <option value="Joanitah" id="Joanitah">Joanitah</option>
                    <option value="Nadya og Ivan" id="Nadya og Ivan">Nadya og Ivan</option>
                    <option value="Anita og Tesic" id="Anita og Tesic">Anita og Tesic</option>
                    <option value="Venelina/Kalinka" id="Venelina/Kalinka">Venelina/Kalinka</option>
                    <option value="H/ Khalida" id="H/ Khalida">H/ Khalida</option>
                    <option value="Natasha" id="Natasha">Natasha</option>
                    <option value="Ivana og Memi" id="Ivana og Memi">Ivana og Memi</option>
                    <option value="Milene" id="Milene">Milene</option>
                    <option value="Mohamed" id="Mohamed">Mohamed</option>
                    <option value="Elmedin" id="Elmedin">Elmedin</option>
                    <option value="Anica" id="Anica">Anica</option>
                    <option value="Denisa" id="Denisa">Denisa</option>
                    <option value="H/ Anja/ Jad" id="H/ Anja/ Jad">H/ Anja/ Jad</option>


                </select>
            </Block>

            <Block>
                <Card animate style={{ maxWidth: "50rem", margin: "0 auto" }}>
                    <CardHeader className='new-style-card-header'></CardHeader>
                    <CardContent className='new-style-card-content-settings'>{showSettings && <MySettings settings={settingsData} onUpdate={settingsChanged} translation={SETTINGS_TRANSLATION} />}</CardContent>
                    <CardFooter className='new-style-card-footer'>
                        <div ></div>
                        <div ></div>
                        <div >
                            <Button fill raised className='new-style-button-green' onClick={saveSettings}>Gem</Button>
                        </div>
                    </CardFooter>
                </Card>
            </Block>
        </Page>
    )
}