import copy from 'copy-to-clipboard';
import { Button, Card, Col, f7, Icon, List, ListItem, Navbar, Page, Preloader, Row } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import { customerHandler } from '../js/CustomerHandler';
import { CenteredPreloader } from '../js/utils/visHelper';
import { formatDateLight } from '../utils/DateHelperWeb';
import { CustomerTaskList } from '../views/CustomerTasksList';

//const customerHandler = CustomerHandler.getInstance();
const GET_LEGACY_MESSAGES_SERVERURL = "https://next1.servicehuset.dk/be_v_100/getLegacyMessages?";
//---

function CustomerPage(props) {
    const [customerData, setCustomerData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    if (!isLoading && !customerData) {
        customerHandler.loadCustomerdata(props.id).then((data) => {
            setCustomerData(data);
            setIsLoading(false);
        });


    }

    const body = customerData ? <CustomerPageBase id={props.id} /> : <CenteredPreloader />;

    return (
        <Page>
            <Navbar title={"KundeId: " + props.id} backLink="Back" >

            </Navbar>
            {body}
        </Page>)

}



const CustomerPageBase = (props) => {
    const [editField, setEditField] = useState(undefined);
    let data = customerHandler.getCustomerById(props.id);
    const editTextRef = useRef(null);
    let stamData = getStamdata();
    let bemData = getBemData();
    let ratingData = getRatingTimeLine();
    const [messageHistoryItems, setMessageHistoryItems] = useState(undefined);

    const isRegistered = data.SLUTDATO != undefined;

    useEffect(() => {
        getMessageHistoryItems(setMessageHistoryItems);
    }, []);

    function registerCustomer() {
        console.log("registerCustomer");
        data.SLUTDATO = new Date("9999-12-31");
        data.BETALING = " "
        setEditField("BETALING");
    }


    return (
        <>
            <Row><Col><div className='sh-customer-menu-button-area'>
                {!isRegistered && <Button fill className='sh-customer-menu-button' onClick={registerCustomer}>Registrer som kunde</Button>}

            </div>
            </Col></Row>

            <Row>
                <Col width="100" medium="50">
                    <Card>
                        <span slot="header">Stamdata:</span>
                        <span slot="content">

                            <List>
                                {stamData}
                            </List>
                        </span>

                    </Card>

                </Col>

                <Col width="100" medium="50">
                    <Card>
                        <span slot="header">Info:</span>
                        <span slot="content">

                            <List>
                                {bemData}
                            </List>
                        </span>

                    </Card>
                </Col>
                {isRegistered && <Col width="100" medium="50">
                    <Card >
                        <span slot="header">Opgaver:</span>
                        <span slot="content">

                            <List>
                                <CustomerTaskList />
                            </List>
                        </span>
                        {/*  <span slot="footer"><Button fill outline href={"/newTask/" + props.id}> Opret ny opgave </Button> </span> */}

                    </Card>
                </Col>}
                {ratingData && <Col width="100" medium="50">
                    <Card>
                        <span slot="header">UL Ratings:</span>
                        <span slot="content">

                            <div className="timeline">
                                {ratingData}

                            </div>
                        </span>

                    </Card>

                </Col>}
                {isRegistered && <Col width="100" medium="50">
                    <Card>

                        <span slot="header">Besked historik:</span>
                        <span slot="content">

                            {!messageHistoryItems && <div style={{ margin: "auto", width: "120px" }}><Preloader color="multi" /></div>}
                            <div className="timeline">
                                {messageHistoryItems}

                            </div>
                        </span>

                    </Card>


                </Col>}
            </Row>


        </>
    );

    function getRatingTimeLine() {

        let out = [];

        if (!data.ratings) return undefined;

        Object.entries(data.ratings).forEach(([key, ul]) => {

            let name = "" + key + " - " + ul.name + ": ";

            for (const rating of ul.ratings) {
                let date = formatDateLight(rating.date, "d-m-Y");
                let time = formatDateLight(rating.date, "H:M:S");
                let text = name + rating.rating;
                out.push(getTimeLineItem("rating", date, time, text));
            }
        });
        return out.length > 0 ? out : undefined;

    }
    //customerHandler.categoryTitles
    function getBemData() {
        const catList = customerHandler.categoryTitles.map((value) => {
            return { value: value, display: value };
        });

        const paymentIdList = customerHandler.paymentIds.map((values) => {
            const disp = values.BETALID.padEnd(5) + values.TEKST;
            return { value: disp, display: disp };
        });


        return [
            getFormatedItem("Kunde kategori:", extractSt("KUNDEKAT"), "KUNDEKAT", { type: "select", selectList: catList }),
            getFormatedItem("Kontakt:", extractSt("KONTAKT"), "KONTAKT"),
            getFormatedItem("Ref:", extractSt("LOGUSER"), "LOGUSER"),
            getFormatedItem("Bemærkninger (adm):", data.OMKBEM1, "OMKBEM1", { type: "textArea" }),
            getFormatedItem("Bemærkninger (drift):", extractSt("BEM"), "BEM", { type: "textArea" }),
            getFormatedItem("Alarm & adgang:", extractSt("ALARM"), "ALARM"),
            getFormatedItem("Betalings ID:", extractSt("BETALING"), "BETALING", { type: "select", selectList: paymentIdList }),
        ]
    }

    function getStamdata() {
        return [
            getFormatedItem("Kunde id:", data.KUNDEID, "KUNDEID"),
            getFormatedItem("Navn:", extractSt("FIRMA"), "FIRMA"),
            getFormatedItem("Adresse:", extractSt("ADDR1"), "ADDR1"),
            getFormatedItem("Postnummer:", extractSt("POSTNR"), "POSTNR"),
            getFormatedItem("Telefon:", extractSt("TLF"), "TLF"),
            getFormatedItem("Mail:", extractSt("EMAIL"), "EMAIL"),

        ]
    }

    function clickElement(elementText) {
        //let value = currentOffer[Number(id)].value;

        //    currentconsole.log("clicked element.", id, currentOffer[Number(id)], value);

        copy(elementText);
        showToastTop(elementText);
    }



    function extractSt(key) {
        let res = data["ST_" + key];
        if (res) return res;
        return data[key];
    }




    function getFormatedItem(key, val, dbKey, params = { type: "text" }) {

        if (!val) return null;

        if (editField != dbKey) {
            return (
                <ListItem id={key} key={dbKey} link="#">
                    <div slot="title" style={{ whiteSpace: 'pre-line' }} onClick={(_e) => clickElement('' + val)}>{val}</div>
                    <div slot="header" style={{ color: "#71c5b2" }}>{key}</div>
                    <div onClick={() => { editField == dbKey ? setEditField(undefined) : setEditField(dbKey) }}><Icon f7="pencil" size="25"></Icon></div>
                </ListItem>
            )
        }

        let body = (<></>);

        switch (params.type) {
            case "select":
                body = (<select name="area"
                    onBlur={() => {
                        setTimeout(() => {
                            setEditField(undefined);
                        }, 300);
                    }}
                    autoFocus={true}
                    defaultValue={val}
                    ref={editTextRef}
                    onChange={() => { }}>
                    {params.selectList.map((values, index) => {
                        return <option value={values.value} key={index}>{values.display}</option>
                    })}


                </select>)

                break;
            case "text":
                body = (<input className='sh-text-input-field'
                    ref={editTextRef}
                    type="text"
                    autoFocus={true}
                    defaultValue={val}
                    onBlur={() => {
                        setTimeout(() => {
                            setEditField(undefined);
                        }, 300);
                        //customerHandler.updateCustomer(props.id, dbKey, e.currentTarget.value)
                    }} />)
                break;
            case "textArea":
                body = (<textarea className='sh-text-input-field'
                    style={{ resize: "vertical" }}
                    ref={editTextRef}
                    type="text"
                    autoFocus={true}
                    defaultValue={val}
                    onBlur={() => {
                        setTimeout(() => {
                            setEditField(undefined);
                        }, 300);

                    }} />)
                break;

        }


        return (
            <ListItem id={key} key={dbKey} >

                {body}




                <Button outline onClick={async () => {
                    console.log("GEM:", dbKey, editTextRef.current.value);
                    await changeValue(dbKey, editTextRef.current.value)
                    setEditField(undefined);

                }}>GEM</Button>

            </ListItem>


        );


    }

    async function changeValue(dbKey, newValue) {

        console.log("changeValue:", dbKey, newValue);
        const stKey = "ST_" + dbKey;

        if (data[stKey]) {
            data[stKey] = newValue;
        }
        if (data[dbKey]) {
            data[dbKey] = newValue;
        }



    }

    async function getMessageHistoryItems(setMessageHistoryItemsCallback) {
        try {
            let res = await fetch(GET_LEGACY_MESSAGES_SERVERURL + "id=" + props.id);
            var result = await res.json();

        } catch (error) {
            console.log("getMessageHistoryItems : Fetch failed ", error);
        }

        let rval = result.map((value, _index) => {
            let tdate = new Date(value.ACTIDATE);
            let time = tdate.getHours() + ":" + tdate.getMinutes() + ":" + tdate.getSeconds()
            let smallDate = tdate.getDate() + "/" + (tdate.getMonth() + 1) + "-" + tdate.getFullYear();

            return getTimeLineItem("message", smallDate, time, value.REMARKS);
        })
        setMessageHistoryItemsCallback(rval);

    }




}

export function getTimeLineItem(keyPrefix, date, time, text) {

    const key = keyPrefix + date + "-" + time;
    //console.log("getMessageTimeLineItem key:", key);

    return (
        <div className="timeline-item" key={key}>
            <div className="timeline-item-date">
                {date}
            </div>
            <div className="timeline-item-divider"></div>
            <div className="timeline-item-content">
                <div className="timeline-item-inner">
                    <div className="timeline-item-time">{time}</div>
                    {/* <div className="timeline-item-title">Item Title</div>
                    <div className="timeline-item-subtitle">Item Subtitle</div> */}
                    <div className="timeline-item-text">
                        {text}
                    </div>

                </div>
            </div>
        </div>)
}

const showToastTop = (text) => {
    // Create toast
    let toastTop;
    if (!toastTop) {
        toastTop = f7.toast.create({
            text: 'Kopieret: ' + text,
            position: 'top',
            horizontalPosition: 'center',
            closeTimeout: 2000,
            destroyOnClose: true
        });
    }
    // Open it

    toastTop.open();
}

export default CustomerPage;
