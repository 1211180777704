import { f7 } from "framework7-react";


export default class Toaster {

    static mainPreloader;

    static ShowToast(text) {
        let dbgToast;

        if (f7 && f7.initialized && text) {

            dbgToast = f7.toast.create({
                text: text,
                position: 'top',
                horizontalPosition: 'center',
                closeTimeout: text.length * 100,
                destroyOnClose: true,
                closeButton: true
                //                on: { closed: LL.toastClosed }
            });
            dbgToast.open();
        }
    }

    static ShowToastCenter(text, callback) {
        let dbgToast;

        if (f7 && f7.initialized && text) {

            dbgToast = f7.toast.create({
                text: text,
                position: 'center',
                horizontalPosition: 'center',
                closeTimeout: text.length * 200,
                destroyOnClose: true,
                closeButton: true,
                on: { closed: toastClosed }
            });
            dbgToast.open();
        }

        function toastClosed() {
            callback && callback();
        }
    }

    static closePreloader() {
        Toaster.preloaderActive = false;
        f7.dialog.close();
    }

    static showPreloader(text, timeoutSeconds = 20) {
        Toaster.preloaderActive = true;
        f7.dialog.preloader(text);
        if (timeoutSeconds) {
            setTimeout(() => Toaster.closePreloader(), timeoutSeconds * 1000);
        }

    }


}