import { Block, BlockTitle, Button, Col, Input, Navbar, Page, PageContent, Row, Toolbar, View } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import Connector from '../Connector';
import { customerHandler } from '../js/CustomerHandler';
import StateManager from '../js/StateManager';
import CustomerList from '../views/CustomerList';

//const customerHandler = CustomerHandler.getInstance();
let lastSearch = "";

const CustomersPage = (_props) => {
    var currentArea = "" + Connector.currentArea || '0';
    currentArea === '99' && (currentArea = '0');
    const [activeArea, setActiveArea] = useState(currentArea);
    const [activeType, setActiveType] = useState("all");
    const [resultList, setResultList] = useState(undefined);
    const [resultCount, setResultCount] = useState(0);
    //const [isLoading, _setIsLoading] = useState(false);
    const [_autoSearch, _setAutoSearch] = useState(true);
    const textSearchText = useRef("");

    useEffect(() => {
        StateManager.setState('setActivePage', 'customers');

        !resultList && customerHandler.load(setResultList, setResultCount);
        customerHandler.setFilter("area", currentArea);
    }, []);

    function changeArea(e) {
        //setList(undefined);
        customerHandler.setFilter("area", e.currentTarget.value);
        setActiveArea(e.currentTarget.value)

    }

    function changeCustomerType(e) {
        //setList(undefined);
        customerHandler.setFilter("type", e.currentTarget.value);
        setActiveType(e.currentTarget.value)
        if (e.currentTarget.value === "potential") {
            setActiveArea(0);
            customerHandler.setFilter("area", 0);
        }
    }

    /* function changeSetting(name, val) {
        customerHandler.setFilter(name, val);
    }
 */
    function textChange(e) {

        lastSearch = textSearchText.current = e.currentTarget.value;
        customerHandler.multiSearch(textSearchText.current);
    }

    function doSearch() {
        console.log("Activate search now!");
        customerHandler.retrieveResult(9999);

    }

    return (
        <Page >
            <Navbar title="Kunder" backLink="Back" />
            <PageContent style={{ overflow: "hidden", paddingTop: "0rem" }}>
                <Block>
                    <BlockTitle>Filter:</BlockTitle>
                    <Block strong>
                        <Row>
                            <Col width="100" medium="33">
                                <div className="trans-50">Område:</div>
                                <select name="area" value={activeArea} onChange={changeArea}>
                                    <option value="1" id="1" >Område 1</option>
                                    <option value="3" id="3">Område 3</option>
                                    <option value="4" id="4">Område 4</option>
                                    <option value="5" id="5">Område 5</option>
                                    <option value="0" id="6">Alle områder</option>
                                </select>
                            </Col>
                            <Col width="100" medium="33">
                                <Row>

                                    <Col>
                                        <div className="trans-50">Søgetekst:</div>
                                        <Input inputStyle={{ fontSize: "1.1rem" }} type="text" inputId="freeTextSearch" onChange={textChange} defaultValue={lastSearch} />
                                    </Col>
                                    <Col>
                                        <Button disabled={(resultList && resultCount > 0)} outline style={{ width: "max-content" }} onClick={doSearch}> {"Søg nu (" + resultCount + " kunder)"} </Button>
                                    </Col>
                                </Row>

                            </Col>
                            <Col width="100" medium="33">
                                <div className="trans-50">kundetype:</div>
                                <select name="area" defaultValue={activeType} onChange={changeCustomerType}>
                                    <option value="all" id="all">Alle</option>
                                    <option value="regular" id="regular">Faste</option>
                                    <option value="adhoc" id="adhoc">Ad hoc</option>
                                    <option value="potential" id="potential">Kundeemner</option>
                                    <option value="old" id="old">Ophørte</option>
                                </select>
                                {/* Inkluder ophørte kunder: &nbsp; <Checkbox id="inactiveCustomer" onChange={e => changeSetting("inactive", e.currentTarget.checked ? "true" : "false")} defaultChecked={false} /><br /> */}

                            </Col>
                        </Row>
                    </Block>
                </Block>
                {<View style={{ height: "80%" }}>
                    <Page name="test2" pageContent={false}  >
                        <PageContent style={{ paddingTop: "0px" }} >
                            <Block>
                                <Block strong>
                                    <CustomerList list={resultList}></CustomerList>
                                </Block>
                            </Block>
                        </PageContent>
                    </Page>
                </View>}

            </PageContent>
            <Toolbar bottom>
            </Toolbar>
        </Page>
    );
}

export default CustomersPage;
