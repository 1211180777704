import { Block, BlockTitle, Icon, Link, List, ListItem, Navbar, NavLeft, NavRight, Page, Toolbar } from 'framework7-react';
import React, { useState } from 'react';
import Connector from '../Connector';
import Config from '../js/Config';
import StateManager from '../js/StateManager';

const PanelMenu = () => {
    const [username, setUsername] = useState(Connector.loginName);
    const [activePage, setActivePage] = useState("");
    const [appVer, setAppVer] = useState(Config.version);
    StateManager.registerStateDispatcher("setAppVer", setAppVer);


    Connector.updateUsernameCall(setUsername);
    StateManager.registerStateDispatcher("setActivePage", setActivePage, true);

    function logOut() {
        localStorage.removeItem("SH-NEXT-LOGIN");
    }

    function menuClicked(_e) {
        //console.log("clicked:", e.currentTarget.id); // no id?
        setActivePage("");

    }


    return (<Page name="menu">

        <Navbar style={{ minHeight: "57px" }}>
            <NavLeft>
                <img src="/icons/128x128.png" width="50" />
            </NavLeft>
            <NavRight >
                {/* <Block>
                    <Row>
                        <Col >
                            <Button small popupOpen="#my-popup">{username}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button raised small loginScreenOpen="#my-login-screen" onClick={logOut}>Log ud</Button>
                        </Col>
                    </Row>
                </Block> */}
            </NavRight>
        </Navbar>

        <List>
            <ListItem title={username} header="Logget ind som:">
                <Link slot="after" reloadAll onClick={logOut} loginScreenOpen="#my-login-screen" href="/" external>Log ud&nbsp;<Icon slot="after" md="material:exit_to_app" /></Link>

            </ListItem>
        </List>

        <BlockTitle>Menu:</BlockTitle>
        <List>
            <ListItem link="https://next-v2-be.servicehuset.dk/NextMail/" external title={"Next Mail"} openIn='_blank' panelClose className={activePage == "NextMail" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <List>
            <ListItem link="/rating/" title={"UL-Rating"} panelClose className={activePage == "ulRating" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <List>
            <ListItem link="/teams/" title={"Kalender"} panelClose className={activePage == "teams" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <List>
            <ListItem link="/customers/" title={"Kunder"} panelClose className={activePage == "customers" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <List>
            <ListItem link="/offers-page/" title={"Tilbud"} panelClose className={activePage == "tilbud" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <List>
            <ListItem link="/postnumre/" title={"Postnumre"} panelClose className={activePage == "postnumre" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <List>
            <ListItem link="/teamView/" title={"Team Live View"} panelClose className={activePage == "teamView" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        {/*         <List>
            <ListItem link="/TeamPref/" title={"Teams instillinger"} panelClose className={activePage == "teamView" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List> */}
        <List>
            <ListItem link="/settings/" title={"Indstillinger"} panelClose className={activePage == "settings" ? "active-menu-button" : ""} onClick={menuClicked} />

        </List>
        <Block>

        </Block>

        <Toolbar bottom>
            <Link></Link>
            <Link>{"version: " + appVer}</Link>
        </Toolbar>


    </Page>)
}



export default PanelMenu;