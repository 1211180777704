import { Block, Checkbox, Col, Link, Menu, MenuDropdown, MenuDropdownItem, MenuItem, Navbar, NavLeft, NavRight, Page, Row } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';

const menuAreasClean = { area1: false, area3: false };
const daysDefault = {};
let menuAreas = { ...menuAreasClean };
const AREA_LOOKUP = {
    area1: 1,
    area2: 1,
    area3: 3,
    area4: 3,
    area5: 1,
    area99: 1
};

const ulSortingOptions = {
    DISTANCE: "Distance",
    TASKS: "Opgaver",
    NUM_TASKS: "Opgaver pr uge",
    ALPHA: "Alfabetisk",
    ULID_DESC: "UL ID (Faldende)",
    ULID: "UL ID (Stigende)",

}

const FORM_FREQ_LOOKUP = {
    "Hver uge": "WEEK1",
    "Hver 2. uge": "WEEK2",
    "Hver 4. uge": "WEEK4",
    "ved ikke lige nu": "UNKNOWN"
}

const intervalOptions = {
    WEEK1: "Hver uge",
    WEEK2: "Hver anden uge",
    WEEK4: "Hver 4. uge",
    UNKNOWN: "Ukendt",
    ADHOC: "Flytte-/Engangsrengøring"
}

let selectedULS = {};


let setSelectedULCB;

export function ULSelector({ dataParams, setSelectedULs, currentSelectedULs }) {
    const [sortOrder, setSortOrder] = useState("DISTANCE");
    const [showFromWeek, setShowFromWeek] = useState(-1);
    const [availableWeeks, setAvailableWeeks] = useState(undefined);
    const [activeAreas, setActiveAreas] = useState(menuAreas);
    const [actieveDays, setActiveDays] = useState({});

    const reqArea = useRef(undefined);



    setSelectedULCB = setSelectedULs;

    useEffect(() => {
        if (dataParams && !reqArea.current) {
            selectedULS = {};
            menuAreas = { ...menuAreasClean };
            reqArea.current = AREA_LOOKUP[dataParams.offer.area] || 1;
            menuAreas["area" + reqArea.current] = true;
            dataParams.requestedArea = reqArea.current;
            setActiveAreas({ ...menuAreas });
            console.log("setActiveArea:", { ...menuAreas });
            selectDays(dataParams?.offer?.dictionary?.dage);
            setActiveDays({ ...daysDefault })

        }
        return () => {
            console.log("unloading");
        }

    }, [dataParams, currentSelectedULs]);

    if (availableWeeks && showFromWeek == -1) {
        setShowFromWeek(availableWeeks.first);
        return undefined;
    }



    if (!dataParams) return null;
    //let theTable = MyTable(dataParams.offerServerInfo.ulData);
    console.log("- ActiveArea:", menuAreas);
    return (
        <Page>
            <Navbar title={"UL Oversigt"}>

                <NavLeft></NavLeft>
                <NavRight>
                    <Link back>Luk ❌</Link>
                </NavRight>
            </Navbar>
            <Block strong>
                <UlSelectorMenu setActiveAreas={setActiveAreas}
                    setActiveDays={setActiveDays}
                    setSortOrder={setSortOrder}
                    dataParams={dataParams}
                    sortOrder={sortOrder}
                />
            </Block>

            <Block strong style={{ zIndex: "unset" }}>
                <div style={{ overflow: "auto" }}>
                    {<UlActivityTable {...dataParams.offerServerInfo.ulData}
                        activeAreas={activeAreas}
                        actieveDays={actieveDays}
                        sortOrder={sortOrder}
                        selectedStartWeek={showFromWeek}
                        setAvailableWeeks={setAvailableWeeks}
                    />}


                </div>
            </Block>
        </Page>
    )

    function CustomerInfoBox({ offer }) {
        offer.dictionary.frekvensA1 && (offer.dictionary.frekvens = offer.dictionary.frekvensA1);
        let area = { area99: 99, area1: 1, area2: 1, area3: 3, area4: 3, area5: 1 }[offer.area];
        let freq = offer.dictionary?.frekvens?.value;
        let freqID = FORM_FREQ_LOOKUP[freq] || "ADHOC";
        let freqText = intervalOptions[freqID];

        return (
            <div style={{ padding: "0 5px" }}>
                <div>Område: {area}</div>
                <div>Navn: {offer.name}</div>
                <div>Værdi: {offer.moneyValue || "?"}</div>
                <div>Frekvens: {freqText}</div>
            </div>
        )
    }

    function UlSelectorMenu({ setActiveAreas, setActiveDays, setSortOrder, sortOrder, dataParams }) {
        const areaRef = useRef(menuAreas);
        const dayRef = useRef({});
        areaRef.current = menuAreas;
        dayRef.current = daysDefault;

        return (
            <Row noGap style={{ border: "1px solid #ffffff14" }}>
                <Col width="15"><CustomerInfoBox offer={dataParams.offer} /></Col>
                <Col width="30" style={{ border: "1px solid #ffffff14", backgroundColor: "#56528021" }}>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"monday"} onChange={dayChange} checked={dayRef.current["monday"] || false} />   {"mandag"}</p>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"tuesday"} onChange={dayChange} checked={dayRef.current["tuesday"] || false} />   {"tirsdag"}</p>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"wednesday"} onChange={dayChange} checked={dayRef.current["wednesday"] || false} />   {"onsdag"}</p>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"thursday"} onChange={dayChange} checked={dayRef.current["thursday"] || false} />   {"torsdag"}</p>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"friday"} onChange={dayChange} checked={dayRef.current["friday"] || false} />   {"fredag"}</p>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"saturday"} onChange={dayChange} checked={dayRef.current["saturday"] || false} />   {"lørdag"}</p>
                        <p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuDay" value={"sunday"} onChange={dayChange} checked={dayRef.current["sunday"] || false} />   {"søndag"}</p>
                    </div>
                </Col>
                <Col width="20">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <BasicDropDownMenu setSortOrder={setSortOrder} sortOrder={sortOrder} menuItems={ulSortingOptions} title="Sortering:" />
                        <BasicDropDownMenu setSortOrder={setShowFromWeek} sortOrder={showFromWeek} menuItems={availableWeeks?.list} title="Start uge:" />
                    </div>
                </Col>
                <Col width="10">
                    <div style={{ display: "flex", flexWrap: "wrap", float: "right", border: "1px solid #ffffff14", backgroundColor: "#56528021" }}>
                        < p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuArea" value={"area1"} onChange={areaChange} checked={areaRef.current["area1"]} /> &nbsp; {"område 1"}</p>
                        < p style={{ padding: "0 21px" }}> <Checkbox name="UlMenuArea" value={"area3"} onChange={areaChange} checked={areaRef.current["area3"]} /> &nbsp; {"område 3"}</p>
                    </div>

                </Col >
            </Row >)

        function dayChange(e) {
            console.log("dayChange e.currentTarget.value", e.currentTarget.value, e.currentTarget.checked);
            console.log("dayChange e.currentTarget.value", e.currentTarget.value, e.currentTarget.checked);
            dayRef.current[e.currentTarget.value] = e.currentTarget.checked;
            console.log("dayRef.current", dayRef.current);
            setActiveDays({ ...dayRef.current });
        }

        function areaChange(e) {
            console.log("e.currentTarget.value", e.currentTarget.value);
            console.log(areaRef.current[e.currentTarget.value]);
            areaRef.current[e.currentTarget.value] = !areaRef.current[e.currentTarget.value];
            console.log(areaRef.current[e.currentTarget.value]);
            setActiveAreas({ ...areaRef.current });
        }
    }


}

const DAYS_LOOKUP = {
    "mandag": "monday",
    "tirsdag": "tuesday",
    "onsdag": "wednesday",
    "torsdag": "thursday",
    "fredag": "friday",
    "lørdag": "saturday",
    "lørdag (pris +25%)": "saturday",
    "søndag": "sunday"
}

const DAYS_INDEX_LOOKUP = {
    "monday": 0,
    "tuesday": 1,
    "wednesday": 2,
    "thursday": 3,
    "friday": 4,
    "saturday": 5,
    "sunday": 6
}



function selectDays(dayObj) {
    let daysList = dayObj?.value || "";
    let days = daysList.split(",");
    Object.values(DAYS_LOOKUP).forEach(day => daysDefault[day] = (daysList.length > 1 ? false : true));

    days.forEach(day => {
        day = day.toLocaleLowerCase().trim()
        daysDefault[DAYS_LOOKUP[day]] = true;
    });

    return daysDefault;
}



const COLOR_CLAMP_VALUE = 50;
const COLOR_CLAMP_SHIFT = 10;

const getColor = (value, maxValue = 4) => {
    maxValue < 4 && (maxValue = 4);
    value > maxValue && (value = maxValue);
    // Calculate a value between 0 and 1 based on the input value
    //const normalizedValue = value / 28;
    const normalizedValue = (value / maxValue); // inverse
    let calc = (255 - (COLOR_CLAMP_VALUE * 2)) * normalizedValue;
    // Calculate the red, green, and blue values using the normalized value
    //   const red = Math.round(calc) + COLOR_CLAMP_SHIFT;
    //   const green = Math.round(255 - (COLOR_CLAMP_VALUE * 2) - calc) + COLOR_CLAMP_SHIFT;
    //   const blue = 0;
    const red = COLOR_CLAMP_VALUE;
    const green = Math.round(255 - (COLOR_CLAMP_VALUE * 2) - calc) + COLOR_CLAMP_SHIFT;
    const blue = COLOR_CLAMP_VALUE;

    // Return the calculated color as a CSS color string
    return `rgb(${red}, ${green}, ${blue})`;
};

const daysNames = ['M', 'T', 'O', 'T', 'F', 'L', 'S'];

const BLACK_LIST = { 168: true, 9898: true, 213: true };

const UlActivityTable = ({ allSchedules, activeAreas, actieveDays, sortOrder, selectedStartWeek, setAvailableWeeks }) => {
    //const lastStartWeek = useRef(0);


    let totalJobsPerDay = [0, 0, 0, 0, 0, 0, 0];
    let totalweeks = 0;
    let totalAveragePerDay;
    let totalHigh = 0;
    let globalStartWeek = 999;
    let globalEndWeek = 0;

    let scheduleData = [];

    let activeDayIndexes = {}
    Object.entries(actieveDays).forEach(([key, val]) => activeDayIndexes[DAYS_INDEX_LOOKUP[key]] = val);

    console.log("_____active Days:", actieveDays);

    Object.entries(allSchedules).forEach(([key, data]) => {

        if (!activeAreas[{ 1: "area1", 2: "area1", 3: "area3", 4: "area3", 5: "area1" }[data.areaNum]]) return;
        if (BLACK_LIST[key]) return;

        data.pureName = fixName(data.ulName);
        data.averageJobsPerWeek ||= 2;
        let weeks = [];
        let minDist = [];
        let item = { name: data.pureName, nameOld: data.ulName, ID: key, average: Math.round(data.averageJobsPerWeek), weeks: weeks, minDist: minDist }
        let high = 0;

        let startWeek = -1;
        let startDate;

        for (const week of data.weeks) {
            if (week.week < selectedStartWeek)
                continue;

            if (startWeek == -1) {
                startWeek = week.week;
                startDate = new Date(week.weekStart);
            }

            minDist.push(week.minDist);
            weeks.push(week.jobsPerDay);
            week.jobsPerDay.forEach(val => val > high && (high = val));
            globalEndWeek < week.week && (globalEndWeek = week.week);
        }

        item.startDate = startDate;
        item.startWeek = startWeek;
        item.maxJobsPerDay = high;
        scheduleData.push(item);
        high > totalHigh && (totalHigh = high);
        totalweeks += data.weeks.length;
        globalStartWeek > startWeek && (globalStartWeek = startWeek);
        totalJobsPerDay = totalJobsPerDay.map((num, index) => num + data.jobsPerDayTotal[index]);
    });
    //let weekNumbers = new Array(globalEndWeek-globalStartWeek+1);
    let weekNumbers = Array.from({ length: globalEndWeek - globalStartWeek + 1 }, (_, index) => index + globalStartWeek);

    if (weekNumbers.length > 0 && selectedStartWeek == -1) {
        let avail = { list: {}, first: globalStartWeek };
        weekNumbers.forEach(num => avail.list[num] = "Uge " + num);
        setTimeout(() => {
            setAvailableWeeks(avail);

        });
    }

    //weekNumbers.fill()
    if (scheduleData.length <= 0) return (<div>-</div>)

    totalAveragePerDay = totalJobsPerDay.map((num) => num / totalweeks);

    console.log("totalAveragePerDay : " + totalAveragePerDay);
    console.log("totalJobsPerDay:", totalJobsPerDay);
    //scheduleData.length = 59;


    //scheduleData = _testdata;

    // sort time... 

    let distLookup = {};
    /*const sortingOptions = {
        DISTANCE: "Distance",
        TASKS: "Opgaver",
        ALPHA: "Alfabetisk",
        ULID: "UL ID",
        NUM_TASKS: "Opgaver pr uge",
    
    }
    */
    scheduleData.sort((ulA, ulB) => {

        switch (sortOrder) {
            case "DISTANCE":
                {
                    let mdis1 = distLookup[ulA.ID];
                    let mdis2 = distLookup[ulB.ID];

                    if (!mdis1) {
                        distLookup[ulA.ID] = mdis1 = calcMinDistPerDay(ulA.minDist);
                    }
                    if (!mdis2) {
                        distLookup[ulB.ID] = mdis2 = calcMinDistPerDay(ulB.minDist);
                    }
                    return mdis1[0] - mdis2[0];
                }

            case "TASKS":
                {
                    let mjob1 = distLookup[ulA.ID + "job"];
                    let mjob2 = distLookup[ulB.ID + "job"];


                    if (!mjob1) {
                        distLookup[ulA.ID + "job"] = mjob1 = calcMinDistPerDay(ulA.weeks, 0);
                        //mjob1[0] = !mjob1[0] ? 9999 : mjob1[0];
                    }
                    if (!mjob2) {
                        distLookup[ulB.ID + "job"] = mjob2 = calcMinDistPerDay(ulB.weeks, 0);
                        //mjob2[0] = !mjob2[0] ? 9999 : mjob2[0];
                    }
                    return mjob1[1] - mjob2[1];
                }
            case "ALPHA":
                return ulA.name.localeCompare(ulB.name);
            case "ULID":
                return parseInt(ulA.ID) - parseInt(ulB.ID);
            case "ULID_DESC":
                return parseInt(ulB.ID) - parseInt(ulA.ID);
            case "NUM_TASKS":
                return (ulA.average) - (ulB.average);
            case "WHAT":
                break;

        }




        //return ((mjob1 * 2) + mdis1) - ((mjob2 * 2) + mdis2);

        //return mdis1 - mdis2;


        function calcMinDistPerDay(item, minCountedval = -1) {
            let mindist = 99999;
            let average = 0;
            let count = 0;
            item.forEach(week => {
                week ||= [9999, 9999, 9999, 9999, 9999, 9999, 9999];
                week.forEach((val, index) => {

                    if (activeDayIndexes[index]) {
                        if (val < 1000 && val > minCountedval) {
                            average += val;
                            count++;
                        }
                        mindist > val && val > minCountedval && (mindist = val);
                    }
                });
            });
            average = average / count;

            //mindist = (mindist + average) / 2;

            return [mindist, average];
        }
    });

    function ulClicked(e) {
        console.log("e.currentTarget", e.currentTarget.id);
        selectedULS[e.target.id] = !selectedULS[e.target.id];
        setTimeout(() => {
            setSelectedULCB({ ...selectedULS });
        }, 25);
    }




    let list = [];
    scheduleData.forEach((row) => {
        list.push(
            (<tr key={row.ID}>
                <td className={selectedULS[row.nameOld] ? "ul-selector-name-selected" : ""}>{row.ID}</td>
                <td className="UL-selector-name"><Link key={row.nameOld} id={row.nameOld} onClick={ulClicked} className={selectedULS[row.nameOld] ? "ul-selector-name-selected" : ""}>{row.name}</Link></td>
                <td style={{ textAlign: "center" }}>{row.average}</td>
                {row.weeks.map((week) => (
                    week.map((day, index) => {
                        let isPrimary = activeDayIndexes[index];
                        if (day > 1000) {
                            return (<td key={index} style={{ backgroundColor: "#000" }} className={"ul-day-box" + (isPrimary ? " ul-select-primary-da-item" : "")}>
                                {"-"}
                            </td>)
                        } else {


                            return (<td key={index} style={{ backgroundColor: isPrimary ? getColor(day, row.maxJobsPerDay * 1.5) : "#000" }} className={"ul-day-box" + (isPrimary ? " ul-select-primary-da-item" : "")}>
                                {day}
                            </td>)
                        }

                    })
                ))}

            </tr>));
        list.push(
            <tr key={"distance" + row.ID}>
                <td></td>
                <td></td>
                <td>Distance ➡️</td>
                {row.minDist.map((distWeek) => (
                    distWeek.map((distDay, index) => {
                        let isPrimary = activeDayIndexes[index];
                        let dist = Math.floor(distDay);
                        if (dist > 2000) {
                            return (<td key={index} style={{ backgroundColor: "#000" }} className={"ul-day-box" + (isPrimary ? " ul-select-primary-da-item" : "")}>
                                {"-"}
                            </td>)

                        }
                        else {
                            return (<td key={index} style={{ backgroundColor: isPrimary ? getColor(dist, 25) : "#000" }} className={"ul-day-box" + (isPrimary ? " ul-select-primary-da-item" : "")}>
                                {dist > 99 ? 99 : dist}
                            </td>)

                        }
                    })
                ))}
            </tr>);
        list.push(<tr key={"spacer" + row.ID}><td style={{ fontSize: "4px" }}>&nbsp;</td></tr>);
    });


    return (
        <table>
            <thead>
                <tr>
                    <th style={{ minWidth: "2rem", textAlign: "left" }}>ID</th>
                    <th style={{ minWidth: "2rem", textAlign: "left" }}>Navn</th>
                    <th>Opgaver/uge</th>
                    {weekNumbers.map((week) => (
                        <th key={"week" + week} colSpan={7}>Uge {week}</th>
                    ))}
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    {scheduleData[0].weeks.map((week) => (
                        week.map((day, index) => {
                            let isPrimary = activeDayIndexes[index];
                            return (<th key={index} className={"ul-day-box" + (isPrimary ? " ul-select-primary-day" : "")}>{daysNames[index]}</th>)
                        }
                        )
                    ))}
                </tr>
            </thead>
            <tbody>
                {list}
            </tbody>
        </table>)
}
var regexNoNumber = /[0-9.:]/g;
var regexTele = /\b(tlf|tele|tel)\b/gi;

function fixName(name) {
    name = name.replace(regexNoNumber, '');
    name = name.replace(regexTele, '');
    return name.trim();
}



function BasicDropDownMenu({ setSortOrder, sortOrder, menuItems, title }) {
    // let sortRef

    if (!menuItems) return undefined;

    function sortingSelect(event) {
        setTimeout((id) => {
            console.log("sorting changed:", id);
            setSortOrder(id);
        }, 1, event.currentTarget.id);


    }

    return (
        <Menu >
            {/* <MenuItem href="#" text="Sortering: Alfabetisk" /> */}
            <MenuItem text={title + " " + menuItems[sortOrder]} dropdown>
                <MenuDropdown left>
                    {
                        Object.entries(menuItems).map(([key, val]) => {
                            return (
                                <MenuDropdownItem href="#" text={val} key={"key" + key} id={key} onClick={sortingSelect} />
                            )
                        })

                    }

                </MenuDropdown>
            </MenuItem>

        </Menu>

    )


}

