import { Block, BlockTitle, Button, Card, CardContent, CardHeader, Checkbox, Col, f7, Icon, Input, Link, Preloader, Row } from 'framework7-react';
import React, { useState } from 'react';
import InfoHelper from '../components/InfoHelper';
import Connector from '../Connector';

const NEXT_GET_POSTCODE_DATA_URL = "https://nextdev.servicehuset.dk/getPostCodeData";
const UPDATE_POSTCODE_DATA_URL = "https://nextdev.servicehuset.dk/updatePostCodeData?";

let pcodeCache = {};
let pcodeCount = 0;
var postcodelistLoading = false;
let rawdatacache = undefined;
let pcodeIndex = {};

let areaStats = { households: 0, people: 0, customers: 0, open: 0, closed: 0 };
let statCache = {};

let massFrom = 0;
let massTo = 0;
let massPause = true;
let massNoService = false;

let statref;
let statText = "";



const PostCodes = (props) => {
    if (!props.show || Connector.ready == false) {
        return null;
    }
    const [list, setList] = useState(undefined);
    let currentArea = "" + Connector.currentArea || '0';
    currentArea === '99' && (currentArea = '0');
    const [activeArea, setActiveArea] = useState(currentArea);
    const [hideDisabled, sethideDisabled] = useState(false);

    if (!list) {
        generatePostcodelist(setList, activeArea, hideDisabled);
        return (<Block strong className="text-align-center">
            <Preloader color="multi" />
        </Block>
        );
    }

    function saveMassChangeToServer(params) {

        //first=1050&comment=test2
        return new Promise((resolve, _reject) => {
            fetch(UPDATE_POSTCODE_DATA_URL + params)
                .then(res => res.json())
                .then((result) => {
                    rawdatacache = undefined;
                    pcodeCache = {};
                    setList(undefined);
                    resolve(result);
                },

                    (error) => {
                        InfoHelper.showError("Fejl i opdatering: PARAMS: " + params + " - error: " + error + "<BR>Kontakt venligst ServiceHusets IT afdeling");
                        console.error("Failed to update postcodedata ERROR:", error);
                        //reject("Failed to UPDATE");
                    }
                )
        });
    }

    const openConfirm = () => {
        f7.dialog.confirm('Er du sikker på du vil ændre fra og med postnummer: ' + massFrom + '<br> til og med postnummer: ' + massTo, "Er du sikker?", () => {
            let param = "first=" + massFrom + "&last=" + massTo + "&active=" + (!massNoService) + "&pause=" + massPause;

            if (hideDisabled) {
                param += "&ignoreNS=true";
            }

            if (activeArea != '0') {
                param += "&limitArea=" + activeArea;
                saveMassChangeToServer(param);
            }



        });
    };


    function changeArea(e) {
        setList(undefined);
        setActiveArea(e.currentTarget.value)
    }

    function changeHideDisabled(e) {
        setList(undefined);
        sethideDisabled(e.currentTarget.checked);
    }

    function saveMassChange(_e) {
        console.log("mass", massFrom, massTo, massPause, massNoService);
        if (massTo > 0) {
            openConfirm();
        }

    }



    let coverage = Math.floor(areaStats.customers / areaStats.households * 10000) / 100;
    let statStr = ": " + areaStats.customers + " kunder" + " ud af " + areaStats.households + " husstande = dækning: " + coverage + "%";
    let openclosed = getOpenPercent();
    statText = "Viser " + (activeArea == "0" ? "alle områder" : "område " + activeArea) + " (" + list.length + " postnumre" + statStr + ")";

    return (
        <div>
            <Block strong>
                <Row>
                    <Col>Vis område:
                        <select name="area" defaultValue={activeArea} onChange={changeArea}>
                            <option value="1" id="1" >Område 1</option>
                            <option value="3" id="3">Område 3</option>
                            <option value="4" id="4">Område 4</option>
                            <option value="5" id="5">Område 5</option>
                            <option value="0" id="5">Alle områder</option>
                        </select>
                    </Col>
                    <Col>Skjul Ingen-service-områder: <br />
                        <Checkbox id="showNoService" onChange={changeHideDisabled} />
                    </Col>

                </Row>
            </Block>

            {activeArea != 'NA' && <div><BlockTitle>Ændre mange:</BlockTitle>
                <Block strong>
                    <Row>
                        <Col>Fra om med postnummer: <Input type="text" id="massChangeFrom" onChange={e => massFrom = e.currentTarget.value} /></Col>
                        <Col>Til og med postnummer: <Input type="text" id="massChangeTo" onChange={e => massTo = e.currentTarget.value} /></Col>

                        <Col>Pause: &nbsp; <Checkbox id="setManyPause" onChange={e => massPause = e.currentTarget.checked} defaultChecked={massPause} /></Col>
                        <Col>Ingen Service: &nbsp; <Checkbox id="setManyNoService" onChange={e => massNoService = e.currentTarget.checked} defaultChecked={massNoService} /></Col>
                        <Col><Button outline onClick={saveMassChange}>Gem ændringer</Button></Col>

                        <Col></Col>
                    </Row>

                </Block> </div>}

            {/*             <div className="pc-scroll-area"> */}<div>
                <Card className="data-table data-table-init">
                    <CardHeader>
                        <div ref={ref => { statref = ref }} className="data-table-title">{statText + openclosed}</div>
                        <div className="data-table-actions">
                            <Link
                                iconIos="f7:line_horizontal_3_decrease"
                                iconAurora="f7:line_horizontal_3_decrease"
                                iconMd="material:sort"
                            />
                            <Link
                                iconIos="f7:ellipsis_vertical_circle"
                                iconAurora="f7:ellipsis_vertical_circle"
                                iconMd="material:more_vert"
                            />
                        </div>
                    </CardHeader>
                    <CardContent padding={false}>
                        <div className='pc-table-list-container'>
                            <table className='pc-table-list'>
                                <thead className='pc-table-list-header'>
                                    <tr>
                                        <th className="checkbox-cell">
                                            <Checkbox />
                                        </th>
                                        <th className="label-cell sortable-cell sortable-cell-active">
                                            Postnummer:
                                        </th>
                                        <th className="sortable-cell">By:</th>
                                        <th className="sortable-cell">Område:</th>
                                        <th className="sortable-cell">Pause:</th>
                                        <th className="sortable-cell">Ingen service:</th>
                                        <th className="sortable-cell">Kategori:</th>
                                        <th className="sortable-cell numeric-cell">Antal kunder / husstande / dækning</th>
                                        <th className="">
                                            <Icon
                                                ios="f7:chat_bubble_text_fill"
                                                aurora="f7:chat_bubble_text_fill"
                                                md="material:message"
                                            />{' '} Kommentar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list}
                                </tbody>
                            </table>
                        </div>
                    </CardContent>


                </Card>

            </div>
        </div>

    )
}


function generatePostcodelist(setListHandler, area, hideDisabled) {

    if (postcodelistLoading) { return }

    let cacheName = area + "-" + (hideDisabled ? "hide" : "show");

    if (pcodeCache[cacheName]) {
        console.log("PcodeCache used:", ++pcodeCount, "times");
        areaStats = statCache[cacheName];

        setListHandler(pcodeCache[cacheName]);
        return;
    }

    let areaName = "area" + area;
    var opencloseList = "";
    function handleData(result) {
        areaStats = { households: 0, people: 0, customers: 0, open: 0, closed: 0 };
        statCache[cacheName] = areaStats;
        result = result.filter((value) => (hideDisabled == false || value.active == true) && (area == 0 || value.area === areaName));

        pcodeCache[cacheName] = result.map((value, _index) => {
            let area = value.area.replace(/[^0-9]/g, '');

            opencloseList += ("" + value.postcode + ": " + value.by + " = " + (value.active == true && value.pause == false ? "ÅBEN" : "Ingen service")) + "\r\n";

            function addComment(e, pc, ref) {
                console.log("ref me", ref);
                //ref.textContent = "HALLO THERE";

                f7.dialog.prompt("Skriv kommentar:", "Kommentar", (value) => {
                    ref.textContent = value;
                    value ||= "NA";
                    saveChangeToServer(pc, "comment", value);
                }, undefined, ref.textContent);


            }

            let households = parseInt(value.households) || 0;

            areaStats.households += households;
            areaStats.people += parseInt(value.people) || 0;
            areaStats.customers += parseInt(value.customers) || 0;
            areaStats.open += (value.pause == false && value.active == true) ? households : 0;
            areaStats.closed += (value.pause == true || value.active == false) ? households : 0;

            pcodeIndex[value.postcode] = value;


            let comref;

            return (
                <tr key={value.postcode} id={"pc-" + value.postcode} name={"pc-" + value.postcode}>
                    <td className="checkbox-cell">
                        <Checkbox />
                    </td>
                    <td className="label-cell">{value.postcode}</td>
                    <td className="label-cell">{value.by}</td>
                    <td className="checkbox-cell">
                        <select name="area" defaultValue={area} onChange={e => changeOne(e, value.postcode)}>
                            <option value="1" id="1">Område 1</option>
                            <option value="3" id="3">Område 3</option>
                            <option value="4" id="4">Område 4</option>
                            <option value="5" id="5">Område 5</option>
                        </select>

                    </td>
                    <td className="checkbox-cell"><Checkbox name="pause" id="pause" defaultChecked={value.pause} onChange={e => changeOne(e, value.postcode)} /></td>
                    <td className="checkbox-cell"><Checkbox name="noService" id="noService" defaultChecked={value.active === false} onChange={e => changeOne(e, value.postcode)} /></td>
                    <td className="">
                        <select name="category" defaultValue={value.category} onChange={e => changeOne(e, value.postcode)}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="X">X</option>
                        </select>

                    </td>
                    <td className="label-cell numeric-cell">{value.customers + " / " + value.households + " / " + value.coverage + "%"}</td>
                    <td className="">


                        <Button onClick={e => addComment(e, value.postcode, comref)} >

                            <div style={{ textTransform: "none" }} id={"comment" + value.postcode} ref={ref => { comref = ref }}>{value.comment === "NA" ? "" : value.comment}</div>
                            <Icon f7="pencil" size="25"></Icon>
                        </Button>


                    </td>
                </tr>)
        })


        //fs.writeFileSync("opencloseList" + cacheName + "txt", opencloseList);
        //console.log(opencloseList);


        setListHandler(pcodeCache[cacheName]);
    }

    if (rawdatacache) {
        handleData(rawdatacache);
    } else {
        loadPostcodeList().then(result => {
            rawdatacache = result;

            handleData(result);

        }).catch(error => {
            console.error("HANDLE ERROR HERE", error);
        })

    }

    return;

}

function getOpenPercent() {
    return " - Åbningsprocent: " + Math.floor(areaStats.open / areaStats.households * 10000) / 100 + "%";
}

function updateStats(pdata) {

    let households = parseInt(pdata.households) || 0;

    let change = (pdata.pause == false && pdata.active == true) ? households : -households;

    areaStats.open += change;
    areaStats.closed -= change;

    statref.textContent = statText + getOpenPercent() + "";

}

function changeOne(event, postcode) {
    let item = pcodeIndex[postcode] || {};
    let opened = (item.pause == false && item.active == true);

    let newValue = undefined;
    let target = undefined;

    switch (event.currentTarget.name) {
        case "category":
            newValue = event.currentTarget.value;
            target = event.currentTarget.name;
            break;
        case "pause":
            newValue = event.currentTarget.checked;
            target = event.currentTarget.name;
            item.pause = newValue;
            break;
        case "noService":
            newValue = !event.currentTarget.checked;
            target = "active"
            item.active = newValue;
            break;
        case "area":
            newValue = "area" + event.currentTarget.value;
            target = event.currentTarget.name;
            break;
    }



    if (newValue !== undefined) {
        let openedAfter = (item.pause == false && item.active == true);
        openedAfter != opened && updateStats(item);
        console.log("Updating:", postcode, target, newValue);
        saveChangeToServer(postcode, target, newValue);
    }

}



function saveChangeToServer(postcode, target, newValue, lastPostcode, retrieCount = 0) {
    var params = "first=" + postcode + "&" + target + "=" + newValue;
    lastPostcode ||= postcode;
    params += "&last=" + lastPostcode;

    //first=1050&comment=test2
    return new Promise((resolve, _reject) => {
        let updateSuccess = false;
        let hasFailed = false;
        setTimeout(() => {
            if (hasFailed == false && (retrieCount === 0 || updateSuccess == false)) {
                retry();
            }

        }, 5_000);

        fetch(UPDATE_POSTCODE_DATA_URL + params)
            .then(res => res.json())
            .then((result) => {
                updateSuccess = true;

                if (retrieCount === 0) {
                    InfoHelper.showInfo("Postnummer: " + postcode + "- ændring gemt");

                }

                resolve(result);
            },

                (error) => {
                    hasFailed = true;
                    setTimeout(() => retry(error), 1_500);
                    //reject("Failed to UPDATE");
                    console.error("Failed to update postcodedata ERROR:", error);
                }
            )
        function retry(error = "connection timed out") {
            if (retrieCount < 5) {
                saveChangeToServer(postcode, target, newValue, lastPostcode, retrieCount + 1)
                    .then((res) => resolve(res))
            }
            else {
                InfoHelper.showError("Fejl i opdatering a postnummer: " + postcode + " - error: " + error + "<BR>Kontakt venligst ServiceHusets IT afdeling<BR>Tag gerne et screenshot af denne besked ;)");
            }
        }
    });
}



function loadPostcodeList() {

    postcodelistLoading = true;
    return new Promise((resolve, reject) => {
        var tryCount = 0;
        setTimeout(() => {
            tryCount++;
            tryFetch();
        }, 3000);

        tryFetch();

        function tryFetch() {
            let myCount = tryCount;
            fetch(NEXT_GET_POSTCODE_DATA_URL)
                .then(res => res.json())
                .then((result) => {
                    if (tryCount !== myCount) return;
                    postcodelistLoading = false;

                    resolve(result);
                },

                    (error) => {
                        if (tryCount !== myCount) return;
                        postcodelistLoading = false;
                        console.error("Failed to retrieve postcodedata ERROR:", error);
                        reject("No data");
                    }
                )
        }


    });
}



export default PostCodes;


/*

*/