import { Block, Button, Card, CardContent, CardFooter, CardHeader, Navbar, Page } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';
import MySettings from '../components/settings/MySettings';
import { defaultNextUser } from '../components/settings/defaultSettings';
import { sharedData } from '../data/SharedData';
import { beConnectorInstance } from '../js/BeConnector';
import Toaster from '../js/utils/Toaster';
import { CenteredPreloader } from '../js/utils/visHelper';

const SETTINGPAGE_STATES = {
    "UNINITIALIZED": "UNINITIALIZED",
    "LOADING": "LOADING",
    "READY": "READY",
}

const SETTINGS_TRANSLATION = {
    label: {
        CHAT: "Chat beskeder",
        MOVE_CLEANING: "Flyt rengøring",
        ADD_EXTRA_TASKS: "Tilføj ekstra opgaver",
        CANCEL_CLEANING: "Aflys rengøring",
        MAX_CANCEL_PERCENT: "Max aflysningsprocent",
        mitSH: "Mit ServiceHuset",
        messagePrefs: "Beskedsindstillinger",
    }, description: {
        messagePrefs: "Instillinger for beskeder, aflysninger, mm. sendt via Mit.ServiceHuset",
        CANCEL_CLEANING: "OBS: Hvis kunde aflyser sidste besøg og vi stadig har deres nøgle bliver der altid sendt besked til os.",
        MAX_CANCEL_PERCENT: "Send kun besked hvis antal aflysninger er over denne procent. 0=altid send besked. 100=aldrig send besked."
    }
}


export default function (_props) {
    const stateRef = useRef(SETTINGPAGE_STATES.UNINITIALIZED);
    const [settingsData, setSettingsData] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const settingToSaveRef = useRef();


    useEffect(() => {
        async function fetchData() {
            if (stateRef.current == SETTINGPAGE_STATES.UNINITIALIZED) {
                stateRef.current = SETTINGPAGE_STATES.LOADING;
                setShowLoader(true);
                let result = await beConnectorInstance.getNextUserSettings(sharedData.getCurrentUserName());
                console.log("SettingsPage: useEffect: Loading: result", result);
                settingToSaveRef.current = { ...defaultNextUser, ...result.settings };
                stateRef.current = SETTINGPAGE_STATES.READY;
                const preferences = settingToSaveRef.current.preferences;
                setSettingsData(preferences);
                setShowLoader(false);
            }

        }
        fetchData();
    }, []);

    async function saveSettings() {
        setShowLoader(true);
        console.log("SettingsPage: saveSettings: settingToSaveRef.current", settingToSaveRef.current);
        beConnectorInstance.saveNextUserSettings(sharedData.getCurrentUserName(), settingToSaveRef.current);
        setShowLoader(false);
        Toaster.ShowToast("Indstillinger gemt");
    }


    async function settingsChanged(preferences) {
        //console.log("SettingsPage: settingsChanged: preferences", preferences);
        /*         let result = await beConnectorInstance.setNextUserSettings(sharedData.getCurrentUserName(), settings);
                console.log("SettingsPage: settingsChanged: result", result); */
        settingToSaveRef.current.preferences = { ...settingToSaveRef.current.preferences, ...preferences };
        //console.log("SettingsPage: settingsChanged: settingToSaveRef.current", settingToSaveRef.current);
    }

    const showSettings = (stateRef.current == SETTINGPAGE_STATES.READY);

    return (
        <Page>
            <Navbar title={`Indstillinger: Område: ${sharedData.getArea()} (${sharedData.getCurrentUserName()})`} backLink="Back" />
            <Block strong style={{ zIndex: "unset" }}>
                {showLoader && <CenteredPreloader />}
            </Block>
            <Block>
                <Card animate style={{ maxWidth: "50rem", margin: "0 auto" }}>
                    <CardHeader className='new-style-card-header'>Instillinger</CardHeader>
                    <CardContent className='new-style-card-content-settings'>{showSettings && <MySettings settings={settingsData} onUpdate={settingsChanged} translation={SETTINGS_TRANSLATION} />}</CardContent>
                    <CardFooter className='new-style-card-footer'>
                        <div ></div>
                        <div ></div>
                        <div >
                            <Button fill raised className='new-style-button-green' onClick={saveSettings}>Gem</Button>
                        </div>
                    </CardFooter>
                </Card>
            </Block>
        </Page>
    )
}