export function printMemUsage() {

    if (typeof process !== "undefined") {
        const mem = process.memoryUsage();
        console.log(`Memory usage heapUsed: ${Math.round(mem.heapUsed / 1024 / 1024)} MB (RSS: ${Math.round(mem.rss / 1024 / 1024)} MB)`);
    }
    else {
        const mem = (window.performance as any).memory;
        console.log(`Memory usage heapUsed: ${Math.round(mem.usedJSHeapSize / 1024 / 1024)} MB (RSS: ${Math.round(mem.totalJSHeapSize / 1024 / 1024)} MB)`);
    }
    //console.log(`Memory usage rss: ${Math.round(mem.rss / 1024 / 1024)} MB`);
}

class CachedDataHelper {

    static cachedData: { [key: string]: any; } = {};
    static timeoutList: { [key: string]: NodeJS.Timer; } = {};

    store(name: string, data: any, ttlInSeconds: number = 0) {
        CachedDataHelper.cachedData[name] = data;
        let timeoutId = CachedDataHelper.timeoutList[name];
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        if (ttlInSeconds) {
            CachedDataHelper.timeoutList[name] = setTimeout(() => {
                delete CachedDataHelper.cachedData[name];
                delete CachedDataHelper.timeoutList[name];
                console.log(`Cached data expired: ${name}`);
                printMemUsage();
            }, ttlInSeconds * 1000);
        }
        printMemUsage();
    }

    get(name: string, purge = false) {
        let out = CachedDataHelper.cachedData[name];
        if (purge) {
            delete CachedDataHelper.cachedData[name];
            delete CachedDataHelper.timeoutList[name];
            printMemUsage();
        }
        return out;
    }


}

export const cachedDataHelper = new CachedDataHelper();