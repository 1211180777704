import { f7 } from 'framework7-react';
import React from 'react';


export default class InfoHelper {
    static pendingInfoMessage = "";

    static showError(message) {
        let toast = f7.toast.create({
            text: message,
            position: 'center',
            closeButton: true

        });
        toast.open();
    }

    static showInfo(message) {

        InfoHelper.pendingInfoMessage.length === 0 && setTimeout(() => {
            let toast = f7.toast.create({
                text: InfoHelper.pendingInfoMessage,
                position: 'top',
                horizontalPosition: 'center',
                closeButton: true,
                closeTimeout: 2000

            });
            InfoHelper.pendingInfoMessage = "";
            toast.open();

        }, 1_500);

        InfoHelper.pendingInfoMessage += message + "<BR>";
    }
}