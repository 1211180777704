export const defaultNextUser = {
    "userName": "",
    "mailName": "",
    "mailEmail": "",
    "mailTitle": "",
    "mailPic": "",
    "area": "",
    "preferences": {
        "mitSH": {
            "messagePrefs": {
                "CHAT": {
                    "CARE": true,
                    "EMAIL": false
                },
                "MOVE_CLEANING": {
                    "CARE": true,
                    "EMAIL": false
                },
                "ADD_EXTRA_TASKS": {
                    "CARE": true,
                    "EMAIL": false
                },
                "CANCEL_CLEANING": {
                    "CARE": true,
                    "EMAIL": false,
                    "MAX_CANCEL_PERCENT": 10
                }
            }
        }
    }
};
