import { AccordionContent, Block, List, ListItem } from 'framework7-react';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { customerHandler } from '../js/CustomerHandler';

export function CustomerTaskList() {
    const [activeInputField, setActiveInputField] = useState(undefined);
    const activeInputRef = useRef(undefined);
    return (
        <div>
            <List>
                {Object.entries(customerHandler.tasks).map(([id, task]) => {
                    const firstTask = task.tasks[0];
                    const exeTime = firstTask.ABBFREKVENS2 ? firstTask.ABBFREKVENS2 : firstTask.ABBFREKVENS;
                    const taskTitle = "Opgave: " + id + " " + task.type + " - " + exeTime;
                    return (
                        <ListItem title={taskTitle} accordionItem
                            /* after={
                            <Button outline onClick={async () => {
                                console.log("Rediger opgave: ", id);
                            }}>Rediger</Button>
                        } */
                            key={"task" + id}>

                            <AccordionContent  >
                                <Block strong>
                                    <b>{task.type}</b>
                                    <pre>{firstTask.TASK_REMARK}</pre>



                                </Block>
                                <Block strong>



                                    <div>
                                        <b>Kommendende opgaver:</b>
                                        {task.tasks.map((task) => {
                                            let formattedDate = DateTime.fromISO(task.DATO).toFormat('dd/MM/yy');
                                            let status = "";
                                            let taskRemark = <div style={{ color: "#FFFF88", textAlign: "left" }}>{task.SUBTASK_REMARK}</div> || "";
                                            let editValue = <></>;
                                            if (activeInputField && task.ID == activeInputField[1]) {
                                                editValue = <div className='sh-task-details-textarea'>
                                                    <textarea
                                                        placeholder='Indtast instruks'
                                                        autoFocus
                                                        ref={activeInputRef}
                                                        defaultValue={task.SUBTASK_REMARK}
                                                        onBlur={() => {
                                                            task.SUBTASK_REMARK = activeInputRef.current.value;
                                                            setActiveInputField(undefined);
                                                        }}>

                                                    </textarea></div>

                                            }
                                            let textStyle = { color: "#a2e4a7" };

                                            switch (task.STATUS) {
                                                case 9:
                                                    status = " - AFLYST";
                                                    textStyle = { color: "#ff7272" };
                                                    break;
                                            }
                                            return (
                                                <div key={task.ID} className='sh-task-details-box'>
                                                    <div>
                                                        <div style={textStyle}>{formattedDate + " - " + task.MNAVN + " - Pris: " + task.FAKPRIS + status}</div>
                                                        <br />
                                                        {taskRemark}
                                                    </div>
                                                    <div className='sh-task-details-box-buttons'>
                                                        {editValue}
                                                        <button className='sh-task-details-button' onClick={() => { setActiveInputField(["instruks", task.ID]) }}>Instruks</button>
                                                        <button className='sh-task-details-button'>Pris</button>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </Block>
                            </AccordionContent>
                        </ListItem>)

                })}


            </List>
        </div >
    )
}

